import React, {useState, useEffect } from "react";
import "./riskfactor.css";
import axios from "axios";
import getProfile from "../../getProfile";








function ProfileForm({ profile, setProfile, setPage }) {

   
  

  console.log("these are the props", profile, setProfile, setPage);
  
  function handleSubmit(e) {
    e.preventDefault();
    const authToken = localStorage.getItem("authToken");
    axios
      .post(process.env.REACT_APP_API_BASE_URL + `/riskfactor/user/?authToken=${authToken}`, profile)
      .then((res) => {
        console.log(profile);
        console.log(res);
        setPage(1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(async () => {
    const authToken = localStorage.getItem("authToken");
    await axios
      .get(process.env.REACT_APP_API_BASE_URL + `/riskfactor/user/?authToken=${authToken}`)
      .then((res) => {
        console.log("Data fetched");
        console.log(res.data);
        if (res && res.data && res.data[0]) {
          const user = res.data[0];
          setProfile({
            name: user.name,
            email: user.email,
            age: user.age,
            gender: user.gender
          });
        }
      })
      .catch(() => console.log("error occurred"));
  }, []);

  return (
    <div className="profile-form">
      <div className="profile-form-head">
        Tell us more about yourself
        <img className="hjkgkujf" src="/images/misc/profile-illustration.svg" alt="" />
      </div>
      <img className="ghtmk" src="/images/misc/profile-illustration.svg" />

      <div className="risk-inputs">
        <input
          type="text"
          placeholder="Name"
          value={profile.name || ""}
          onChange={(e) => {
            setProfile({ ...profile, name: e.target.value });
          }}
        />
        <input
          type="text"
          placeholder="Email"
          value={profile.email || ""}
          onChange={(e) => {
            setProfile({ ...profile, email: e.target.value });

          }}
          
         
        />
        
        
         
      </div>

      <div className="profileform-dropdowns">
        <select
          value={profile.age || ""}
          onChange={(e) => {
            setProfile({ ...profile, age: e.target.value });
          }}>
          <option value={18} disabled selected>
            Age
          </option>
          {[...Array(93).keys()].map((number) => {
            return (
              <option key={number} value={number + 18}>
                {number + 18}
              </option>
            );
          })}
        </select>
        <select
          className="gender-gap"
          value={profile.gender || ""}
          onChange={(e) => {
            setProfile({ ...profile, gender: e.target.value });
          }}>
          <option value={'Male'} disabled selected>
            Gender
          </option>
          <option value="M">Male</option>
          <option value="F">Female</option>
          <option value="B">Non Binary</option>
        </select>
        {/* <div>{x.name}</div> */}
      </div>
      
      <button onClick={handleSubmit}>Submit</button>
    </div>
  );
}

export default ProfileForm;
