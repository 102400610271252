/* eslint-disable react/prop-types */
import React, { useState } from "react";
import LifeStepOne from "./LifeStepOne";
import LifeStepTwo from "./LifeStepTwo";
import axios from "axios";
import sendRequest from "../../../helpers/sendPostRequest";

function Life({ open, handleClose }) {
  const [step, setStep] = useState(1);
  console.log("Inside car");
  const handleNext = () => {
    setStep(step + 1);
  };
  const handlePrev = () => {
    setStep(step - 1);
  };
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDOB] = useState(new Date());
  const [amt, setAmt] = useState("");
  const [add, setAdd] = useState("");
  const [life, setLife] = useState("");
  const [upto, setUpto] = useState("");
  const [chew, setChew] = useState(false);
  const [success, setSuccess] = useState(false);
  const handleName = (e) => setName(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);
  const handlePhone = (e) => setPhone(e.target.value);
  const handleDOB = (value) => setDOB(value);
  const handleAmt = (e) => setAmt(e.target.value);
  const handleAdd = (e) => setAdd(e.target.value);
  const handleLife = (e) => setLife(e.target.value);
  const handleUpto = (e) => setUpto(e.target.value);
  const handleChew = () => setChew(!chew);
  const handleSubmit = () => {
    const body = {
      type: "life",
      name,
      email,
      mobile: phone,
      location: add,
      dob,
      cover_life: life,
      cover_upto: upto,
      amount: amt
    };

    axios.post(process.env.REACT_APP_API_BASE_URL + `/add/insurance/details/`, body)
      .then((data) => {
        if (data.status === 200) {
          console.log(data);
          setSuccess(true);
          // handleClose();
          // alert("Insurance added.");
        }
      })
      .catch((e) => console.log(e));
  };
  return (
    <>
      {step === 1 && (
        <LifeStepOne
          open={open}
          handleClose={handleClose}
          handlePrev={handlePrev}
          handleNext={handleNext}
          email={email}
          name={name}
          phone={phone}
          dob={dob}
          amt={amt}
          add={add}
          handleName={handleName}
          handleEmail={handleEmail}
          handlePhone={handlePhone}
          handleDOB={handleDOB}
          handleAmt={handleAmt}
          handleAdd={handleAdd}
        />
      )}
      {step === 2 && (
        <LifeStepTwo
          handleNext={handleNext}
          open={open}
          handleClose={handleClose}
          handlePrev={handlePrev}
          life={life}
          upto={upto}
          handleLife={handleLife}
          handleUpto={handleUpto}
          chew={chew}
          handleChew={handleChew}
          handleSubmit={handleSubmit}
          success={success}
        />
      )}
    </>
  );
}

export default Life;
