/* eslint-disable react/prop-types */
import { CalendarToday, CheckCircleOutlined, MonetizationOn } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  styled,
  TextField,
} from "@mui/material";
import React from "react";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    overFlowX: "hidden",
    height: "100%",
    alignItems: "center",
    marginBottom: "50px",
    minWidth: "200px"
  },
  display: "flex",
  width: "100%",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
  height: "90vh",
  marginBottom: "50px",
  backgroundColor: "white",
  minWidth: "500px"
}));

const SuccessStyle = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "90vh",
  marginBottom: "50px",
  backgroundColor: "white",
  minWidth: "500px"
}));

const ButtonStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "90%",
    height: "30%",
    alignItems: "center",
    marginBottom: "50px"
  },
  display: "flex",
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  marginBottom: "50px",
  backgroundColor: "white"
}));

const RowStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "80%",
    alignItems: "center",
    marginBottom: "10px"
  },
  display: "flex",
  width: "90%",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  marginBottom: "10px",
  color: "#45A69F"
}));

function LifeStepTwo({
  open,
  handleClose,
  life,
  upto,
  chew,
  handleLife,
  handleUpto,
  handlePrev,
  handleChew,
  handleSubmit,
  success
}) {
  return (
    <>
      <Dialog open={open} onClose={handleClose} sx={{ minHeight: "60%" }}>
        {success ? (
          <SuccessStyle>
            <h1>SUCCESS!!</h1>
            <CheckCircleOutlined style={{ color: "#00C314", fontSize: "40px" }} />
            <p>Our team will contact you shortly.</p>
          </SuccessStyle>
        ) : (
          <RootStyle>
            <div style={{ flex: "0.1", marginTop: '20px'}}>
              <h1 style={{ color: "#45A69F" }}>LIFE INSURANCE</h1>
            </div>
            <RowStyle>
              <TextField
                id="input-with-icon-textfield"
                label="Pin Code"
                InputLabelProps={{
                  style: { color: "#45A69F", fontSize: "20px", fontWeight: "600", marginLeft: '2px'  }
                }}
                value={life}
                onChange={handleLife}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* <MonetizationOn /> */}
                    </InputAdornment>
                  )
                }}
                variant="standard"
                sx={{ margin: "15px" }}
              />
              <TextField
                id="input-with-icon-textfield"
                label="Cover Upto Age"
                InputLabelProps={{
                  style: { color: "#45A69F", fontSize: "20px", fontWeight: "600", marginLeft: '2px'  }
                }}
                value={upto}
                onChange={handleUpto}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* <CalendarToday /> */}
                    </InputAdornment>
                  )
                }}
                variant="standard"
                sx={{ margin: "15px" }}
              />
            </RowStyle>
            <RowStyle>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox value={chew} onChange={handleChew} />}
                  label="Do you smoke tobacco?"
                />
              </FormGroup>
            </RowStyle>

            <ButtonStyle>
              <Button
                sx={{ background: "#45A69F", color: "white", width: "200px", marginBottom: '30px', "&:hover": {backgroundColor: "#45A69F", }  }}
                onClick={handlePrev}>
                Prev <img src="/images/icons/login.png" alt='Previous'/>{" "}
              </Button>
              <Button
                sx={{ background: "#45A69F", color: "white", width: "200px", marginBottom: '30px', "&:hover": {backgroundColor: "#45A69F", }  }}
                onClick={handleSubmit}>
                Submit <img src="/images/icons/login.png" alt='submit'/>{" "}
              </Button>
            </ButtonStyle>
          </RootStyle>
        )}
      </Dialog>
    </>
  );
}

export default LifeStepTwo;
