import React from "react";
import "./design.css";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

function Design() {
  const particlesInit = async (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  return (
    <div className="mff-design">
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fullScreen: {
            enable: false,
            zIndex: 0
          },
          particles: {
            number: {
              value: 50,
              density: {
                enable: true,
                value_area: 2000
              }
            },
            color: {
              value: "#fff"
            },
            shape: {
              type: "circle"
            },
            opacity: {
              value: 0.5,
              random: true,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false
              }
            },
            size: {
              value: 18,
              random: true,
              anim: {
                enable: false,
                speed: 40,
                size_min: 0.1,
                sync: false
              }
            },
            line_linked: {
              enable: false,
              distance: 500,
              color: "#ffffff",
              opacity: 0.4,
              width: 2
            },
            move: {
              enable: true,
              speed: 2,
              direction: "top-right",
              random: false,
              straight: false,
              out_mode: "out",
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
              }
            }
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "bubble"
              },
              onclick: {
                enable: true,
                mode: "repulse"
              },
              resize: true
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 0.5
                }
              },
              bubble: {
                distance: 400,
                size: 4,
                duration: 0.3,
                opacity: 1,
                speed: 3
              },
              repulse: {
                distance: 200
              },
              push: {
                particles_nb: 4
              },
              remove: {
                particles_nb: 2
              }
            }
          },
          retina_detect: true,
          background: {
            color: "#6147e3",
            image: "",
            position: "50% 50%",
            repeat: "no-repeat",
            size: "cover"
          }
        }}
      />

      <div className="huzxn56">
        <div className="text-div58">
          <h1>
            Meet W.R.A.P. – Wealth Robotic Assistant Program
            <br />
          </h1>
          <p>
            An approach towards financial freedom, W.R.A.P is a perfect union of well-developed
            technology system and highly skilled Human expertise. Extremely progressive, it uses a
            personalised strategy with Human proficiency to create an advance portfolio that
            accelerates your earnings in the financial universe – helping you towards wealth
            creation and financial independence.
          </p>
        </div>
        <img src="./images/misc/protection.png" alt="" />
      </div>
    </div>
  );
}

export default Design;
