/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Slider.css";
import { Button, Modal, Box, Typography } from "@mui/material";
import GetAssistanceForm from "./GetAssistanceForm";
// import man from "../images/billy.svg";
import asset from "../images/Frame5.png";

AOS.init();

// You can also pass an optional settings object
// below listed default settings
AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
  initClassName: "aos-init", // class applied after initialization
  animatedClassName: "aos-animate", // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: "ease", // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: "top-bottom" // defines which position of the element regarding to window should trigger the animation
});

function Slider() {
  const [form, setForm] = React.useState({
    name: "",
    email: "",
    phone: "",
    query: ""
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSubmit = async () => {
    const data = await axios
      .post("https://api.stocktick.in/getassistance/", form, {
        headers: {
          Connection: "keep-alive",
          authToken: localStorage.getItem("authToken"),
          accepts: "application/json"
        }
      })
      .then(() => {
        setForm({
          name: "",
          email: "",
          phone: "",
          query: ""
        });
        handleClose();
      });
    console.log(data);
  };
  return (
    <div className="slider">
      {/* <div className="bg-black"></div> */}
      <div className="data">
        {/* <img data-aos="flip-left" src={asset} alt="none" /> */}
        {/* <h1>Meet Billy... She is 20.</h1>
        <h2> She doesn't ask for expenses anymore.</h2> */}

        {/* <img src="/images/misc/mfBanner.mp4" alt="" /> */}
        {/* <video autoPlay loop muted style={{ zIndex: "-1" }}>
          <source src="/images/Fire-Financial freedom.mp4" type="video/mp4" />
          Error Message
        </video> */}

        <Link className="slider_btn"><a href="https://calendly.com/vijaymaheshwari/financialfreedom" style={{ textDecoration: 'none' }}
        >Book A Free Call </a></Link>
      </div>

      <div className="head-div-1">
        <button className="heda123" onClick={handleOpen}>
          {" "}
          Get Assistance!
        </button>
      </div>

      <GetAssistanceForm
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        handleSubmit={handleSubmit}
        form={form}
        setForm={setForm}
      />
    </div>
  );
}

export default Slider;
