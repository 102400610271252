import axios from "axios";
import getProfile from '../components/getProfile.jsx';

async function URLise(file) {
    var author = 'any';
    var pic = null;
    // const profile = await getProfile();
    const authToken= localStorage.getItem('authToken')
    await axios.get(`https://api.stocktick.in/get/userinfo?authToken=${authToken}`).then(async (res) => {
            if(res.data.name) {
                author = res.data.name;
            }
            var data = new FormData();
            data.append('file', file)
            data.append('created_by', author)
            await axios.post(`https://api.stocktick.in/fileUploader?authToken=${authToken}`, data
            ).then((res) => {
                  pic = res.data.file;
                  console.log(pic)
                  return(pic)
                })
            return(pic)
        })
        return pic;
}

export default URLise