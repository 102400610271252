import React from "react";
import "./qr.css";
// import mobAd from '/images/misc/mobile-ad.svg';
const mobAd = '/images/misc/app_image.jpg'

function QrBox() {
  return (
    <>
      <div className="qr-parent">
        <div className="qr-box">
          <div className="qr-content">
            <div className="qr-text">Get our App for <br /> seemless experience!</div>
            <br />
            <div className="qr-sub-text">Scan the QR code to get the app</div>

            <img className="qr-code" src="/images/misc/download_qr.png" alt="" />
          </div>
          <div className="qr-img-box">
            <img src={mobAd} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default QrBox;