import React from "react";

import Accordion from "./Accordion";

function FAQ() {
  return (
    <div className="App12652">
      <h1>Frequently Asked Questions</h1>
      <Accordion
        title="What are Mutual Funds?"
        content="Mutual Fund is an investment fund where you as an investor pool your money. Now,
        based on your goals and investments your money is managed and invested by a
        professional fund manager in various asset classes such as equity, debt, gold, as
        well as securities to generate good returns."
      />
      <Accordion
        title="When should I start investing in Mutual Funds? Which is the best time to
        invest in Mutual Funds?"
        content="There is no best time as such for investing in mutual funds. Individuals can make
        investments in mutual funds as and when they wish. It is best explained by a
        Chinese saying, “The best time to plant a tree was 20 years ago. The second-best
        time is now!”"
      />
      <Accordion
        title="Can Mutual Funds help me in Wealth Creation?"
        content="Mutual funds have ways for aggressive as well as conservative users. Managed by
        professionals and experts, it offers liquidity and portfolio diversification - leading to
        wealth creation in a healthier way. Considering crucial factors like your financial
        goals and investment, it is a beneficial tool to provide you profits in the long run."
      />
      <Accordion
        title="Can NRIs invest in Mutual Funds in India?"
        content="Yes. Both, Non-Resident Indians (NRI) and Persons of Indian Origin (PIO) can invest
        in Indian Mutual Funds."
      />
      <Accordion
        title="What are Equity Funds?"
        content="Equity Fund also known as Growth Funds are a Mutual Fund Scheme that invests
        mainly in shares/stocks of the companies."
      />
      <Accordion
        title="What are Debt Funds?"
        content="A debt fund or bond fund is a Mutual Fund scheme that invests in fixed income
        instruments that offer capital appreciation. These include corporate and government
        bonds, corporate debt securities, and money market instruments etc."
      />
      <Accordion
        title="When can I withdraw or redeem my Mutual Fund investment?"
        content="An investment in an open- end scheme can be redeemed at any time. Unless it is an
        investment in an Equity Linked Savings Scheme (ELSS), wherein there is a lock-in of
        3 years from date of investment, there are no restrictions on investment redemption."
      />
      <Accordion
        title="What is Fundbazar? What does it offer to the Investors?"
        content="Fundbazar is an online investment platform that offers investors to invest online in
        various financial instruments such as Mutual Funds, Bonds, Loans against Securities
        
        (LAS), Stock Broking, and Fixed Deposits easily through its web portal and mobile
        application."
      />
    </div>
  );
}

export default FAQ;
