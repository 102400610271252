import { styled, Typography } from "@mui/material";
import React from "react";
import image from "../images/billy.svg";
import logo from "../images/logo.svg";
import './login.css';

const RootStyle = styled("div")(() => ({
  /*[theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "15%"
  },*/
  width: "100%",
  color: "#FFFFFF",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "26px",
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100vh"
}));

const ImageStyle = styled("img")(() => ({
  height: "30%",
  width: "30%",
  objectFit: "contain",
  
}));

const LogoStyle = styled("img")(() => ({
  height: "10%",
  width: "10%",
  objectFit: "contain",
  
}));

function LoginLeft() {
  return (


    <div className="login-left ">

      <h1>Welcome to StockTick</h1>

      <p>One step Solution for all your Financial Needs</p>

    </div>

    // <RootStyle style={{ background: "#604FB2" }}>
    //   <Typography
    //     variant={"h3"}
    //     style={{ marginTop: "40px", marginBottom: "20px", fontWeight: "600" }}>
    //     Welcome to StockTick
    //   </Typography>
    //   <Typography
    //     variant={"h6"}
    //     style={{
    //       marginBottom: "40px",
         
    //       fontWeight: "50"
    //     }}>
    //     One stop solution for all your financial needs.
    //   </Typography>
    //   <ImageStyle src={image}></ImageStyle>
    //   <LogoStyle src={logo}></LogoStyle>
    // </RootStyle>
  );
}

export default LoginLeft;
