/* eslint-disable react/prop-types */
import {
  AccountCircle,
  DirectionsCarFilledOutlined,
  EmailOutlined,
  PhoneAndroidOutlined
} from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  styled,
  Switch,
  TextField
} from "@mui/material";
import React, { useState } from "react";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    height: "100vh",
    alignItems: "center",
    marginBottom: "50px"
  },
  display: "flex",
  width: "100%",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
  height: "90vh",
  marginBottom: "50px",
  backgroundColor: "white"
}));

const RowStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "100%",
    alignItems: "center",
    marginBottom: "10px"
  },
  display: "flex",
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "start",
  marginBottom: "10px",
  color: "#45A69F"
}));

const SwitchStyle = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "10px"
}));

const CheckBoxStyle = styled("div")(({ theme }) => ({
  display: "flex",
  width: "80%",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "10px",
  marginLeft: "20px"
}));

function CarStepOne({
  open,
  handleName,
  handleEmail,
  handlePhone,
  handleVno,
  handleClose,
  handleNext,
  name,
  email,
  phone,
  vno
}) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <RootStyle>
        <div style={{ flex: "0.1", marginTop: '20px' }}>
          <h1 style={{ color: "#45A69F" }}>CAR INSURANCE</h1>
        </div>
        <RowStyle>
          <TextField
            id="input-with-icon-textfield"
            label="Name"
            value={name}
            onChange={handleName}
            InputLabelProps={{
              style: { color: "#45A69F", fontSize: "20px", fontWeight: "600", marginLeft: '2px' }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <AccountCircle /> */}
                </InputAdornment>
              )
            }}
            variant="standard"
            style={{ margin: "15px" }}
          />
          <TextField
            id="input-with-icon-textfield"
            label="Email"
            value={email}
            onChange={handleEmail}
            InputLabelProps={{
              style: { color: "#45A69F", fontSize: "20px", fontWeight: "600", marginLeft: '2px'  }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <EmailOutlined /> */}
                </InputAdornment>
              )
            }}
            variant="standard"
            sx={{ margin: "15px" }}
          />
        </RowStyle>
        <RowStyle>
          <TextField
            id="input-with-icon-textfield"
            label="Phone"
            value={phone}
            onChange={handlePhone}
            InputLabelProps={{
              style: { color: "#45A69F", fontSize: "20px", fontWeight: "600", marginLeft: '2px'  }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <PhoneAndroidOutlined /> */}
                </InputAdornment>
              )
            }}
            variant="standard"
            sx={{ margin: "15px" }}
          />
          <TextField
            id="input-with-icon-textfield"
            label="Vehicle Number"
            value={vno}
            onChange={handleVno}
            InputLabelProps={{
              style: { color: "#45A69F", fontSize: "20px", fontWeight: "600", marginLeft: '2px'  }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <DirectionsCarFilledOutlined /> */}
                </InputAdornment>
              )
            }}
            variant="standard"
            sx={{ margin: "15px" }}
          />
        </RowStyle>
        <SwitchStyle>
          <p>Old Car</p>
          <Switch></Switch>
          <p>New Car</p>
        </SwitchStyle>
        <CheckBoxStyle>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="I accept to the terms and conditions. I autorise company to call me."
            />
          </FormGroup>
          <Button
            sx={{ background: "#45A69F", color: "white", width: "200px", "&:hover": {backgroundColor: "#45A69F", }  }}
            onClick={handleNext}>
            Next <img src="/images/icons/login.png" />{" "}
          </Button>
        </CheckBoxStyle>
      </RootStyle>
    </Dialog>
  );
}

export default CarStepOne;
