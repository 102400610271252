import React, { useState, useEffect } from "react";
import axios from "axios";
import Headline from "../../Headline/Headline";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";

function LifeInsurance() {
  // const [editBankName, setEditBankName] = useState(false);
  // const [editBranchAddress, setEditBranchAddress] = useState(false);
  // const [editAccountHolderName, setEditAccountHolderName] = useState(false);
  // const [editAccountNumber, setEditAccountNumber] = useState(false);
  // const [editAccountType, setEditAccountType] = useState(false);
  // const [editNomineeName, setEditNomineeName] = useState(false);
  // const [editRelationship, setEditRelationship] = useState(false);
  // const [editAllocationPercent, setEditAllocationPercent] = useState(false);
  // const [editNomineeName2, setEditNomineeName2] = useState(false);
  // const [editRelationship2, setEditRelationship2] = useState(false);
  // const [editAllocationPercent2, setEditAllocationPercent2] = useState(false);

  const [multiInput, setMultiInput] = useState([
    {
      id: "",
      plan_name: "",
      policy_number: "",
      policy_holder_name: "",
      sum_assured: "",
      policy_start_date: "",
      policy_maturity_date: "",
      policy_term: "",
      premium_payment: "",
      premium_amount: "",
      premium_payment_frequency: "",
      renewal_date: "",
      nominee1: "",
      relationship1: "",
      allocation1: "",
      nominee2: "",
      relationship2: "",
      allocation2: "",
      premium_payment_term: ""
    }
  ]);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const inputList = [...multiInput];
    inputList[index][name] = value;
    setMultiInput(inputList);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (e, index) => {
    e.preventDefault();

    const id = multiInput[index].id;

    if (!id) {
      const inputList = [...multiInput];
      inputList.splice(index, 1);
      setMultiInput(inputList);
    } else {
      const authToken = localStorage.getItem("authToken");
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL +
            `/asset-recorder/data-delete/?authToken=${authToken}&deleteId=${id}`
        )
        .then(() => {
          const inputList = [...multiInput];
          inputList.splice(index, 1);
          setMultiInput(inputList);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // handle click event of the Add button
  const handleAddClick = (e) => {
    e.preventDefault();
    setMultiInput([
      ...multiInput,
      {
        id: "",
        plan_name: "",
        policy_number: "",
        policy_holder_name: "",
        sum_assured: "",
        policy_start_date: "",
        policy_maturity_date: "",
        policy_term: "",
        premium_payment: "",
        premium_amount: "",
        premium_payment_frequency: "",
        renewal_date: "",
        nominee1: "",
        relationship1: "",
        allocation1: "",
        nominee2: "",
        relationship2: "",
        allocation2: "",
        premium_payment_term: ""
      }
    ]);
  };

  function handleSubmit(e, index) {
    e.preventDefault();
    const data = multiInput[index];
    console.log(data);

    const authToken = localStorage.getItem("authToken");
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL +
          `/asset-recorder/data/?authToken=${authToken}&asset=life_insurance&editId=${data.id}`,
        data
      )
      .then((res) => {
        console.log("ser1", res.data.ser1);
        const inputList = [...multiInput];
        inputList[index].id = res.data.ser1.id;
        setMultiInput(inputList);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(async () => {
    const authToken = localStorage.getItem("authToken");
    await axios
      .get(
        process.env.REACT_APP_API_BASE_URL +
          `/asset-recorder/data/?authToken=${authToken}&asset=life_insurance`
      )
      .then((res) => {
        console.log("FD : Data fetched");
        console.log(res.data);
        if (res && res.data && res.data[0]) {
          var newData = res.data.map((d) => {
            const newD = {
              id: d.id || "",
              plan_name: d.plan_name || "",
              policy_number: d.policy_number || "",
              policy_holder_name: d.policy_holder_name || "",
              sum_assured: d.sum_assured || "",
              policy_start_date: d.policy_start_date || "",
              policy_maturity_date: d.policy_maturity_date || "",
              policy_term: d.policy_term || "",
              premium_payment: d.premium_payment || "",
              premium_amount: d.premium_amount || "",
              premium_payment_frequency: d.premium_payment_frequency || "",
              renewal_date: d.renewal_date || "",
              nominee1: d.nominee1 || "",
              relationship1: d.relationship1 || "",
              allocation1: d.allocation1 || "",
              nominee2: d.nominee2 || "",
              relationship2: d.relationship2 || "",
              allocation2: d.allocation2 || "",
              premium_payment_term: d.premium_payment_term || ""
            };
            return newD;
          });
          console.log(newData);
          setMultiInput(newData);
        }
      })
      .catch(() => console.log("error occurred"));
  }, []);

  return (
    <>
      <Headline />
      <Navbar />
      <div className="parent-key">
        <div className="parent-key-card">
          <h1>
            {" "}
            Life Insurance
            <button className="add-card-btn" onClick={handleAddClick}>
              Add
            </button>
          </h1>
          <div className="parent-card-true">
            {multiInput.map((x, i) => {
              return (
                <div className="parent-card-true1" key={i}>
                  <div className="div-flex">
                    <div className="div-flex-one">
                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Plan Name </label>
                        <input
                          type="text"
                          className="input-text"
                          name="plan_name"
                          value={multiInput[i].plan_name}
                          // readOnly={!editBranchAddress}
                          onChange={(e) => handleInputChange(e, i)}
                        />

                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditBranchAddress(!editBranchAddress);
                    }}>
                    {editBranchAddress ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Policy Number </label>
                        <input
                          type="text"
                          className="input-text"
                          name="policy_number"
                          value={multiInput[i].policy_number}
                          // readOnly={!editAccountNumber}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAccountNumber(!editAccountNumber);
                    }}>
                    {editAccountNumber ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">
                          Policy Holder&apos;s Name{" "}
                        </label>
                        <input
                          type="text"
                          className="input-text"
                          name="policy_holder_name"
                          value={multiInput[i].policy_holder_name}
                          // readOnly={!editAccountNumber}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAccountNumber(!editAccountNumber);
                    }}>
                    {editAccountNumber ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Sum Assured </label>
                        <input
                          type="number"
                          className="input-text"
                          name="sum_assured"
                          value={multiInput[i].sum_assured}
                          // readOnly={!editAccountHolderName}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAccountHolderName(!editAccountHolderName);
                    }}>
                    {editAccountHolderName ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Policy Start Date </label>
                        <input
                          type="date"
                          className="input-text"
                          name="policy_start_date"
                          value={multiInput[i].policy_start_date}
                          // readOnly={!editNomineeName}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditNomineeName(!editNomineeName);
                    }}>
                    {editNomineeName ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Policy Maturity Date </label>
                        <input
                          type="date"
                          className="input-text"
                          name="policy_maturity_date"
                          value={multiInput[i].policy_maturity_date}
                          // readOnly={!editRelationship2}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditRelationship2(!editRelationship2);
                    }}>
                    {editRelationship2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Policy Term (Years) </label>
                        <input
                          type="number"
                          className="input-text input-year"
                          name="policy_term"
                          value={multiInput[i].policy_term}
                          // readOnly={!editRelationship2}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        <span className="input-postfix">Yr.</span>
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditRelationship2(!editRelationship2);
                    }}>
                    {editRelationship2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">
                          Premium Payment Term (Years){" "}
                        </label>
                        <input
                          type="number"
                          className="input-text input-year"
                          name="premium_payment_term"
                          value={multiInput[i].premium_payment_term}
                          // readOnly={!editRelationship2}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        <span className="input-postfix">Yr.</span>
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditRelationship2(!editRelationship2);
                    }}>
                    {editRelationship2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      {/* <div className="input-entry1">
                  <label className="label-cust-edit-profile">Premium Payment Term (Years)   </label>
                  <input
                    type="number"
                    className="input-text"
                    name="policy_term"
                    value={multiInput[i].premium_payment_frequency}
                    // readOnly={!editRelationship2}
                    onChange={(e) => handleInputChange(e, i)}
                  /> */}
                      {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditRelationship2(!editRelationship2);
                    }}>
                    {editRelationship2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      {/* </div> */}

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Premium Amount </label>
                        <input
                          type="number"
                          className="input-text"
                          name="premium_amount"
                          value={multiInput[i].premium_amount}
                          // readOnly={!editRelationship2}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditRelationship2(!editRelationship2);
                    }}>
                    {editRelationship2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>
                    </div>
                    <div className="div-flex-two">
                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">
                          Premium Payment Frequency{" "}
                        </label>
                        <input
                          type="text"
                          className="input-text"
                          name="premium_payment_frequency"
                          value={multiInput[i].premium_payment_frequency}
                          // readOnly={!editRelationship2}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditRelationship2(!editRelationship2);
                    }}>
                    {editRelationship2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Renewal Date </label>
                        <input
                          type="date"
                          className="input-text"
                          name="renewal_date"
                          value={multiInput[i].renewal_date}
                          // readOnly={!editRelationship2}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditRelationship2(!editRelationship2);
                    }}>
                    {editRelationship2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Nominee Name I </label>
                        <input
                          type="text"
                          className="input-text"
                          name="nominee1"
                          value={multiInput[i].nominee1}
                          // readOnly={!editAllocationPercent2}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAllocationPercent2(!editAllocationPercent2);
                    }}>
                    {editAllocationPercent2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Relationship I</label>
                        <input
                          type="text"
                          className="input-text"
                          name="relationship1"
                          value={multiInput[i].relationship1}
                          // readOnly={!editAllocationPercent2}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAllocationPercent2(!editAllocationPercent2);
                    }}>
                    {editAllocationPercent2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Allocation Percent I </label>
                        <input
                          type="text"
                          className="input-text input-percent"
                          name="allocation1"
                          value={multiInput[i].allocation1}
                          // readOnly={!editAllocationPercent2}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        <span className="input-postfix">%</span>
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAllocationPercent2(!editAllocationPercent2);
                    }}>
                    {editAllocationPercent2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Nominee Name II</label>
                        <input
                          type="text"
                          className="input-text"
                          name="nominee2"
                          value={multiInput[i].nominee2}
                          // readOnly={!editAllocationPercent2}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAllocationPercent2(!editAllocationPercent2);
                    }}>
                    {editAllocationPercent2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Relationship II</label>
                        <input
                          type="text"
                          className="input-text"
                          name="relationship2"
                          value={multiInput[i].relationship2}
                          // readOnly={!editAllocationPercent2}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAllocationPercent2(!editAllocationPercent2);
                    }}>
                    {editAllocationPercent2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Allocation Percent II</label>
                        <input
                          type="text"
                          className="input-text input-percent"
                          name="allocation2"
                          value={multiInput[i].allocation2}
                          // readOnly={!editAllocationPercent2}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        <span className="input-postfix">%</span>
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAllocationPercent2(!editAllocationPercent2);
                    }}>
                    {editAllocationPercent2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="div-flex">
                    <button className="div-form-button" onClick={(e) => handleSubmit(e, i)}>
                      {" "}
                      save{" "}
                    </button>
                  </div>

                  {i >= 0 && (
                    <button className="delete-card-btn" onClick={(e) => handleRemoveClick(e, i)}>
                      <img src="/images/icons/delete.svg" alt="" /> Delete
                    </button>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default LifeInsurance;
