import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
// import Footer from "./components/Footer/Footer";
import InsuranceCard from "./components/Insurance/InsuranceCard";
import Home from "./pages/Home";
import Media from "./pages/Media";
import Insurance from "./pages/Insurance";
import Mutual_Funds from "./pages/Mutual_Funds";
import Profile from "./pages/Profile";
import Blog from "./pages/Blog";
import BlogsPage from "./pages/Blogs";
import NewBlog from "./pages/NewBlog";
import Loan from "./pages/Loan";
import ApplyInsurance from "./components/Insurance/ApplyInsurance";
import Education from "./pages/Education";
// import Pagination from "./components/Blogs/Pagination";
import Explore_MF from "./pages/Explore_MF";
import MF_Calculator from "./pages/MF_Calculator";
import Asset_Recorder from "./pages/Asset_Recorder";
import Profile_Page from "./components/Mutual_Funds/AssetRecorder/Profile";
import KeyPeople from "./components/Mutual_Funds/AssetRecorder/KeyPeople";
import BankAccount from "./components/Mutual_Funds/AssetRecorder/BankAccount";
import Bond from "./components/Mutual_Funds/AssetRecorder/Bonds";
import Locker from "./components/Mutual_Funds/AssetRecorder/Locker";
import FixedDeposit from "./components/Mutual_Funds/AssetRecorder/FixedDeposit";
import MutualFunds from "./components/Mutual_Funds/AssetRecorder/MutualFunds";
import EPF from "./components/Mutual_Funds/AssetRecorder/EPF";
import NPS from "./components/Mutual_Funds/AssetRecorder/NPS";
import PPF from "./components/Mutual_Funds/AssetRecorder/PPF";
import PMS from "./components/Mutual_Funds/AssetRecorder/PMS";
import AIF from "./components/Mutual_Funds/AssetRecorder/AIF";
import IndianEquity from "./components/Mutual_Funds/AssetRecorder/IndianEquity";
import USEquity from "./components/Mutual_Funds/AssetRecorder/USEquity";
import OffMarket from "./components/Mutual_Funds/AssetRecorder/OffMarket";
import RealEstate from "./components/Mutual_Funds/AssetRecorder/RealEstate";
import LifeInsurance from "./components/Mutual_Funds/AssetRecorder/LifeInsurance";
import HealthInsurance from "./components/Mutual_Funds/AssetRecorder/HealthInsurance";
import MotorInsurance from "./components/Mutual_Funds/AssetRecorder/MotorInsurance";
import Loans from "./components/Mutual_Funds/AssetRecorder/Loan";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import TermsOfUse from "./pages/TermsOfUse";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import Partner from "./components/Partner/Partner";

function routes() {
  const isLoggedIn = localStorage.getItem("authToken");

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/media">
          <Media />
        </Route>
        <Route exact path="/insurance">
          <Insurance />
        </Route>
        <Route path="/blog">
          <Blog />
        </Route>
        <Route path="/blogs">
          <BlogsPage />
        </Route>
        <Route path="/create-blog">
          <NewBlog />
        </Route>
        <Route exact path="/insurance-card">
          <InsuranceCard />
        </Route>

        <Route exact path="/loans">
          <Loan />
        </Route>
        <Route exact path="/insurance/apply">
          <ApplyInsurance />
        </Route>
        <Route exact path="/education">
          <Education />
        </Route>
        <Route exact path="/mutualfunds">
          <Mutual_Funds />
        </Route>
        <Route exact path="/mutualfunds/explore">
          <Explore_MF />
        </Route>
        <Route exact path="/mutualfunds/calculators">
          <MF_Calculator />
        </Route>
        <Route exact path="/mutualfund/assetreconciliation">
          <Asset_Recorder />
        </Route>
        <Route exact path="/user-profile">
          {isLoggedIn ? <Profile /> : <Home />}
        </Route>
        <Route exact path="/mutualfund/assetreconciliation/profile-page">
          <Profile_Page />
        </Route>
        <Route exact path="/mutualfund/assetreconciliation/key-people">
          <KeyPeople />
        </Route>
        <Route exact path="/mutualfund/assetreconciliation/bank-account">
          <BankAccount />
        </Route>
        <Route exact path="/mutualfund/assetreconciliation/locker">
          <Locker />
        </Route>
        <Route exact path="/mutualfund/assetreconciliation/fixed-deposit">
          <FixedDeposit />
        </Route>
        <Route exact path="/mutualfund/assetreconciliation/mutualfunds">
          <MutualFunds />
        </Route>
        <Route exact path="/mutualfund/assetreconciliation/bonds">
          <Bond />
        </Route>
        <Route exact path="/mutualfund/assetreconciliation/EPF">
          <EPF />
        </Route>
        <Route exact path="/mutualfund/assetreconciliation/NPS">
          <NPS />
        </Route>
        <Route exact path="/mutualfund/assetreconciliation/PPF">
          <PPF />
        </Route>
        <Route exact path="/mutualfund/assetreconciliation/PMS">
          <PMS />
        </Route>
        <Route exact path="/mutualfund/assetreconciliation/AIF">
          <AIF />
        </Route>
        <Route exact path="/mutualfund/assetreconciliation/indian-equity">
          <IndianEquity />
        </Route>
        <Route exact path="/mutualfund/assetreconciliation/US-equity">
          <USEquity />
        </Route>
        <Route exact path="/mutualfund/assetreconciliation/off-market">
          <OffMarket />
        </Route>
        <Route exact path="/mutualfund/assetreconciliation/real-estate">
          <RealEstate />
        </Route>
        <Route exact path="/mutualfund/assetreconciliation/life-insurance">
          <LifeInsurance />
        </Route>
        <Route exact path="/mutualfund/assetreconciliation/health-insurance">
          <HealthInsurance />
        </Route>
        <Route exact path="/mutualfund/assetreconciliation/motor-insurance">
          <MotorInsurance />
        </Route>
        <Route exact path="/mutualfund/assetreconciliation/loan">
          <Loans />
        </Route>

        <Route path="/privacypolicy">
          <PrivacyPolicy />
        </Route>

        <Route path="/terms-of-use">
          <TermsOfUse />
        </Route>

        <Route path="/partner">
          <Partner />
        </Route>

        <Route path="/contact-us">
          <ContactUs />
        </Route>

        <Route path="/about">
          <About />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default routes;
