import React, {useState, useEffect} from 'react'
import axios from "axios";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ProfileFrom from "./ProfileFrom";
import InfoFrom from './InfoFrom';
import AnnualExpanses from './annualExpanses';
import AssetAllocation from './assetAllocation';
import FinancialInstruments from './financialInstruments';
import './financial.css';
import FamilyFinancialGoals from './FamilyFinancialGoal';
import Family from './family';
import NetworkHistory from './networkHistory';
import ChildMarriage from './ChildMarriage';
import ChildEducation from './ChildEducation';
import Retirement from './Retirement';
import EmergencyFund from './EmergencyFund';
import DownloadPDF from './downloadPDF';
import FinancialGoals from './goals';

function FinancialPlanningModals({open, handleClose}) {
    const [page, setPage] = useState(1);
    const [info, setInfo] = useState(0);
    const [ profile, setProfile ] = useState({
      "name":"",
      "age":"",
      "email":"",
      "gender":""
    });

    const [options, setOptions] = useState({
      "option1":"",
      "option2":"",
      "option3":"",
      "option4":"",
      "option5": "",
      "option6": "",
      "option7": "",
      "option8": "",
      "option9": "",
      "option10": "",

    });

    // useEffect(() => {
    //   axios.get("/infrom/info")
    //   .then((res) => {
    //   setInfo([...res.data]);
    //   console.log(res);
    //   console.log(profile.name);
    //   });
    //   return(()=>
    //   {
    //     setPage(1);
    //   });
    // }, [])

    // useEffect(() => {
    //   console.log(profile.name);
    // },[])

    function handleRetry(e) {
      e.preventDefault();
      setOptions({
        "option1": "",
        "option2": "",
        "option3": "",
        "option4": "",
        "option5": "",
        "option6": "",
        "option7": "",
        "option8": "",
        "option9": "",
        "option10": ""
    });
    setPage(2);
    }

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '60%',
      maxHeight: '90%',
      overflow: 'scroll',
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    let component = 0;
    switch(page) {
      case 0:
       component = <ProfileFrom profile={profile} setProfile={setProfile} setPage={setPage} />
       default:
       component = <InfoFrom info={info} setInfo={setInfo} setPage={setPage} />
    }
  return (
    <Modal className='risk-modal'
        open={open}
        onClose={()=>{
          setPage(1);
          handleClose();
        }}
        >
        <div className='riskfactor-modal-box'>
        <button className="riskfactor-close-button" onClick={handleClose}><img src="./images/icons/close.svg" alt="" /> </button>
        {page==1?<ProfileFrom profile={profile} setProfile={setProfile} setPage={setPage}/>:
        page==2?<InfoFrom info={info} setInfo={setInfo} setPage={setPage} page={page} profile={profile["name"]} />:
        page==3?<AnnualExpanses page={page} setPage={setPage}/>:
        page==4?<AssetAllocation page={page} setPage={setPage}/>:
        page==5?<FinancialInstruments page={page} setPage={setPage}/>:
        page==6?<FamilyFinancialGoals page={page} setPage={setPage} />:
        page==7?<FinancialGoals page={page} setPage={setPage} />:
        page==8?<ChildMarriage page={page} setPage={setPage} />:
        page==9?<EmergencyFund page={page} setPage={setPage} />:
        page==10?<ChildEducation page={page} setPage={setPage} />:
        page==11?<Retirement page={page} setPage={setPage} />:
        page==12?<Family page={page} setPage={setPage}></Family>:
        page==13?<NetworkHistory page={page} setPage={setPage} />:
        page==14?<DownloadPDF page={page} setPage={setPage} handleClose = {handleClose} /> :
        <div />}
        </div>
    </Modal>
  )
}

export default FinancialPlanningModals