import React, { useEffect, useState } from "react";
import "./styles.css"


const MediaLoading = () => {
    return (<div className="center">
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
    </div>);
};

export default MediaLoading;