import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import './partner.css';
import axios from "axios";

function EModal(props) {
    const [name, setName] = useState("");
    const [query, setQuery] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    const submitForm = async () => {
        console.log("Submit form called");
        const body = {
            name: name,
            email: email,
            phone: phone,
            query: query
        }
        const formResp = await axios.post(process.env.REACT_APP_API_BASE_URL + "/post/contactus/", body)
        console.log(formResp)
        if (formResp.status == 200) {
            alert("Message has been sent succesfully. Team will get back to you shortly.")
        } else {
            alert("Sending message failed. Please retry again!")
        }
    }

    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Enroll
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="contact-form212">
                <input type="text" placeholder="Your Name *" value={name} onChange={(e) => { setName(e.target.value) }} />
                <input type="email" placeholder="Email *" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                <input type="tel" placeholder="Phone No. *" value={phone} onChange={(e) => { setPhone(e.target.value) }} />
                <textarea name="" id="" cols="10" rows="5" placeholder="Say Something... " value={query} onChange={(e) => { setQuery(e.target.value) }}></textarea>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={submitForm}>Enroll</Button>
        </Modal.Footer>
        </Modal>
    );
}


function Enroll() {
    const [modalShow, setModalShow] = useState(false);
    return (
        <>
            <div className="p_article" style={{ "backgroundColor": "#eef3ff" }}>
                <div className="p_enroll">
                    <Button onClick={() => setModalShow(true)}
                        size="large">
                            Enroll
                    </Button>
                </div>
            </div>
            <EModal 
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default Enroll;
