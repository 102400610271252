import React from 'react'
import Footer from '../components/Footer/Footer'
import Headline from '../components/Headline/Headline'
import Navbar from '../components/Navbar/Navbar'
import AssetRecorder from '../components/Mutual_Funds/AssetRecorder/AssetRecoder'
import ScrollToTop from '../components/ScrollToTop/ScrollToTop'

function Asset_Recorder() {
  return (
    <>
    <ScrollToTop />
    <Headline />
    <Navbar />
    <AssetRecorder />
    <Footer />
    </>
  )
}

export default Asset_Recorder