import { Button, Container, Link, styled, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import OTP from "./OTP";
import { Redirect } from "react-router-dom";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    minWidth: "500px"
  },
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%"
}));



const Body = styled("div")(() => ({
  flex: "0.3",
  display: "flex",
  width: "100%",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "start"
}));

const OTPBodyTop = styled("div")(() => ({
  display: "flex",
  width: "100%",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "20px"
}));


function LoginRight() {
  const [mobile, setMobile] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [otp, setOTP] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [errorOTP, setErrorOTP] = useState(false);
  const [invalidNumber, setInvalidNumber] = useState(false);

  const changeMobile = (e) => {
    setMobile(e.target.value);
  };

  const sendRequest = async () => {
    const body = {
      username: mobile
    };
    const data = await axios.post(process.env.REACT_APP_API_BASE_URL + "/auth/getotp/", body);
    return data;
  };

  const handleSubmit = () => {
    sendRequest()
      .then((data) => {
        console.log(showOTP);
        if (data.status === 200) {
          console.log("Success");
          setShowOTP(true);
        }
      })
      .catch((err) => {
      
      console.log(err.response.status)
      if (err.response.status === 400) {
        
          setInvalidNumber(true);       
      }
      console.log(err);
      
  });

  };

  const handleOTPChange = (otp) => setOTP(otp);
  const handleMobileChange = () => setShowOTP(false);
  const handleVerify = () => {
    const sendRequest = async () => {
      const body = {
        username: mobile,
        otp: otp
      };
      const data = await axios.post(process.env.REACT_APP_API_BASE_URL + "/auth/validateotp/", body);
      return data;
    };
    sendRequest()
      .then((data) => {
        localStorage.setItem('authToken', data.data.authToken);
        setShowOTP(false);
        setRedirect(true);
        window.location.reload();
      })
      .catch((err)=>{
        console.log(err.response.status)
        if (err.response.status === 401) {
          // alert(err.response.data.message)
             setErrorOTP(true);         
        }
        console.log(err);
      });
  };

  return (
    <div className="right-container">
      {redirect && <Redirect to="/" />}
      <div className="inner-container-right">
        {showOTP === false ? (
          <div className="innnnn-pio">
            <h2>Login/Signup</h2>
            <div className="input-div-container">
              <label>Phone Number </label>
              
              <input
                className="ph-input-box"
                type="number"
                placeholder="Your Phone Number"
                value={mobile}
                onChange={changeMobile}
              />

              
              {invalidNumber ? <div className="invalid-mbl">Invalid Mobile Number! </div> : <></>}
              
              <button onClick={handleSubmit}>SEND OTP</button>
              <div className="otp-text">Default OTP: 000000</div>
            </div>
            <div className="copyright">
              {" "}
              <img src="./images/icons/copyright.svg" alt="" /> StockTick 2022
            </div>
            <div style={{ flex: 0.2 }}></div>
          </div>
        ) : (
          <>
            <h2>Login/Signup</h2>

            <button className="backarrow" onClick={handleMobileChange}>
              <i className="fi fi-rr-arrow-left"></i>
            </button>

            <Body>
              <div className="otp-body-top">
                <CheckCircleRoundedIcon
                  style={{
                    background: "green",
                    color: "white",
                    borderRadius: "4px",
                    height: "30px",
                    width: "30px"
                  }}
                />
                <div className="otp-confirmation">OTP has been sent to {mobile}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "start",
                  marginBottom: "20px",
                  marginLeft: "5%"
                }}>
                <Typography variant={"h6"} style={{ color: "#AAAAAA", paddingBottom: "10px" }}>
                  Enter your OTP
                </Typography>
                <OTP otp={otp} handleChange={handleOTPChange} />
              </div>
              
              {errorOTP? <div className="invalid-otp">Invalid OTP! </div> : <></>}

              <div className="btnn-divvv">
                <button onClick={handleMobileChange}>Change Number</button>
                <div className="div-hgtr">
                  Didnt receive the OTP?
                  <button onClick={handleSubmit}>RESEND OTP</button>
                </div>
              </div>
       
              <button className="verify-btn" onClick={handleVerify}>
                Verify OTP
              </button>
              <div className="default-otp">
                Default OTP: 000000
              </div>
            </Body>
            <div className="copyright">
              {" "}
              <img src="./images/icons/copyright.svg" alt="" /> StockTick 2022
            </div>
            <div style={{ flex: 0.2 }}></div>
          </>
        )}
      </div>
    </div>
  );
}

export default LoginRight;
