import { styled } from "@mui/material";
import React from "react";
import TickList from "../Insurance/TickList";

const img = "/images/misc/img123.svg";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    height: "auto",
    paddingBottom: "30px",
    background: "rgba(106, 131, 255, 0.16)"
  },
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  backgroundImage: `url(${img})`,
  // background: "#98C1D929",
  height: "40vh",
  marginBottom: "50px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover"
}));

const MiddleLeft = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start"
  },
  flex: "0.4",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "center",
  height: "60%",
  margin: "20px"
}));

const MiddleCenter = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    width: "100%",
    marginLeft: "20%"
  },
  flex: "0.3"
}));

const MiddleRight = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    marginLeft: "20%",
    width: "100%"
  },
  flex: "0.3"
}));

function Middle() {
  return (
    <RootStyle>
      <MiddleLeft>
        <h2 className="borrow-heading">
          Borrow with <span style={{ color: "black" }}>Confidence.</span>
        </h2>
      </MiddleLeft>
      <MiddleCenter>
        <TickList
          heading="Easy and Hassle-Free"
          list={[
            "Digitally Enabled, Apply Online",
            "Quick and Simple Application Process",
            "Loans Based on your Needs"
          ]}
        />
      </MiddleCenter>
      <MiddleRight>
        <TickList
          heading="Helpful and Friendly"
          list={["Flexible Repayment", "Save on Interest", "Customer First-Approach"]}
        />
      </MiddleRight>
    </RootStyle>
  );
}

export default Middle;
