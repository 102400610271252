/* eslint-disable react/prop-types */
import { CalendarToday, CheckCircleOutlined, MonetizationOn } from "@mui/icons-material";
import { Button, Dialog, InputAdornment, styled, TextField } from "@mui/material";
import React from "react";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "90%",
    height: "100vh",
    alignItems: "center",
    marginBottom: "50px",
    minWidth: "auto"
  },
  display: "flex",
  width: "100%",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
  height: "90vh",
  marginBottom: "50px",
  backgroundColor: "white",
  minWidth: "500px"
}));

const ButtonStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "90%",
    alignItems: "center",
    marginBottom: "50px"
  },
  display: "flex",
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  marginBottom: "50px",
  backgroundColor: "white"
}));

const SuccessStyle = styled("div")(() => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "90vh",
  marginBottom: "50px",
  backgroundColor: "white",
  minWidth: "500px"
}));

const RowStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "80%",
    alignItems: "center",
    marginBottom: "10px"
  },
  display: "flex",
  width: "90%",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  marginBottom: "10px",
  color: "#45A69F"
}));

function HealthStepTwo({
  open,
  handleClose,
  handleSubmit,
  handlePrev,
  family,
  disease,
  handleFamily,
  handleDisease,
  success
}) {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"500px"}>
      {success ? (
        <SuccessStyle>
          <h1>SUCCESS!!</h1>
          <CheckCircleOutlined style={{ color: "#00C314", fontSize: "40px" }} />
          <p>Our team will contact you shortly.</p>
        </SuccessStyle>
      ) : (
        <RootStyle>
          <div style={{ flex: "0.1" }}>
            <h1 style={{ color: "#45A69F", textAlign: "center" }}>HEALTH INSURANCE</h1>
          </div>
          <RowStyle>
            <TextField
              id="input-with-icon-textfield"
              label="No of Family Member"
              value={family}
              onChange={handleFamily}
              InputLabelProps={{
                style: { color: "#45A69F", fontSize: "20px", fontWeight: "600" }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {/* <CalendarToday /> */}
                  </InputAdornment>
                )
              }}
              variant="standard"
              sx={{ marginBottom: "15px" }}
            />
            <TextField
              id="input-with-icon-textfield"
              label="Pre-Existing Disease"
              value={disease}
              onChange={handleDisease}
              InputLabelProps={{
                style: { color: "#45A69F", fontSize: "20px", fontWeight: "600" }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {/* <MonetizationOn /> */}
                  </InputAdornment>
                )
              }}
              variant="standard"
              sx={{ marginBottom: "15px" }}
            />
          </RowStyle>
          <ButtonStyle>
            <Button
              sx={{ background: "#45A69F", color: "white", width: "200px" , "&:hover": {backgroundColor: "#45A69F", } }}
              onClick={handlePrev}>
              Prev <img src="/images/icons/login.png" alt=''/>{" "}
            </Button>
            <Button
              sx={{ background: "#45A69F", color: "white", width: "200px" , "&:hover": {backgroundColor: "#45A69F", } }}
              onClick={handleSubmit}>
              Submit <img src="/images/icons/login.png" alt=''/>{" "}
            </Button>
          </ButtonStyle>
        </RootStyle>
      )}
    </Dialog>
  );
}

export default HealthStepTwo;
