import { Button, Grid, styled } from "@mui/material";
import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
// import poster from "../images/Rectangle.svg";
import axios from "axios";
import GetAssistanceForm from "../Slider/GetAssistanceForm";
// import mutualFunds from '../images/Rectangle.svg'
import "./mf.css";

function MFPoster() {
  const [assistance, setAssistance] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    query: ""
  });

  const handleAssistance = async () => {
    const data = await axios
      .post("https://api.stocktick.in/getassistance/", form, {
        headers: {
          Connection: "keep-alive",
          authToken: localStorage.getItem("authToken"),
          accepts: "application/json"
        }
      })
      .then(() => {
        setForm({
          name: "",
          email: "",
          phone: "",
          query: ""
        });
        setAssistance(false);
      });
    console.log(data);
  };

  return (
    <>
      <div className="mutualfund-poster">
        <div className="mf-mobile">
          {/* <img src="/images/misc/mfBanner.mp4" alt="" /> */}
          <video autoPlay loop muted>
            <source src="/images/misc/StockTick-3d Video Optimized-2.mp4" type="video/mp4" />
            Error Message
          </video>
        </div>

        {/* <div className="mf-content-mobile">
          <h1>Expaaand your money with</h1>
        </div> */}
        <div className="mutualfund-poster-left">
          <h1>Expand your wealth & retire rich with </h1>
          <h2> Mutual Funds! </h2>

          <p>
            Let your money work for you. Start your journey of wealth creation and financial
            independence.
          </p>

          <a
            href="https://calendly.com/vijaymaheshwari/financialfreedom"
            target="_blank"
            rel="noreferrer"
            className="apply-now-btn-mf1"
            style={{ textDecoration: 'none' }}
          >
            Book A Free Call
          </a>
        </div>

        <div className="mutualfund-poster-right">
          {/* <img src="/images/misc/mfBanner.mp4" alt="" /> */}
          <video autoPlay loop muted>
            <source src="/images/misc/mfBanner.mp4" type="video/mp4" />
            Error Message
          </video>
        </div>

        <div className="head-div-1">
          <button className="heda123" onClick={() => setAssistance(true)}>
            {" "}
            Get Assistance!
          </button>
        </div>
        <GetAssistanceForm
          open={assistance}
          handleClose={() => {
            setAssistance(false);
          }}
          handleSubmit={handleAssistance}
          form={form}
          setForm={setForm}
        />
      </div>
    </>
  );
}

export default MFPoster;
