import React, { useEffect, useState } from "react";
import "./financial.css";
import { Icon, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import finIcon from "./fin_icon.png";
import { styled } from "@mui/material";
// import { useEffect } from "react";
import getProfile from "../../getProfile";
import axios from "axios";


function ProfileFrom({ profile, setProfile, setPage }) {

  // const [userProfile, setUserProfile] = useState({});
  console.log("these are the props", profile, setProfile, setPage);

  function handleSubmit(e) {
    e.preventDefault();
    const authToken = localStorage.getItem("authToken");
    axios
      .put(process.env.REACT_APP_API_BASE_URL + `/update/userinfo/?authToken=${authToken}`, profile)
      .then((res) => {
        console.log(profile);
        console.log(res);
        setPage(2);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(async () => {
    const profile = await getProfile();
    // setUserProfile(profile)
    console.log(profile)
    setProfile(profile)
  }, [])

  const Cards = styled("div")(({ theme }) => ({
    [theme.breakpoints.down("md")]:
    {
      display: "flex",
      flexDirection: "column",
    },
    display: "flex",
    flexDirection: "row",
    minHeight: "200px",
    maxWidth: "250px"
  }));

  const Headers = styled("div")(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: '20px',
    },
    color: "#604FB2",
    textAlign: "center",
    fontFamily: 'Roboto',
    fontSize: '31px',
    fontWeight: 600,
    fontStyle: 'normal'
  }));

  const Headers1 = styled("div")(({ theme }) => ({
    [theme.breakpoints.down(("md"))]: {
      fontSize: '16px',
      marginLeft: '40px'
    },
    fontSize: '25px',
    color: 'black',
    marginLeft: '410px',
    display: 'grid'
  }));

  const ImageBox = styled("div")(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      display: 'none',
      width: '0px',
      height: '0px'
    },
    width: '250px',
    
  }));

  return (
    <div className="profile-form" >
      <h3>WELCOME TO FINANCIAL PLANNING</h3>
      <h3 > Hey !</h3>
      <div className="financial-card">
        <ImageBox >
          <img src={finIcon} className="modified-img-alt" alt="financial_icon" />
        </ImageBox>
        <div className="label-inputs-com">
          <div className="financial-labels">
            <select>
              <option value="mr" selected>Mr</option>
              <option value="mrs">Mrs</option>
              <option value="ms">MS</option>
              <option value="miss">Miss</option>
            </select>


            <input
              type="text"
              placeholder="Name"
              value={profile.name || ""}
              onChange={(e) => {
                setProfile({ ...profile, name: e.target.value });
              }}>

            </input>


          </div>
          <div className="financial-labels">

            <label className="hedal2">Enter  Age</label>

            <input
              className="age-input-58"
              type="number"
              placeholder="Enter Age"
              value={profile.age || 0}
              onChange={(e) => {
                setProfile({ ...profile, age: e.target.value });
              }}
            />
          </ div>
        </div>
      </div>
      <br />
      <button variant="contained" onClick={handleSubmit} className='started-button16' endIcon={
        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>}> Get Started
      </button>
    </div>
  );
}

export default ProfileFrom;
