/* eslint-disable react/prop-types */
import React, { useState } from "react";
import sendRequest from "../../../helpers/sendPostRequest";
import axios from "axios";
import CarStepOne from "./CarStepOne";
import CarStepThree from "./CarStepThree";
import CarStepTwo from "./CarStepTwo";

function Car({ open, handleClose }) {
  const [step, setStep] = useState(1);
  const [mandate, setManDate] = useState(new Date());
  const [manufac, setManufac] = useState("");
  const [pClaim, setPClaim] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [vno, setVno] = useState("");
  const [model, setModel] = useState("");
  const [ftype, setFtype] = useState("");
  const [variant, setVariant] = useState("");
  const [currInsurer, setCurrInsurer] = useState("");
  const [puc, setPuc] = useState(false);
  const [pucExp, setPucExp] = useState(new Date());
  const [own, setOwn] = useState(false);
  const [polExp, setPolExp] = useState(new Date());
  const [success, setSuccess] = useState(false);

  const handleName = (e) => setName(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);
  const handlePhone = (e) => setMobile(e.target.value);
  const handleCurrInsurer = (e) => setCurrInsurer(e.target.value);
  const handleVno = (e) => setVno(e.target.value);
  const handleFtype = (e) => setFtype(e.target.value);
  const handleManufac = (e) => setManufac(e.target.value);
  const handleVariant = (e) => setVariant(e.target.value);
  const handleModel = (e) => setModel(e.target.value);
  const handlePClaim = () => setPClaim(!pClaim);
  const handleOwn = (e) => setOwn(e.target.checked);
  const handlePuc = (e) => setPuc(e.target.checked);
  const handlePolExp = (val) => setPolExp(val);
  const handlePucExp = (val) => setPucExp(val);
  const handleManDate = (val) => setManDate(val);

  const handleSubmit = () => {
    const body = {
      type: "motor",
      manufac_date: mandate,
      manufacturer: manufac,
      current_policy_claim: pClaim,
      name,
      email,
      mobile,
      vehicle_number: vno,
      model,
      fuel_type: ftype,
      variant,
      current_insurer: currInsurer,
      puc,
      puc_expiry: pucExp,
      month_12_ownership: own,
      policy_expiry: polExp
    };

    axios.post(process.env.REACT_APP_API_BASE_URL + `/add/insurance/details/`, body)
      .then((data) => {
        if (data.status === 200) {
          console.log(data);
          setSuccess(true);
          // handleClose();
          // alert("Insurance added.");
        }
      })
      .catch((e) => console.log(e));
  };

  const handleNext = () => {
    setStep(step + 1);
  };
  const handlePrev = () => {
    setStep(step - 1);
  };
  return (
    <>
      {step === 1 && (
        <CarStepOne
          open={open}
          handleClose={handleClose}
          handlePrev={handlePrev}
          handleNext={handleNext}
          name={name}
          email={email}
          phone={mobile}
          vno={vno}
          handleName={handleName}
          handleEmail={handleEmail}
          handlePhone={handlePhone}
          handleVno={handleVno}
        />
      )}
      {step === 2 && (
        <CarStepTwo
          handleNext={handleNext}
          open={open}
          handleClose={handleClose}
          handlePrev={handlePrev}
          manufac={manufac}
          model={model}
          ftype={ftype}
          variant={variant}
          mandate={mandate}
          handleManufac={handleManufac}
          handleModel={handleModel}
          handleFtype={handleFtype}
          handleVariant={handleVariant}
          handleManDate={handleManDate}
        />
      )}
      {step === 3 && (
        <CarStepThree
          handleNext={handleNext}
          open={open}
          handleClose={handleClose}
          handlePrev={handlePrev}
          currInsurer={currInsurer}
          polExp={polExp}
          puc={puc}
          pucExp={pucExp}
          own={own}
          success={success}
          handleCurrInsurer={handleCurrInsurer}
          handlePolExp={handlePolExp}
          handlePuc={handlePuc}
          handlePucExp={handlePucExp}
          handleOwn={handleOwn}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
}

export default Car;
