import React from "react";
import "./newsletter.css";

function NewsLetter() {
  return (
    <div className="newsletter-parent">
      <div className="max-width">
      <div className="newsletter-img">
        <img src="/images/misc/newsletter.svg" />
      </div>
      <div className="newsletter-content">
        <div className="newsletter-heading">
          Sign up for <br /> Our Newsletters
        </div>

        <div className="input-div-for-newsletter">
          <input type="text" placeholder="Enter your Email ID"></input>
        </div>
        <div className="newsletter-texttt">You will recieve mails for recent updates.</div>

        <button className="newsletter-btn">Subscribe</button>
      </div>
      </div>
    </div>
  );
}

export default NewsLetter;