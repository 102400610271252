import axios, { AxiosRequestConfig } from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./Headline.css";


function Headline() {
  const [headline, setHeadline] = useState("");
  const location = useLocation();
  let platform = "H";
  if (location.pathname === "/loans") platform = "L";
  else if (location.pathname === "/insurance") platform = "I";
  else if (location.pathname === "/education") platform = "E";
  else if (location.pathname === "/media") platform = "M";
  else if (location.pathname === "/mutualfunds") platform = "MF";
  else platform = "H";

  useEffect(() => {
    let config = {
      baseURL: process.env.REACT_APP_API_BASE_URL,
      url: `/get/headlines`,
      method: 'GET',
      params: {
        "page": platform,
        "platform": "W"

      }
    }

    axios.request(config).then((res) => {
      setHeadline(res.data.headline)
    }).catch(err => {
      console.log(err)
    });
    console.log("I am called:")
  }, []);

  return <div className="headline">{headline && <span className="text">{headline}</span>}</div>;
}

export default Headline;
