import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ProfileForm from "./ProfileForm";
import RiskFactorQuestion from "./RiskFactorQuestion";
import RiskFactorResult from "./RiskFactorResult";
import "./modal.css";

function RiskFactorModals({ open, handleClose }) {
  const [page, setPage] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [profile, setProfile] = useState({
    name: '',
    email: '',
    age: 18,
    gender: 'Male'
  });

  const [options, setOptions] = useState({
    option1: null,
    option2: null,
    option3: null,
    option4: null,
    option5: null,
    option6: null,
    option7: null,
    option8: null,
    option9: null,
    option10: null
  });

  //   const [options, setOptions] = useState({
  //     "option1": 1,
  //     "option2": 2,
  //     "option3": 2,
  //     "option4": 1,
  //     "option5": 3,
  //     "option6": 1,
  //     "option7": 2,
  //     "option8": 1,
  //     "option9": 3,
  //     "option10": 4
  // });

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_BASE_URL + "/riskfactor/questions/").then((res) => {
      setQuestions([...res.data]);
      console.log(res);
    });
  }, []);

  function handleRetry(e) {
    e.preventDefault();
    setOptions({
      option1: null,
      option2: null,
      option3: null,
      option4: null,
      option5: null,
      option6: null,
      option7: null,
      option8: null,
      option9: null,
      option10: null
    });
    setPage(1);
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    maxHeight: "90%",
    overflow: "scroll",
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4
  };

  let component = 0;
  switch (page) {
    case 0:
      component = <ProfileForm profile={profile} setProfile={setProfile} setPage={setPage} />;
    case 11:
      component = <RiskFactorResult />;
    default:
      component = (
        <RiskFactorQuestion
          pageNumber={page}
          question={questions[page - 1]}
          setPageNumber={setPage}
          setOption={(val) => {
            setOptions({ ...options, ["option" + page]: val });
          }}
          options={options}
        />
      );
  }

  return (
    <Modal className="risk-modal" open={open} onClose={handleClose}>
      <div className="riskfactor-modal-box">
        <button className="riskfactor-close-button" onClick={handleClose}>
          <img src="./images/icons/close.svg" alt="" />{" "}
        </button>
       
        
        {!page ? (
          <ProfileForm profile={profile} setProfile={setProfile} setPage={setPage} />
        ) : page === 11 ? (
          <RiskFactorResult handleRetry={handleRetry} />
        ) : (
          <RiskFactorQuestion
            pageNumber={page}
            question={questions[page - 1]}
            setPageNumber={setPage}
            setOption={(val) => {
              setOptions({ ...options, ["option" + page]: val.toString() });
            }}
            setOptions={setOptions}
            options={options}
          />
        )}
      </div>
    </Modal>
  );
}

export default RiskFactorModals;
