import React, { useEffect, useState } from 'react';
import "./css/Profile.css";
import axios from "axios";
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import URLise from '../components/fileUploader';
import getProfile from '../components/getProfile';
import img1 from "./Vector1.png";
import img2 from "./Vector2.png";
import { FiEdit3, FiEdit2 } from "react-icons/fi";
import logo from "./logo.svg";
import { Link } from "react-router-dom";
import Headline from '../components/Headline/Headline';

const Profile = () => {

  const [pic, setPic] = useState(null);
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const [gender, setGender] = useState(null);
  const [age, setAge] = useState('M');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState("Phone");
  const [password, setPassword] = useState("Password");

  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    axios.get(`https://api.stocktick.in/get/userinfo?authToken=${authToken}`).then(res => {
      setPic(res.data.profile_url)
      setName(res.data.name);
      setEmail(res.data.email);
      setAge(res.data.age);
      setGender(res.data.gender);
      setPhone(res.data.phone);
      setPassword(res.data.password);
    })
  }, [])

  async function updateDetails() {
    const picture = file ? await URLise(file) : pic;
    const authToken = localStorage.getItem("authToken");
    console.log('PIC:', picture);
    axios.put(`https://api.stocktick.in/update/userinfo/?authToken=${authToken}`, {
      name: name,
      email: email,
      gender: gender,
      age: age,
      profile_url: picture,
    })
  }

  return (
    <>
      <Headline />
      <Navbar />
      <div className='profile-page'>
        <img src={img1} alt="design" className='profile-page-img1' />

       
        <div className='profile-container'>
          <label className='profile-container-logo' htmlFor='profile-pic'>
            <img src={pic ? pic : "/images/icons/customer-dp.png"} alt="" />
            <div className='profile-container-logo-edit'>
              <FiEdit2 style={{ fontSize: "17px", fontColor: "#909090", marginBottom: "" }} />
            </div>
          </label>
          <div className="profile-container-credeb">
            <div className="profile-container-credeb1">
              <span style={{ fontSize: "16px", color: "white", marginLeft: "10px" }}>Credit</span>
              <span style={{ fontSize: "20px", color: "white", marginLeft: "10px" }}>18000 &nbsp; INR</span>
            </div>
            <div className="profile-container-credeb2">
              <span style={{ fontSize: "16px", color: "white", marginLeft: "10px" }}>Debit</span>
              <span style={{ fontSize: "20px", color: "white", marginLeft: "10px" }}>18000 &nbsp; INR</span>
            </div>
          </div>
          <div className='test1'>
            <h3>We are upgrading the tool for you. Work in progress</h3>
          </div>
          <div className="profile-container-user">
            <div className='profile-container-user-info'>
              <input type="text" placeholder="Name" className="profile-container-user-info-input" autoComplete='new-password' value={name} required onChange={(e) => { setName(e.target.value) }} 
              />
              <FiEdit3 style={{ fontSize: "22px", fontColor: "#909090", position: "relative", top: "5px" }} />                                                                                                   
            </div>
            <div className='profile-container-user-info'>
              <input type="email" placeholder="Email" className="profile-container-user-info-input" autoComplete='new-password' value={email} required onChange={(e) => { setEmail(e.target.value) }} />
              
              <FiEdit3 style={{ fontSize: "22px", fontColor: "#909090", position: "relative", top: "5px" }} />
            </div>
            <div className='age-gender-flex'>
              <input type='number' placeholder='Age' max={110} min={18} value={age} onChange={(e) => { setAge(e.target.value) }} />


              <select className='select-gender' value={gender} onChange={(e) => { setGender(e.target.value) }} >

                <option value='M'>Male</option>
                <option value='F'>Female</option>
                <option value='B'>Bisexual</option>
              </select>
            </div>
            <div className='profile-container-user-info'>
              <input type="tel" placeholder={phone} disabled className="profile-container-user-info-input" autoComplete='new-password' value={phone} required />
              <FiEdit3 style={{ fontSize: "22px", fontColor: "#909090", position: "relative", top: "5px" }} />
            </div>
            <div className='profile-container-user-info'>
              <input type="text" placeholder={password} className="profile-container-user-info-input" autoComplete='new-password' value={password} required onChange={(e) => { setPassword(e.target.value) }} />
              <FiEdit3 style={{ fontSize: "22px", fontColor: "#909090", position: "relative", top: "5px" }} />
            </div>
          </div>
          <div className="profile-container-button" onClick={updateDetails}>
            <button className="profile-container-button1">
              Save
            </button>
          </div>
        </div>
        <img src={img2} alt="design" className='profile-page-img2' />
        <input type='file' id='profile-pic' hidden onChange={(e) => {
          console.log(e.target.files[0])
          console.log(getProfile());
          setFile(e.target.files[0]);
          setPic(URL.createObjectURL(e.target.files[0]));
        }} />
      </div>
      <Footer />
    </>
  )
}

export default Profile