import React from 'react'

function containsObject(obj, list) {
  var i;
  for (i = 0; i < list.length; i++) {
    if (list[i].id === obj.id) {
      return true;
    }
  }
  return false;
}

export function TagsList({ tags, selectedTags, setTags, setSelectedTags }) {
  return (
    <div className="tags-list">
      {tags.map((item) => {
        return (<div key={item.title}>
          <div onClick={() => {
            if (!containsObject(item, selectedTags)) {
              setSelectedTags([...selectedTags, item]);
            }
          }}>{item.title}</div>
        </div>)
      })}
    </div>
  )
}

export function SelectedTagsList({ selectedTags, setSelectedTags }) {
  return (
    <div className="tags-list-sel">
      {selectedTags.map((item) => {
        return (<div key={item.id}>
          <div>{item.title}</div>
          <button onClick={(e) => { e.preventDefault(); setSelectedTags([...selectedTags.filter(v => v.id !== item.id)]) }}>X</button>
        </div>)
      })}
      {selectedTags.length === 0 ? <div id='choose-tags'>No tags selected. Choose tags from below.</div> : null}
    </div>
  )
}

// export default {TagsList, SelectedTagsList}