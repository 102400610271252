import React from "react";
import "./Independence.css";
import independ from "../images/home5.svg";

function Independence() {
  return (
    <div className="independence">
      {/* <div className="ind_info"> */}
      {/* <h1>Financial Independence with us.</h1>
        <p>We work in ALGO and EQ model</p> */}

      <video autoPlay loop muted className="ind_info">
        <source src="/images/Fire-Financial freedom No Audio.mp4" type="video/mp4" />
        Error Message
      </video>
      {/* </div> */}
      {/* <div className="floting-box"></div> */}
    </div>
  );
}

export default Independence;
