/* eslint-disable react/prop-types */
import React from "react";
import OtpInput from "react-otp-input";
import './login.css';

function OTP({ otp, handleChange }) {
  return (
    <div >
      <OtpInput
       
        style={{ padding: "10px" }}
        value={otp}
        onChange={handleChange}
        numInputs={6}
        separator={<span style={{ width: "8px" }}></span>}
        isInputNum={true}
        shouldAutoFocus={true}
        inputStyle={{
          border: "1px solid #E7ECF4",
          borderRadius: "8px",
          width: "40px",
          height: "40px",
          fontSize: "16px",
          color: "#000",
          fontWeight: "600",
          caretColor: "blue",
          background: "#fafafc",
        }}
        focusStyle={{
          border: "1px solid #CFD3DB",
          outline: "none",
          color: "blue"
        }}
      />
    </div>
  );
}

export default OTP;
