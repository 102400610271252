import { styled } from "@mui/material";
import React from "react";
import img1 from "../images/Ellipse7.svg";
import TickList from "../Insurance/TickList";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "../Insurance/ticklist.css";
import fundbazar from "../images/fund-b.svg";

const img = "/images/misc/img123.svg";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    height: "auto",
    marginBottom: "0px",
    background: "#E8E6F4",
    paddingTop: "35px",
    background: "rgba(82, 204, 195, 0.1)"
  },
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  backgroundImage: `url(${img})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: "52vh",
  marginBottom: "50px",
  marginTop: "80px"
}));

const MiddleLeft = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: "auto"
  },
  flex: "0.4",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "center",
  height: "60%"
  // margin: "10px 20px"
}));

const MiddleCenter = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    width: "100%",
    marginLeft: "20%"
  },
  flex: "0.3",
  marginBottom: "20px"
}));

const MiddleRight = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    marginLeft: "20%",
    width: "100%"
  },
  flex: "0.3",
  marginBottom: "20px"
}));
function MiddleMutualFunds() {
  const history = useHistory();
  return (
    <RootStyle>
      <MiddleLeft>
        <img className="fundz-logo" src={fundbazar} alt="" />
        <h2 className="design-fundbazar">
          FUNDZ<span style={{ color: "#F76300" }}>BAZAR</span>
        </h2>
        {/* <Link to="https://www.fundzbazar.com/signin"> */}
        <a href="https://fundzbazar.com/Link/Pj9zkiEx5to" target="_blank" rel="noreferrer">
          <button
            className="fundbazar-btn"
            // onClick={() => history.push("https://www.fundzbazar.com/signin")}>
          >
            Visit
          </button>

          {/* </Link> */}
        </a>
      </MiddleLeft>
      <MiddleCenter>
        <TickList
          heading="Easy and Hassle-Free"
          list={[
            "Simple and Easy ",
            "Customized SIP portfolio",
            "Quick online transaction",
            "Multi-Asset allocation under one roof"
          ]}
        />
      </MiddleCenter>
      <MiddleRight>
        <TickList
          heading="Helpful and Friendly"
          list={[
            "Investor Friendly",
            "Wide Branch Network",
            "Insta Overdraft facility",
            "Personalized attention to your needs"
          ]}
        />
      </MiddleRight>
    </RootStyle>
  );
}

export default MiddleMutualFunds;
