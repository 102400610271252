/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import Headline from "../components/Headline/Headline";

import MiddleLanding from "../components/MiddleLanding/MiddleLanding";
import Navbar from "../components/Navbar/Navbar";
import OurServices from "../components/MiddleLanding/OurServices";
import QrBox from "../components/MiddleLanding/QrBox";
import Footer from "../components/Footer/Footer";
import NewsLetter from "../components/MiddleLanding/NewsLetter";
import Independence from "../components/Independence/Independence";
import Slider from "../components/Slider/Slider";
import Appbar from "../components/Appbar/Appbar";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import MaintainanacePopup from '../pages/MaintainancePopup';
import '../App.css';
import './css/home.css';
import MaintainanceModal from "../components/Modals/MaintainanceModal";
import PosterHome from "../components/Slider/PosterHome";

// Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";

// import required modules
// import { Mousewheel, Pagination } from "swiper";

function Home() {

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <div className="home">


      <ScrollToTop />
      <Headline />
      <Navbar />
      {/* <MaintainanceModal open={open} handleClose={handleClose} /> */}
      {/* <Slider /> */}
      <PosterHome />
      {/* <MaintainanacePopup /> */}


      <Independence />

      <MiddleLanding />

      <OurServices />


      <QrBox />
      <NewsLetter />
      <Footer />

    </div>
  );
}

export default Home;
