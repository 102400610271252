import React, {useState} from 'react'
import ReactDOM from "react-dom";

import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import "./editor.css";

import draftToHtml from 'draftjs-to-html';

function Sample() {
    const [expand, setExpand] = useState(false);
    return(
        <>
        <div onClick={() => {setExpand(!expand)}}>
            Heyo!
        </div>
        {expand ? <div>Upload Image</div> : null}
        </>
    )
}


function BlogEditor({content, setContent}) {
    // var editorState = EditorState.createEmpty();
    // const [content, setContent] = useState(editorState);
    const [editorState, setEditorState] = useState(() => {
            EditorState.createEmpty()
    })

    const updateEditor = async (state) => {
        await setEditorState(state);
        // setContent(editorState);        
        const data = convertToRaw(editorState.getCurrentContent()); 
        setContent(data);
        // console.log(EditorState.createWithContent(convertFromRaw(JSON.parse(current.description)))) 
        };
        function parseContent() {
            if(content !== "") {
                return draftToHtml(content);
            }
            else {
                return (<p></p>)
            }
        }

    
  return (
      <>
    <Editor
        wrapperClassName="editor-container"
        editorClassName="blog-editor"
        toolbarClassName="blog-toolbar"
        editorState={editorState}
        onEditorStateChange={updateEditor}
        // toolbar={{
        //     image: { component: Sample },
        // }}
    />


    {/* <Editor
        editorState={editorState}
    /> */}
    </>
  )
}

export default BlogEditor