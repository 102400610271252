import { Button, Grid, styled } from "@mui/material";
import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import loan from "../images/education.svg";
import axios from "axios";
import "./education.css";
import GetAssistanceForm from "../Slider/GetAssistanceForm";

const GridStyle = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    height: "auto",
    marginBottom: "50px"
  },
  height: "90vh"
}));

const PosterLeft = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    marginLeft: "10%"
  },
  display: "flex",
  padding: "10%",
  position: "relative",
  top: "20%",
  flexDirection: "column",
  justifyContent: "left",
  alignItems: "left",
  marginLeft: "20%"
}));

const Image = styled("img")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100vw",
    height: "80vh"
  },
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "40vw",
  height: "auto"
}));

function Poster() {
  const [assistance, setAssistance] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    query: ""
  });

  const handleAssistance = async () => {
    const data = await axios
      .post("https://api.stocktick.in/getassistance/", form, {
        headers: {
          Connection: "keep-alive",
          authToken: localStorage.getItem("authToken"),
          accepts: "application/json"
        }
      })
      .then(() => {
        setForm({
          name: "",
          email: "",
          phone: "",
          query: ""
        });
        setAssistance(false);
      });
    console.log(data);
  };

  return (
    <>
      <div className="education-poster">
        <div className="education-mob-img">
          <img src="/images/misc/education.svg" alt="" />
        </div>
        <div className="poster-left">
          <h1>
            Financial Education, Training & <br /> &{" "}
            <span style={{ color: "#FF7666" }}>Webinars</span>{" "}
          </h1>

          <p>
          Financial education can give you freedom, security and break down the barriers standing between you and your life goals
          </p>
        </div>
        <div className="poster-image">
          <img src="/images/misc/education.svg" alt="" />
        </div>

        <div className="head-div-1">
          <button className="heda123" onClick={() => setAssistance(true)}>
            {" "}
            Get Assistance!
          </button>
        </div>
        <GetAssistanceForm
          open={assistance}
          handleClose={() => {
            setAssistance(false);
          }}
          handleSubmit={handleAssistance}
          form={form}
          setForm={setForm}
        />
      </div>
    </>
  );
}

export default Poster;
