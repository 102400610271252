import React, { useState, useEffect } from "react";
import Slider from "@material-ui/core/Slider";
import Box from "@material-ui/core/Box";
import { format } from "indian-number-format"
import './Retirement.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { set } from "react-hook-form";
// import { parse } from "dotenv";

const Retirement = ({ page, setPage }) => {
  //input variables
  const [currentExpenses, setCurrentExpenses] = useState(20000);
  const [inflation, setInflation] = useState(5);
  const [currentAge, setCurrentAge] = useState(25);
  const [retirementAge, setRetirementAge] = useState(60);
  const [lifeExpectancy, setLifeExpectancy] = useState(80);
  const [currentInvestment, setCurrentInvestment] = useState(20000);
  const [returnRate, setReturnRate] = useState(5);

  //output variables
  const [requiredAmount, setRequiredAmount] = useState(0);
  const [todayInvestment, setTodayInvestment] = useState(0);
  const [deficitCorpus, setDeficitCorpus] = useState(0);
  const [lumpsumFunding, setLumpsumFunding] = useState(0);
  const [monthlyInvestment, setMonthlyInvestment] = useState(0);

  const handleChange1 = (e, value) => {
    setCurrentExpenses(value)
  }
  const handleChange2 = (e, value) => {
    setInflation(value)
  }
  const handleChange3 = (e, value) => {
    setCurrentAge(value)
    // if (requiredAge < value) {
    //   setRetirementAge(value)
    // }
  }
  const handleChange4 = (e, value) => {
    setRetirementAge(value);
    if (value < currentAge) {
      setCurrentAge(value)
    }
  }

  const handleChange5 = (e, value) => {
    setLifeExpectancy(value);
  }

  const handleChange6 = (e, value) => {
    setCurrentInvestment(value);
  }


  const handleChange7 = (event, value) => {
    setReturnRate(value);
  };

  useEffect(async () => {
    const token = localStorage.getItem("authToken");
    console.log("token", token)
    const url = process.env.REACT_APP_API_BASE_URL + "/finantialplanning/finnantial-RetirementCalc?authToken=" + token
    await fetch(url,
      {
        method: "GET",
      })
      .then(res => res.json())
      .then(data => {
        console.log(data)
        if (data && data.length > 0) {
          const res = data[0]
          setCurrentExpenses(res.monthly_income_reqd)
          setInflation(res.inflation_rate)
          setCurrentAge(res.age)
          setRetirementAge(res.retirement_age)
          setLifeExpectancy(res.life_expectancy)
          setCurrentInvestment(res.current_investment)
          setReturnRate(res.return_rate)
          setRequiredAmount(res.annual_income_retire)
          setTodayInvestment(res.investment_appreciation)
          setDeficitCorpus(res.corpus_deficit)
          setLumpsumFunding(res.lumpsum_fund_reqd)
          setMonthlyInvestment(res.monthly_investment_reqd)
        }
      })
      .catch(err => {
        console.log(err)
      })

  }, [])

  async function handleSubmit(e) {
    const token = localStorage.getItem("authToken")
    const url = process.env.REACT_APP_API_BASE_URL + "/finantialplanning/finnantial-RetirementCalc?authToken=" + token
    await fetch(url, {
      method: "POST",
      headers: {
        // "authToken" : token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        monthly_income_reqd: currentExpenses,
        inflation_rate: inflation,
        age: currentAge,
        retirement_age: retirementAge,
        life_expectancy: lifeExpectancy,
        current_investment: currentInvestment,
        return_rate: returnRate,
        annual_income_retire: requiredAmount,
        investment_appreciation: todayInvestment,
        corpus_deficit: deficitCorpus,
        lumpsum_fund_reqd: lumpsumFunding,
        monthly_investment_reqd: monthlyInvestment

      })
    })
      .then((res) => res.json())
      .then(data => {
        console.log("response from adding data ", data)
        if (data.message == "Data saved") {
          console.log("Data saved succesfully")
          setPage(page + 1);
        }
        else {
          alert("Something went wrong while submitting your data, please try again")
        }
      })
      .catch(err => {
        console.log(err)
        alert("Something went wrong, please check your internet connection and try again")
      })
    e.preventDefault();
  }

  useEffect(() => {
    //the calculation occurs here
    let f_cost, m_invest;
    // f_cost = currentCost*Math.pow(1 + inflation/100, requiredAge-currentAge)
    // let r = returnRate;
    // r = r/1200;
    // m_invest = f_cost/(((Math.pow(1+r, (requiredAge-currentAge)*12)-1)/r)*(r+1));
    // setFutureCost(parseInt(f_cost));
    // setMonthlyInvestment(parseInt(m_invest));
    let required_amount = currentExpenses * Math.pow((1 + inflation / 100), retirementAge - currentAge) * 12;
    let appreciation = currentInvestment * Math.pow((1 + returnRate / 100), retirementAge - currentAge);
    setRequiredAmount(parseInt(required_amount));
    setTodayInvestment(parseInt(appreciation));

  },
    [currentAge, currentExpenses, inflation, retirementAge, lifeExpectancy, currentInvestment, returnRate])

  return (
    <>
      <h1 className="hedarf">Retirement  Calculator</h1>
      <div className="retirement58">
        <div className="marriageinput58">
          <div className="marriageheading">
            Systematic Investment Plan (SIP) allows you to make small investment at regular
            intervals to help you achieve your dreams.
          </div>
          <div className="goalslider">
            <div className="goalinfo-title-text">
              <h1>Monthly Income Required In Retirement Years  </h1>
              <div className="goalinfo-title-text-sub">
                <span> &#8377;</span>
                <input type="number" placeholder="0" onChange={(e) => setCurrentExpenses(e.target.value)} value={currentExpenses} />
              </div>
            </div>
            <Box >
              <Slider
                aria-label="Volume"
                value={currentExpenses}
                onChange={handleChange1}
                max={500000}
              />
            </Box>
          </div>
          <div className="goalslider">
            <div className="goalinfo-title-text">
              <h1>Inflation Rate</h1>
              <div className="goalinfo-title-text-sub">
                <input type="number" placeholder="0" onChange={(e) => setInflation(e.target.value)} value={inflation} />

                <span>&nbsp;%</span>
              </div>
            </div>
            <Box >
              <Slider
                aria-label="Volume"
                value={inflation}
                onChange={handleChange2}
                min={3}
                max={15}
              />
            </Box>
          </div>
          <div className="goalslider">
            <div className="goalinfo-title-text">
              <h1>Current Age </h1>
              <div className="goalinfo-title-text-sub">
                <input type="number" placeholder="0" onChange={(e) => setCurrentAge(e.target.value)} value={currentAge} />
                <span>&nbsp;Y</span>
              </div>
            </div>
            <Box >
              <Slider
                aria-label="Volume"
                value={currentAge}
                onChange={handleChange3}
                min={20}
                max={60}
              />
            </Box>
          </div>
          <div className="goalslider">
            <div className="goalinfo-title-text">
              <h1>Retirement Age </h1>
              <div className="goalinfo-title-text-sub">
                <input type="number" placeholder="0" onChange={(e) => setRetirementAge(e.target.value)} value={retirementAge} />
                <span>&nbsp;Y</span>
              </div>
            </div>
            <Box >
              <Slider
                aria-label="Volume"
                value={retirementAge}
                onChange={handleChange4}
                max={70}
              />
            </Box>
          </div>
          <div className="goalslider">
            <div className="goalinfo-title-text">
              <h1>Life Expectancy </h1>
              <div className="goalinfo-title-text-sub">
                <input type="number" placeholder="0" onChange={(e) => setLifeExpectancy(e.target.value)} value={lifeExpectancy} />
                <span>&nbsp;Y</span>
              </div>
            </div>
            <Box >
              <Slider
                aria-label="Volume"
                value={lifeExpectancy}
                onChange={handleChange5}
                max={110}
              />
            </Box>
          </div>

          <div className="goalslider">
            <div className="goalinfo-title-text">
              <h1>Current Investment </h1>
              <div className="goalinfo-title-text-sub">
                <input type="number" placeholder="0" onChange={(e) => setCurrentInvestment(e.target.value)} value={currentInvestment} />
                <span>&nbsp;₹</span>
              </div>
            </div>
            <Box >
              <Slider
                aria-label="Volume"
                value={currentInvestment}
                onChange={handleChange6}
                max={500000}
              />
            </Box>
          </div>

          <div className="goalslider">
            <div className="goalinfo-title-text">
              <h1>Rate of return  </h1>
              <div className="goalinfo-title-text-sub">
                <input type="number" placeholder="0" onChange={(e) => setReturnRate(e.target.value)} value={returnRate} />
                <span>&nbsp;%</span>
              </div>
            </div>
            <Box >
              <Slider
                aria-label="Volume"
                value={returnRate}
                onChange={handleChange7}
                max={50}
              />
            </Box>
          </div>


        </div>
        <div className="marriageinfoshedaa">
          <div className="marriageinfo">
            <div className="marriageinfo-title">
              <h1 >
                Annual Income Required Immediately After Retirement
              </h1>
              <h2>&#8377;  {format(requiredAmount)} </h2>
            </div>
            <div className="marriageinfo-title">
              <h1 >
                Appreciation of investment made today
              </h1>
              <h2>&#8377;  {format(todayInvestment)} </h2>
            </div>

            {/* <div className="marriageinfo-title">
              <h1 >
                Deficit Corpus
              </h1>
              <h2>&#8377;  {format(monthlyInvestment)} </h2>
            </div>

            <div className="marriageinfo-title">
              <h1 >
                Lumpsum funding required
              </h1>
              <h2>&#8377;  {format(monthlyInvestment)} </h2>
            </div>

            <div className="marriageinfo-title">
              <h1 >
                Monthly investment required
              </h1>
              <h2>&#8377;  {format(monthlyInvestment)} </h2>
            </div> */}
          </div>
        </div>
        <div className="profile-button-row1">

          <button className="hedfa" variant="contained" style={{ margin: "20px", padding: "20 40" }} onClick={() => setPage(page - 1)} startIcon={
            <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>}>Previous</button>
          <button className="jkrd" variant="outlined" style={{ margin: "20px", padding: "20 40" }} onClick={() => setPage(page + 1)}>SKIP</button>
          <button className="hedfa" variant="contained" style={{ margin: "20px", padding: "20 40" }} onClick={handleSubmit} endIcon={
            <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>}>NEXT</button>
        </div>
      </div>
    </>
  );
};

export default Retirement;
