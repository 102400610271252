import React from "react";

import Accordion from "./Accordion";

function FAQ() {
  return (
    <div className="App12652">
      <h1>Frequently Asked Questions</h1>
      <Accordion
        title="What are the key documents required when applying for any loan?"
        content="Though the documentation requirements many vary from loan to loan and from one financial institution to another, some of the key documents that you would need to provide with your loan application include:

        • Income proof (Salary Slip for salaried/recent acknowledged ITR for self-employed)
        • Address Proof Documents (Aadhar Card)
        • Identity Proof Documents (PAN Card) and others
        • Certified copies of degree/license (in case of self-employed professional) and others as per the lender’s criteria."
      />
      <Accordion
        title="What type of loans does Stocktick provide?"
        content="Stocktick, a brand of Waymark Enterprises Private Ltd. offers all types of loans from banks and NBFCs – home loans, vehicle loans, business loan, education loan, personal loan, unsecured funding."
      />
      <Accordion
        title="What is an EMI?"
        content="As a borrower, you repay the loan in Equated Monthly Instalments (EMIs) which comprises of both, the principal amount and the interest. Repayment by way of EMI starts from the month following the month in which you take full disbursement. "
      />
      <Accordion
        title="How does the tenure affect cost of loan?"
        content="The longer the tenure of the loan, the lesser will be your monthly EMI outflow. Shorter tenures mean greater EMI burden, but your loan is repaid faster. If you have a short-term cash flow mismatch, your bank may increase the tenure of the loan, and your EMI burden comes down. But longer tenures mean payment of larger interest towards the loan and make it more expensive."
      />
      <Accordion
        title="What is the first step when applying for any loan?"
        content="The first step is to gather everything that is needed to apply for a loan. 
        Documents such as tax returns, bank statements, financial statements, legal documents.
        An idea of which items you might put up as collateral, if required.
        And if you are clueless and seek advice, book a free call with us here."
      />
      <Accordion
        title="How do I apply for a loan?"
        content="You can avail any loan online by Booking a Free call with us here. 
        Your loan process will take in 2 quick and easy steps:
        
        1. Book a Free Call and 
        2. Our advisor will get in touch with you"
      />
      <Accordion
        title="Do you need a perfect credit score to get a loan?"
        content="No. A low credit score is a concern for some lenders but banks aren’t the only lenders. We have network of alternative, NBFCs and private lenders that are often able to offer more flexible terms, including the level of creditworthiness they can approve."
      />
      <Accordion
        title="What type of loans do not require collateral?"
        content="Personal loans, unsecured funding loans are collateral free loans. Even education loan comes without collateral. To know more about it – Book a Free Call with Us."
      />
    </div>
  );
}

export default FAQ;
