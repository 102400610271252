import React, {useState} from "react";
import { useEffect } from 'react';
import { Button, Stack } from "@mui/material";
import "./financial.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Chart from "react-apexcharts";
import familyImage from './family_image.png';
import URLise from "../../fileUploader";

function Family ({page, setPage}) {
    const [pic, setPic] = useState(null);
    const [file, setFile] = useState(null);

    useEffect( async () => {
        const token = localStorage.getItem("authToken")
        const url = process.env.REACT_APP_API_BASE_URL + "/finantialplanning/finnantial-Q7?authToken="+token        
        const image = await fetch(url)
        .then(res => res.json())
        .catch(err => {
            console.log(err);
        })
        if(image.img){
            setPic(image.img)
        }
    }, [])

    const onChangePicture = async (e) => {
        const fileURL = await URLise(e.target.files[0])
        console.log(fileURL)
        setFile(fileURL);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(file === null){
            setPage(page+1);
            return ;
        }
        const token = localStorage.getItem("authToken")
        const url = process.env.REACT_APP_API_BASE_URL + "/finantialplanning/finnantial-Q7?authToken="+token
        const response = await fetch(url, {method : "POST", headers : {"Content-Type" : "application/json"}, body:JSON.stringify({img:file})})
        .then(res => res.json())
        .catch(err => {
            console.log(err);
            alert("Something went wrong, please try again");
        })
        console.log(response);
        if(response.message){
            setPage(page+1);
        }
        else {
            alert("Something went wrong in adding the image, please try again")
        }
    }   

    return(
        <div className="profile-info-container">
            <div className="profile-head">WELCOME TO FINANCIAL PLANNING</div>
            <div className="profile-subheading">{"Know more about your family"}</div>
            <div style={{}}>Upload a nice picture of your family</div>
            <div  className="image-upload">
                <label htmlFor="file-input">
                    <img height={500} width={500} src={pic ? pic : familyImage}/>
                </label>
                <input id="file-input" type="file" style={{ visibility:"hidden",width:0,height:0}}
                 onChange={(e)=> {onChangePicture(e); setFile(e.target.files[0]); setPic(URL.createObjectURL(e.target.files[0]))}}/>
            </div>
            <div className="profile-button-row">
                 <button className="hedfa" variant="contained" style={{margin:"20px",padding:"20 40"}} onClick={() => setPage(page-2)} startIcon={
                    <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>}>Previous    </button>
                <button className="jkrd" variant="outlined" style={{margin:"20px",padding:"20 40"}} onClick={() => setPage(page+1)}>SKIP</button>
                <button className="hedfa" variant="contained" style={{margin:"20px",padding:"20 40"}} onClick={handleSubmit} endIcon={
                    <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>}>NEXT   </button>
            </div>
        </div>
    )
}

export default Family;