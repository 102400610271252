import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="sec branding">
          <img className="footer-logo" src="/images/icons/logo.png" alt="" />

          <div className="foot-address">
            A Venture Of Waymark Enterprises Pvt Ltd, B wing 105, Yogeshwar CHS, Near Shahasdrabude
            Hospital, Opposite Chintamani Hall, Old Panvel 410206.
          </div>

          <div className="social-icons">
            <a href="/">
              <img src="/images/icons/facebook.svg" />
            </a>
            <a href="/">
              <img src="/images/icons/instagram.svg" />
            </a>
            <a href="/">
              <img src="/images/icons/linkedln.svg" />
            </a>
          </div>
        </div>

        <div className="sec products">
          <div className="sec-heading">PRODUCTS</div>
          <ul>
            <li>
              <Link to="/mutualfunds">Mutual Funds</Link>
            </li>
            <li>
              <Link to="/insurance">Insurance</Link>
            </li>
            <li>
              <Link to="/loans">Loan</Link>
            </li>
            <li>
              <Link to="/education">Education</Link>
            </li>
          </ul>
        </div>

        <div className="sec company">
          <div className="sec-heading">COMPANY</div>
          <ul>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/blogs">Blog</Link>
            </li>
            <li>
              <Link to="/media">Media</Link>
            </li>

            <li>
              <Link to="/partner">Partners</Link>
            </li>
            <li>
              <Link to="/privacypolicy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/terms-of-use">Terms and Conditions</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>
        </div>

        <div className="sec quicklinks">
          <div className="sec-heading">QUICK LINKS</div>
          <ul>
            <li>
              <Link to="/mutualfunds/calculators">Calculators</Link>
            </li>
            <li >
              <a href="https://play.google.com/store/apps/details?id=in.stocktick.stocktick">
                <img src="/images/misc/play_store.png" className="anchor-img" />
              </a>
            </li>
            <li>
              <a href="/">
                <img src="/images/misc/app_store.png" className="anchor-img" />
              </a>

            </li>
          </ul>
          <div className="applinks">


          </div>
        </div>


        <div className="footer-content">
          <h6>DISCLAIMER</h6>
          <p>
            Stocktick Capital ( A Venture of Waymark Enterprises Pvt Ltd) is registered under AMFI (ARN-9992)Prudent
            Corporate Advisory Pvt Ltd and complied by all the rules of SEBI and other regulatory bodies of the Industry. <br />
            Stocktick Capital ( A Venture of Waymark Enterprises Pvt Ltd) make no warranties or representations, express or
            implied, on products offered through the platform. It accepts no liability for any damages or losses, however
            caused, in connection with the use of, or on the reliance of its product or related services. Unless otherwise
            specified, all returns, expense ratio, NAV, etc. are historical and for illustrative purposes only. Future will vary
            greatly and depends on personal and market circumstances. The information provided by our blog is educational
            only and is not investment or tax advice.<br />
            Mutual fund investments are subject to market risks. Please read all scheme related documents carefully before
            investing. Past performance of the schemes is neither an indicator nor a guarantee of future performance.
          </p>
          <div className="rights">2016-22 StockTick. All rights reserved</div>

        </div>
      </div>
    </div >
  );
}

export default Footer;
