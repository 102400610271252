/* eslint-disable react/prop-types */
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  styled,
  Typography
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import img from "../images/webinar.svg";
import "./webinar.css";

function youtube_parser(url) {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  var link = match && match[7].length == 11 ? match[7] : false;
  console.log(link);
  if (link) {
    return `https://img.youtube.com/vi/${link}/0.jpg`;
  } else {
    return "";
  }
}

function LearnCard({ long_desc, short_desc, image_url, video_link, blog_link }) {
  return (
    <Card className="stocktick-card1">
      <CardMedia
        className="Learn"
        component="img"
        height="180"
        image={image_url ? image_url : youtube_parser(video_link)}
        alt="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {short_desc}
        </Typography>
        <Typography variant="body2" color="text.secondary" style={{ height: "100px" }}>
          {long_desc}
        </Typography>
        <a
          className="hrf-btn"
          href={video_link ? video_link : blog_link}
          style={{ textDecoration: "none" }}>
          <Button size="large">Read More</Button>{" "}
        </a>
      </CardContent>
    </Card>
  );
}

function Learn() {
  const [webinars, setWebinars] = useState([]);
  useEffect(() => {
    const sendRequest = async () => {
      const data = await axios.get(process.env.REACT_APP_API_BASE_URL + "/get/education/");
      console.log(data);
      return data;
    };
    sendRequest().then((data) => setWebinars(data.data));
  }, []);
  return (
    <div className="learn-parent">
      <h1>Knowledge Centre</h1>
      <div className="learnnn-more">{webinars.map((data) => LearnCard(data))}</div>
    </div>
  );
}

export default Learn;
