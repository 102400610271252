import React, { useState, useEffect } from "react";
import axios from "axios";
import Headline from "../../Headline/Headline";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";

function PMS() {
  // const [editBankName, setEditBankName] = useState(false);
  // const [editBranchAddress, setEditBranchAddress] = useState(false);
  // const [editAccountHolderName, setEditAccountHolderName] = useState(false);
  // const [editAccountNumber, setEditAccountNumber] = useState(false);
  // const [editAccountType, setEditAccountType] = useState(false);
  // const [editNomineeName, setEditNomineeName] = useState(false);
  // const [editRelationship, setEditRelationship] = useState(false);
  // const [editAllocationPercent, setEditAllocationPercent] = useState(false);
  // const [editNomineeName2, setEditNomineeName2] = useState(false);
  // const [editRelationship2, setEditRelationship2] = useState(false);
  // const [editAllocationPercent2, setEditAllocationPercent2] = useState(false);

  const [multiInput, setMultiInput] = useState([
    {
      id: "",
      amc_name: "",
      pms_name: "",
      invested_value: "",
      current_amount: "",
      account_number: "",
      invested_date: "",
      return_abs: "",
      return_cagr: "",
      nominee1: "",
      relationship1: "",
      allocation1: "",
      nominee2: "",
      relationship2: "",
      allocation2: ""
    }
  ]);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const inputList = [...multiInput];
    inputList[index][name] = value;
    setMultiInput(inputList);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (e, index) => {
    e.preventDefault();

    const id = multiInput[index].id;

    if (!id) {
      const inputList = [...multiInput];
      inputList.splice(index, 1);
      setMultiInput(inputList);
    } else {
      const authToken = localStorage.getItem("authToken");
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL +
            `/asset-recorder/data-delete/?authToken=${authToken}&deleteId=${id}`
        )
        .then(() => {
          const inputList = [...multiInput];
          inputList.splice(index, 1);
          setMultiInput(inputList);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // handle click event of the Add button
  const handleAddClick = (e) => {
    e.preventDefault();
    setMultiInput([
      ...multiInput,
      {
        id: "",
        amc_name: "",
        pms_name: "",
        invested_value: "",
        current_amount: "",
        account_number: "",
        invested_date: "",
        return_abs: "",
        return_cagr: "",
        nominee1: "",
        relationship1: "",
        allocation1: "",
        nominee2: "",
        relationship2: "",
        allocation2: ""
      }
    ]);
  };

  function handleSubmit(e, index) {
    e.preventDefault();
    const data = multiInput[index];
    console.log(data);

    const authToken = localStorage.getItem("authToken");
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL +
          `/asset-recorder/data/?authToken=${authToken}&asset=pms&editId=${data.id}`,
        data
      )
      .then((res) => {
        console.log("ser1", res.data.ser1);
        const inputList = [...multiInput];
        inputList[index].id = res.data.ser1.id;
        setMultiInput(inputList);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(async () => {
    const authToken = localStorage.getItem("authToken");
    await axios
      .get(
        process.env.REACT_APP_API_BASE_URL +
          `/asset-recorder/data/?authToken=${authToken}&asset=pms`
      )
      .then((res) => {
        console.log("FD : Data fetched");
        console.log(res.data);
        if (res && res.data && res.data[0]) {
          var newData = res.data.map((d) => {
            const newD = {
              id: d.id || "",
              amc_name: d.amc_name || "",
              pms_name: d.pms_name || "",
              invested_value: d.invested_value || "",
              current_amount: d.current_amount || "",
              account_number: d.account_number || "",
              invested_date: d.invested_date || "",
              return_abs: d.return_abs || "",
              return_cagr: d.return_cagr || "",
              nominee1: d.nominee1 || "",
              relationship1: d.relationship1 || "",
              allocation1: d.allocation1 || "",
              nominee2: d.nominee2 || "",
              relationship2: d.relationship2 || "",
              allocation2: d.allocation2 || ""
            };
            return newD;
          });
          console.log(newData);
          setMultiInput(newData);
        }
      })
      .catch(() => console.log("error occurred"));
  }, []);

  return (
    <>
      <Headline />
      <Navbar />
      <div className="parent-key">
        <div className="parent-key-card">
          <h1>
            {" "}
            PMS
            <button className="add-card-btn" onClick={handleAddClick}>
              Add
            </button>
          </h1>
          <div className="parent-card-true">
            {multiInput.map((x, i) => {
              return (
                <div className="parent-card-true1" key={i}>
                  <div className="div-flex">
                    <div className="div-flex-one">
                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">AMC Name</label>
                        <input
                          type="text"
                          className="input-text"
                          name="amc_name"
                          value={multiInput[i].amc_name}
                          // readOnly={!editBankName}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditBankName(!editBankName);
                    }}>
                    {editBankName ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">PMS Name</label>
                        <input
                          type="text"
                          name="pms_name"
                          className="input-text"
                          value={multiInput[i].pms_name}
                          // readOnly={!editBranchAddress}
                          onChange={(e) => handleInputChange(e, i)}
                        />

                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditBranchAddress(!editBranchAddress);
                    }}>
                    {editBranchAddress ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Invested Value </label>
                        <input
                          type="text"
                          className="input-text"
                          name="invested_value"
                          value={multiInput[i].invested_value}
                          // readOnly={!editAccountNumber}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAccountNumber(!editAccountNumber);
                    }}>
                    {editAccountNumber ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Current Amount </label>
                        <input
                          type="text"
                          className="input-text"
                          name="current_amount"
                          value={multiInput[i].current_amount}
                          // readOnly={!editAccountNumber}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAccountNumber(!editAccountNumber);
                    }}>
                    {editAccountNumber ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Account Number </label>
                        <input
                          type="number"
                          className="input-text"
                          name="account_number"
                          value={multiInput[i].account_number}
                          // readOnly={!editAccountHolderName}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAccountHolderName(!editAccountHolderName);
                    }}>
                    {editAccountHolderName ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Invested Date</label>
                        <input
                          type="date"
                          className="input-text"
                          name="invested_date"
                          value={multiInput[i].invested_date}
                          // readOnly={!editNomineeName}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditNomineeName(!editNomineeName);
                    }}>
                    {editNomineeName ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Return Absolute </label>
                        <input
                          type="number"
                          className="input-text input-percent"
                          name="return_abs"
                          value={multiInput[i].return_abs}
                          // readOnly={!editRelationship2}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        <span className="input-postfix">%</span>
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditRelationship2(!editRelationship2);
                    }}>
                    {editRelationship2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>
                    </div>
                    <div className="div-flex-two">

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Return CAGR </label>
                        <input
                          type="number"
                          className="input-text input-percent"
                          name="return_cagr"
                          value={multiInput[i].return_cagr}
                          // readOnly={!editRelationship2}
                          onChange={(e) => handleInputChange(e, i)}
                        />

                        <span className="input-postfix">%</span>
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditRelationship2(!editRelationship2);
                    }}>
                    {editRelationship2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>
                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Nominee Name I </label>
                        <input
                          type="text"
                          className="input-text"
                          name="nominee1"
                          value={multiInput[i].nominee1}
                          // readOnly={!editAllocationPercent2}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAllocationPercent2(!editAllocationPercent2);
                    }}>
                    {editAllocationPercent2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Relationship I</label>
                        <input
                          type="text"
                          className="input-text"
                          name="relationship1"
                          value={multiInput[i].relationship1}
                          // readOnly={!editAllocationPercent2}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAllocationPercent2(!editAllocationPercent2);
                    }}>
                    {editAllocationPercent2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Allocation Percent I </label>
                        <input
                          type="text"
                          className="input-text input-percent"
                          name="allocation1"
                          value={multiInput[i].allocation1}
                          // readOnly={!editAllocationPercent2}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        <span className="input-postfix">%</span>
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAllocationPercent2(!editAllocationPercent2);
                    }}>
                    {editAllocationPercent2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Nominee Name II</label>
                        <input
                          type="text"
                          className="input-text"
                          name="nominee2"
                          value={multiInput[i].nominee2}
                          // readOnly={!editAllocationPercent2}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAllocationPercent2(!editAllocationPercent2);
                    }}>
                    {editAllocationPercent2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Relationship II</label>
                        <input
                          type="text"
                          className="input-text"
                          name="relationship2"
                          value={multiInput[i].relationship2}
                          // readOnly={!editAllocationPercent2}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAllocationPercent2(!editAllocationPercent2);
                    }}>
                    {editAllocationPercent2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>

                      <div className="input-entry1">
                        <label className="label-cust-edit-profile">Allocation Percent2</label>
                        <input
                          type="text"
                          className="input-text input-percent"
                          name="allocation2"
                          value={multiInput[i].allocation2}
                          // readOnly={!editAllocationPercent2}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        <span className="input-postfix">%</span>
                        {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAllocationPercent2(!editAllocationPercent2);
                    }}>
                    {editAllocationPercent2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="div-flex">
                    <button className="div-form-button" onClick={(e) => handleSubmit(e, i)}>
                      {" "}
                      save{" "}
                    </button>
                  </div>

                  {i >= 0 && (
                    <button className="delete-card-btn" onClick={(e) => handleRemoveClick(e, i)}>
                      <img src="/images/icons/delete.svg" alt="" /> Delete
                    </button>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PMS;
