import React, { useState, useEffect } from "react";
import Footer from "../components/Footer/Footer";
import Poster from "../components/Mutual_Funds/Poster";
import MiddleMutualFunds from "../components/Mutual_Funds/MiddleMutualFunds";
import ExploreInsurance from "../components/Mutual_Funds/ExploreMutualFunds";
import DiscoverMutualFunds from "../components/Mutual_Funds/DiscoverMutualFunds";
import MFFAQ from "../components/Mutual_Funds/FAQ";
import OurServices from "../components/MiddleLanding/OurServices";
import QrBox from "../components/MiddleLanding/QrBox";
import NewsLetter from "../components/MiddleLanding/NewsLetter";
import Headline from "../components/Headline/Headline";
import Navbar from "../components/Navbar/Navbar";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import Car from "../components/Insurance/CarInsurance/Car";
import Life from "../components/Insurance/LifeInsurance/Life";
import Health from "../components/Insurance/HealthInsurance/Health";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import Design from "../components/Mutual_Funds/Design";

function MutualFunds() {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("1");
  const handleClick = (e) => {
    setOpen(true);
    console.log(open);
    console.log(e.target.value);
    setType(e.target.value);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_API_BASE_URL + "discover-mutual-funds/catg"
      );
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <div style={{ background: "white" }}>
      {open && type == "1" && <Car open={open} handleClose={handleClose} />}
      {open && type == "2" && <Life open={open} handleClose={handleClose} />}
      {open && type == "3" && <Health open={open} handleClose={handleClose} />}
      <ScrollToTop />
      <Headline />
      <Navbar />
      <Poster />
      <MiddleMutualFunds />
      <ExploreInsurance handleClick={handleClick} />
      <DiscoverMutualFunds />
      <Design />
      <OurServices />
      <MFFAQ />
      <QrBox />
      <NewsLetter />
      <Footer />
    </div>
  );
}

export default MutualFunds;
