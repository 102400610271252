import React from "react";
import { useEffect, useState, useRef } from 'react';
import { Button, Stack } from "@mui/material";
import "./financial.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Chart from "react-apexcharts";
import minusCircle from "./minus-circle.png";
import { ConstructionOutlined } from "@mui/icons-material";

function FamilyFinancialGoals ({page, setPage}) {
    
    // {task: "", priority: ""}
    const [goals, setGoals] = useState([]);
    const [editGoal, setEditGoal] = useState({task: null, priority: null});
    const [goalRemove, setGoalRemove] = useState(goals);
    const ref = useRef();

    async function handleSubmit(e){
        const token = localStorage.getItem("authToken")
        const url = process.env.REACT_APP_API_BASE_URL + "/finantialplanning/finnantial-Q5?authToken="+token
        const finalGoals = goals.map(item => {
            return {
                goal : item.task,
                priority : item.priority
            }
        })
        await fetch(url, {
            method : "POST",
            headers : {
                // "authToken" : token,
                "Content-Type" : "application/json"
            },
            body  : JSON.stringify({data : finalGoals}) 
        })
        .then((res)=> res.json())
        .then(data => {
            console.log("response from adding data ", data)
            if(data.message){
                setPage(page+1);
            }
            else {
                alert("Something went wrong while submitting your data, please try again")
            }
        })
        .catch( err => {
            console.log(err)
            alert("Something went wrong, please check your internet connection and try again")
        })
        e.preventDefault();
    }

    const handleRemove = (ind) => {
        goalRemove.splice(ind, 1);
        setGoals(goalRemove);
        setEditGoal({task: null, priority: null});
    } 

    const handleEdit = (priority, index , goal) => {
        goalRemove[index] = {task: goal, priority: priority};
        console.log(goalRemove);
        setGoals(goalRemove);
        setEditGoal({task: null, priority: null});
    }

    useEffect(()=>{
        setGoalRemove(goals);
    },[goals])

    useEffect( async () => {
        const token = localStorage.getItem("authToken");
        console.log("token", token)
        const url = process.env.REACT_APP_API_BASE_URL + "/finantialplanning/finnantial-Q5?authToken=" + token
        await fetch(url, {
            method : "GET",
            // headers : {
            //     "authToken" : token
            // }
        })
        .then(res => res.json())
        .then(async (data) => {
            console.log(data)
            let finalGoals = [];    
            if(data.length){
                data.map((item, index) => {
                    finalGoals.push({
                        task : item.goal,
                        priority : item.priority
                    })
                })
            }
            console.log(finalGoals)
            setGoals(finalGoals);
        })
        .catch(err => {
            console.log(err)
        })
    }, [])

    return(
        <div className="profile-info-container">
            <div className="profile-head">WELCOME TO FINANCIAL PLANNING</div>
            <div className="profile-subheading">{"Family's Vision of Financial Welbeing"}</div>
            <div style={{margin:"10px"}}>Family Vision for Financial Freedom</div>
            {goals.map((goal, index)=>
                <Stack className="abcde123" key ={index} direction ="row" justifyContent="space-around" alignItems="space-around" style={{margin:"-10px"}}>
                    <img src={minusCircle} style={{height: "20px", alignSelf:"center", margin:"10px"}} onClick={()=>handleRemove(index)}></img>
                    <input
                        type="text"
                        placeholder="Enter goal"
                        value={goal.task}
                        style={{height: "30px", alignSelf:"center"}}
                        onChange={(e) => {
                            handleEdit(goal.priority, index, e.target.value);
                        }}></input>
                    <select value={goal.priority}
                        onChange={(e) => {handleEdit(e.target.value,index,goal.task);
                        }} style={{margin:"20px"}}>
                        <option value={null} disabled selected> + Add </option>
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                    </select>
                </Stack>
            )}
            <Stack className="abcde123" direction = "row" justifyContent="space-around" alignItems="space-around" style={{marginLeft: "38px"}}>
                <input
                    type="text"
                    placeholder="Enter goal"
                    defaultValue={null}
                    value={editGoal.task}
                    style={{height: "30px", alignSelf:"center"}}
                    onChange={(e) => {
                        setEditGoal({task:e.target.value, priority: editGoal.priority});
                    }}></input>
                <select value={editGoal.priority} defaultValue={null}
                    onChange={(e) => {console.log(e.target.value);
                        let tsk = editGoal.task;
                        console.log([...goals, {task: tsk, priority: e.target.value}]);
                        setEditGoal({task: null, priority: null});
                        setGoals([...goals, {task: tsk, priority: e.target.value}]);
                        setEditGoal({task: null, priority: null});
                    }} style={{margin:"20px"}}>
                    <option value={null} disabled selected> + Add </option>
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="high">High</option>
                </select>
            </Stack>
            <div className="profile-button-row">
                <button className="hedfa" variant="contained" style={{margin:"20px",padding:"20 40"}} onClick={() => setPage(page-1)} startIcon={
                    <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>}>Previous   </button>
                <button className="jkrd" variant="outlined" style={{margin:"20px",padding:"20 40"}} onClick={() => setPage(page+1)}>SKIP</button>
                <button className="hedfa" variant="contained" style={{margin:"20px",padding:"20 40"}} onClick={handleSubmit} endIcon={
                    <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>}>NEXT   </button>
            </div>
        </div>
    )
}

export default FamilyFinancialGoals;