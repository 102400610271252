import { styled, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { LoginModal } from "../../pages/Login";
import "./explore.css";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center"
  },
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "20px"
}));

function Explore({ openModal, handleClick }) {
  const [res, setRes] = useState([]);
  const [login, setLogin] = useState(false);
  function handleOpen() {
    openModal();
  }
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_BASE_URL + "/get/loans?platform=W")
      .then((res) => {
        setRes(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  console.log(res);
  return (
    <RootStyle>
      <div className="middle-heading-loan">For Any Need, Apply Now</div>
      <div className="parent-loan-explore-card">
        {res &&
          res.map((info) => {
            return (
              <>
                <div className="loan-explore-card" onClick={handleOpen}>
                  <div className="loan-card-image">
                    <img src={info.image_url} alt="" />
                  </div>
                  <div
                    className="card-category"
                    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {info.category}
                  </div>
                  {/* <div className="card-interest">{info.interest}</div> */}
                  <div
                    className="card-description"
                    style={{
                      fontSize: "16px",
                      // paddingLeft: "7%",
                      // paddingRight: "7%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}>
                    {info.short_desc}
                  </div>
                </div>
              </>
            );
          })}
      </div>
    </RootStyle>
  );
}

export default Explore;
