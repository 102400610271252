import { Button, Grid, styled } from "@mui/material";
import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import insurance from "../images/insurance12.svg";
import axios from "axios";
import "./ins.css";
import GetAssistanceForm from "../Slider/GetAssistanceForm";

function Poster() {
  const [assistance, setAssistance] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    query: ""
  });

  const handleAssistance = async () => {
    const data = await axios
      .post("https://api.stocktick.in/getassistance/", form, {
        headers: {
          Connection: "keep-alive",
          authToken: localStorage.getItem("authToken"),
          accepts: "application/json"
        }
      })
      .then(() => {
        setForm({
          name: "",
          email: "",
          phone: "",
          query: ""
        });
        setAssistance(false);
      });
    console.log(data);
  };

  return (
    <>
      <div className="insurance-poster">
        <div className="insurance-poster-left">
          <div className="insurance-mobile">
            <img src={insurance} alt="" />
          </div>

          <h1>
            Simple. Reliable. Direct<span> INSURANCE, </span> the way it should be
          </h1>

          <p>
            With our integrated wealth preservation, insurance and wealth protection strategies,
            secure your financial future and get shielded from all future uncertainties!
          </p>

          <button className="apply-now-btn123"><a href="https://calendly.com/vijaymaheshwari/financialfreedom"
            target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#fff' }}>Book A Free Call</a>  </button>
        </div>

        <div className="insurance-poster-right">
          <img src={insurance} alt="" />
        </div>

        <div className="head-div-1">
          <button className="heda123" onClick={() => setAssistance(true)}>
            {" "}
            Get Assistance!
          </button>
        </div>
        <GetAssistanceForm
          open={assistance}
          handleClose={() => {
            setAssistance(false);
          }}
          handleSubmit={handleAssistance}
          form={form}
          setForm={setForm}
        />
      </div>
    </>
  );
}

export default Poster;
