import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import axios from "axios";

function DModal(props) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [query, setQuery] = useState("");

    const submitForm = async () => {
        console.log("Submit form called");
        const body = {
            name: name,
            email: email,
            phone: phone,
            query: query
        }
        const formResp = await axios.post(process.env.REACT_APP_API_BASE_URL + "/discover-mutual-funds/mf-queries/", body)
        console.log(formResp)
        if (formResp.status >= 200 && formResp.status <300)  {
            alert("Message has been sent succesfully. Team will get back to you shortly.")
        } else {
            alert("Sending message failed. Please retry again!")
        }
    }

    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Write Your Dream
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="contact-form212">
                <input type="text" placeholder="Your Name *" value={name} onChange={(e) => { setName(e.target.value) }} />
                <input type="email" placeholder="Email *" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                <input type="tel" placeholder="Phone No. *" value={phone} onChange={(e) => { setPhone(e.target.value) }} />
                <textarea name="" id="" cols="10" rows="5" placeholder="Say Something... " value={query} onChange={(e) => { setQuery(e.target.value) }}></textarea>
            </div>
        </Modal.Body>
            <Modal.Footer>
                <div style={{ "width": "100%", "display" : "flex", "justifyContent": "space-between"}}>
                    <div >
                            Our Advisor will connect you Shortly
                    </div>
                    <div >
                        <Button onClick={submitForm}>Submit</Button>
                    </div>
                </div>
        </Modal.Footer>
        </Modal>
    );
}


function DreamBoxModal() {
    const [modalShow, setModalShow] = useState(false);
    return (
        <>
            <button
                onClick={() => setModalShow(true)}
                className="explorebutton"
            >
                Explore More
            </button>
            <DModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default DreamBoxModal;
