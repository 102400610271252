/* eslint-disable react/prop-types */
import { AccountCircle } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Button, Dialog, InputAdornment, styled, TextField } from "@mui/material";
import React from "react";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "90%",
    height: "auto",
    alignItems: "center",
    marginBottom: "50px",
    minWidth: "0px"
  },
  display: "flex",
  width: "100%",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
  height: "90vh",
  marginBottom: "50px",
  backgroundColor: "white",
  minWidth: "500px"
}));

const ButtonStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "90%",
    alignItems: "center",
    marginBottom: "50px"
  },
  display: "flex",
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  marginBottom: "50px",
  backgroundColor: "white"
}));

const RowStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "90%",
    alignItems: "center",
    marginBottom: "30px",
    marginLeft: "10px"
  },
  display: "flex",
  width: "70%",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "10px"
}));

function CarStepTwo({
  open,
  handleClose,
  handleNext,
  handlePrev,
  manufac,
  model,
  ftype,
  variant,
  mandate,
  handleManufac,
  handleModel,
  handleFtype,
  handleVariant,
  handleManDate
}) {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"500px"}>
      <RootStyle>
        <div style={{ flex: "0.1", marginTop: '20px'  }}>
          <h1 style={{ color: "#45A69F" }}>CAR INSURANCE</h1>
        </div>
        <RowStyle>
          <TextField
            id="input-with-icon-textfield"
            label="Manufacturer"
            value={manufac}
            onChange={handleManufac}
            InputLabelProps={{
              style: { color: "#45A69F", fontSize: "20px", fontWeight: "600", marginLeft: '2px'  }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <AccountCircle /> */}
                </InputAdornment>
              )
            }}
            variant="standard"
            style={{ margin: "15px" }}
          />
        </RowStyle>
        <RowStyle>
          <TextField
            id="input-with-icon-textfield"
            label="Model"
            value={model}
            onChange={handleModel}
            InputLabelProps={{
              style: { color: "#45A69F", fontSize: "20px", fontWeight: "600", marginLeft: '2px'  }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <AccountCircle /> */}
                </InputAdornment>
              )
            }}
            variant="standard"
            style={{ margin: "15px" }}
          />
        </RowStyle>
        <RowStyle>
          <TextField
            id="input-with-icon-textfield"
            label="Fuel Type"
            value={ftype}
            onChange={handleFtype}
            InputLabelProps={{
              style: { color: "#45A69F", fontSize: "20px", fontWeight: "600", marginLeft: '2px'  }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <AccountCircle /> */}
                </InputAdornment>
              )
            }}
            variant="standard"
            style={{ margin: "15px" }}
          />
        </RowStyle>
        <RowStyle>
          <TextField
            id="input-with-icon-textfield"
            label="Variant"
            value={variant}
            onChange={handleVariant}
            InputLabelProps={{
              style: { color: "#45A69F", fontSize: "20px", fontWeight: "600", marginLeft: '2px'  }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <AccountCircle /> */}
                </InputAdornment>
              )
            }}
            variant="standard"
            style={{ margin: "15px" }}
          />
        </RowStyle>
        <RowStyle>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="MFG date"
              value={mandate}
              onChange={handleManDate}
              style={{ border: "none" }}
              //   onChange={handleDOB}
              renderInput={(params) => (
                <TextField
                  id="icon-textfield"
                  variant="standard"
                  {...params}
                  InputLabelProps={{
                    style: { color: "#45A69F", fontSize: "20px", fontWeight: "600", marginLeft: '2px'  }
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </RowStyle>
        <ButtonStyle>
          <Button
            sx={{ background: "#45A69F", color: "white", width: "200px", margin: "10px", "&:hover": {backgroundColor: "#45A69F", }  }}
            onClick={handlePrev}>
            Prev <img src="/images/icons/login.png" />{" "}
          </Button>
          <Button
            sx={{ background: "#45A69F", color: "white", width: "200px", margin: "10px" , "&:hover": {backgroundColor: "#45A69F", } }}
            onClick={handleNext}>
            Next <img src="/images/icons/login.png" />{" "}
          </Button>
        </ButtonStyle>
      </RootStyle>
    </Dialog>
  );
}

export default CarStepTwo;
