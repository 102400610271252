import {
  InputAdornment,
  styled,
  TextField,
  Typography,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
  // FormLabel
} from "@mui/material";
import React, { useState } from "react";
import { SearchOffOutlined } from "@mui/icons-material";
import ScrollToTop from "../ScrollToTop/ScrollToTop";

const Heading = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}));

const Info = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}));

const Filter = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  display: "flex",
  flexDirection: "column",
  flex: 0.3,
  justifyContent: "center",
  alignItems: "start"
}));

const FilterHeader = styled("div")(() => ({
  display: "flex",
  position: "relative",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center"
}));

const FilterContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  display: "flex",
  width: "100%",
  position: "relative",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center"
}));

const FilterOptions = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  width: "90%",
  position: "relative",
  display: "flex",
  borderStyle: "solid",
  borderColor: "cyan",
  padding: "2px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center"
}));

const Insurance = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  display: "flex",
  flex: 0.6,
  justifyContent: "center",
  alignItems: "center"
}));

function InsuranceCard() {
  const [value, setValue] = useState("female");
  const handleChange = () => {
    setValue("male");
  };
  return (
    <React.Fragment>
      <ScrollToTop />
      <Heading>
        <Typography variant="h4" margin="50px">
          Explore Insurance
        </Typography>
        <TextField
          id="input-with-icon-textfield"
          label="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOffOutlined />
              </InputAdornment>
            )
          }}
          variant="standard"
        />
      </Heading>
      <Info>
        <Box minWidth={"250px"}>
          <Filter>
            <FilterHeader>
              <Typography variant="h5">Filters</Typography>
              <Button color="primary" size="large">
                Clear All!
              </Button>
            </FilterHeader>
            <FilterContainer>
              <Typography>Category</Typography>
              <FilterOptions>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={value}
                    onChange={handleChange}>
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                    <FormControlLabel value="other" control={<Radio />} label="Other" />
                  </RadioGroup>
                </FormControl>
              </FilterOptions>
            </FilterContainer>
          </Filter>
        </Box>
        <Insurance>boys</Insurance>
      </Info>
    </React.Fragment>
  );
}

export default InsuranceCard;
