import React from "react";
import Footer from "../components/Footer/Footer";

import Headline from "../components/Headline/Headline";
import Navbar from "../components/Navbar/Navbar";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import "./css/privacypolicy.css";

function TermsOfUse() {
  const particlesInit = async (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  return (
    <div className="privacy-policy">
      <ScrollToTop />
      <Headline />
      <Navbar />

      <div className="privacy-main">
        <div className="privacy-sidebar">
          <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
              fullScreen: {
                enable: false,
                zIndex: 0
              },
              particles: {
                number: {
                  value: 50,
                  density: {
                    enable: true,
                    value_area: 2000
                  }
                },
                color: {
                  value: "#fff"
                },
                shape: {
                  type: "circle"
                },
                opacity: {
                  value: 0.5,
                  random: true,
                  anim: {
                    enable: false,
                    speed: 1,
                    opacity_min: 0.1,
                    sync: false
                  }
                },
                size: {
                  value: 18,
                  random: true,
                  anim: {
                    enable: false,
                    speed: 40,
                    size_min: 0.1,
                    sync: false
                  }
                },
                line_linked: {
                  enable: false,
                  distance: 500,
                  color: "#ffffff",
                  opacity: 0.4,
                  width: 2
                },
                move: {
                  enable: true,
                  speed: 2,
                  direction: "top-right",
                  random: false,
                  straight: false,
                  out_mode: "out",
                  attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200
                  }
                }
              },
              interactivity: {
                events: {
                  onhover: {
                    enable: true,
                    mode: "bubble"
                  },
                  onclick: {
                    enable: true,
                    mode: "repulse"
                  },
                  resize: true
                },
                modes: {
                  grab: {
                    distance: 400,
                    line_linked: {
                      opacity: 0.5
                    }
                  },
                  bubble: {
                    distance: 400,
                    size: 4,
                    duration: 0.3,
                    opacity: 1,
                    speed: 3
                  },
                  repulse: {
                    distance: 200
                  },
                  push: {
                    particles_nb: 4
                  },
                  remove: {
                    particles_nb: 2
                  }
                }
              },
              retina_detect: true,
              background: {
                color: "#2e3092",
                image: "",
                position: "50% 50%",
                repeat: "no-repeat",
                size: "cover"
              }
            }}
          />
          <h1> Terms and Conditions </h1>
        </div>
        <div className="privacy-policy1">
          <div className="hding212">Terms and Conditions</div>
          <div className="ppp212">
            Welcome to Stocktick! These terms and conditions outline the rules and regulations for
            the use of waymark enterprises private limited Website, located at
            https://stocktick.in/. By accessing this website, we assume you accept these terms and
            conditions.
          </div>

          <div className="ppp2121">
            If you do not agree with the Terms and Conditions of this policy, please do not proceed
            or continue further to use this website.
          </div>

          <div className="hgtr212">
            <div className="hdddder212">
              1. <span>Cookies:</span>
            </div>

            <div className="content212">
              {/* &quot;User&quot;, &quot;You/you&quot;, &quot;Your/your&quot; means any person or
              visitor or registered user who views, browses, accesses or uses the Site;
              &quot;Use&quot; or &quot;Using&quot; means to browse, access, view, copy, availing
              Services or other benefit from using the Site; &quot;Policy&quot; means this Privacy
              Policy. &quot;Services&quot; means all the services as provided in Terms & Conditions
              from time to time. &quot;Registered User&quot; shall mean and include the visitors who
              have Investment Account with the Site to avail the Services or any part thereof, as
              provided on the Site. */}
              The website uses cookies to help personalize your online experience. By accessing
              Stocktick, you agreed to use the required cookies. A cookie is a text file that is
              placed on your hard disk by a web page server. Cookies cannot be used to run programs
              or deliver viruses to your computer. Cookies are uniquely assigned to you and can only
              be read by a web server in the domain that issued the cookie to you. We may use
              cookies to collect, store, and track information for statistical or marketing purposes
              to operate our website. You have the ability to accept or decline optional Cookies.
              There are some required Cookies that are necessary for the operation of our website.
              These cookies do not require your consent as they always work. Please keep in mind
              that by accepting required Cookies, you also accept third-party Cookies, which might
              be used via third-party provided services if you use such services on our website, for
              example, a video display window provided by third parties and integrated into our
              website.
            </div>

            <div className="hdddder212">
              2. <span>License:</span>
            </div>

            <div className="content212">
              Unless otherwise stated, waymark enterprises private limited and/or its licensors own
              the intellectual property rights for all material on Stocktick. All intellectual
              property rights are reserved. You may access this from Stocktick for your own personal
              use subjected to restrictions set in these terms and conditions.
              <br />
              You must not:
              <br />
              <br />
              <li>Copy or republish material from Stocktick</li>
              <li>Sell, rent, or sub-license material from Stocktick</li>
              <li>Reproduce, duplicate or copy material from Stocktick</li>
              <li>Redistribute content from Stocktick</li>
              {/* <br />
              The first is information that we gather through aggregated tracking information
              derived while you are browsing this website. Anonymous information will be gathered in
              our computer system log files to allow us to better tailor our services to your needs,
              help us better understand the demographics of our audience and improve our products.
              <br />
              <br />
              The second is information that registered users provide through optional, voluntary
              submissions such as
              <br />
              <br />
              1. Name and Contact information, including email address, third-party account
              credentials etc.
              <br />
              <br />
              2. Personal information, including date of birth and Permanent Account Number (PAN)
              and other information required in accordance with the KYC norms and regulatory
              requirements.
              <br />
              <br />
              3. Demographic information such as gender and income.
              <br />
              <br />
              4. Other information that can help us improve our services
              <br />
              <br />
              We require this information
              <br />
              <br />
              1. To perform compliance checks and internal record keeping.
              <br />
              <br />
              2. To use the information to improve our products and services
              <br />
              <br />
              3. To periodically send emails to your registered email address about new products,
              special offers, or other information which we think you may find interesting.
              <br />
              <br />
              4. You will be free to unsubscribe your email address at any time, if you do not wish
              to receive such emails from us.
              <br />
              <br />
              5. From time to time, we may also use your information to contact you via phone or
              email for market research purposes.
              <br />
              <br />
              Also, we may receive a confirmation when you open an email from us. This confirmation
              helps us make our communications with you more interesting.
              <br />
              <br />
              We need physical evidence of your personal details like PAN, cancelled cheque leaf
              etc. to satisfy regulatory requirements while your bank details are required to honour
              your investment instructions.
              <br />
              <br />
              Oro shall not be responsible, in the event, any discrepancy is found, in any of the
              information provided by the user, such as, mismatch of the information provided, shall
              lead to the rejection for opening of the account.
              <br />
              <br />
              We may also ask for additional personal information from time to time to meet new
              regulatory requirements or to provide you with other benefits of our services. As
              always, the information that we seek will only be for the purpose stated.
              <br />
              <br />
              You agree that, all intellectual property including but not limited to copy right,
              trade mark, any personal information, content, photographs, images, experience
              sharing, feedback, opinions that is voluntarily shared by you with us or uploaded on
              the Site are non infringing of any rights of third parties in whatsoever manner and
              you give us unrestricted, perpetual right of use of your such intellectual property
              for any purpose whatsoever. */}
              <br />
              This Agreement shall begin on the 27-11-2022 hereof.
              <br />
              <br />
              Parts of this website offer users an opportunity to post and exchange opinions and
              information in certain areas of the website. waymark enterprises private limited does
              not filter, edit, publish or review Comments before their presence on the website.
              Comments do not reflect the views and opinions of waymark enterprises private limited,
              its agents, and/or affiliates. Comments reflect the views and opinions of the person
              who posts their views and opinions. To the extent permitted by applicable laws,
              waymark enterprises private limited shall not be liable for the Comments or any
              liability, damages, or expenses caused and/or suffered as a result of any use of
              and/or posting of and/or appearance of the Comments on this website.
              <br />
              <br />
              waymark enterprises private limited reserves the right to monitor all Comments and
              remove any Comments that can be considered inappropriate, offensive, or causes breach
              of these Terms and Conditions.
              <br />
              <br />
              You warrant and represent that:
              <li>
                You are entitled to post the Comments on our website and have all necessary licenses
                and consents to do so;
              </li>
              <li>
                The Comments do not invade any intellectual property right, including without
                limitation copyright, patent, or trademark of any third party;
              </li>
              <li>
                The Comments do not contain any defamatory, libelous, offensive, indecent, or
                otherwise unlawful material, which is an invasion of privacy.
              </li>
              <li>
                The Comments will not be used to solicit or promote business or custom or present
                commercial activities or unlawful activity.
              </li>
              <br />
              <br />
              You hereby grant waymark enterprises private limited a non-exclusive license to use,
              reproduce, edit and authorize others to use, reproduce and edit any of your Comments
              in any and all forms, formats, or media.
              <br />
              <br />
              <i>Hyperlinking to our Content:</i>
              <br />
              <br />
              The following organizations may link to our Website without prior written approval:
              <li>Government agencies;</li>
              <li>Search engines;</li>
              <li>News organizations;</li>
              <li>
                Online directory distributors may link to our Website in the same manner as they
                hyperlink to the Websites of other listed businesses; and
              </li>
              <li>
                System-wide Accredited Businesses except soliciting non-profit organizations,
                charity shopping malls, and charity fundraising groups which may not hyperlink to
                our Web site.
              </li>
              <br />
              <br />
              These organizations may link to our home page, to publications, or to other Website
              information so long as the link: <li>is not in any way deceptive;</li>{" "}
              <li>
                does not falsely imply sponsorship, endorsement, or approval of the linking party
                and its products and/or services;{" "}
              </li>{" "}
              <li>fits within the context of the linking party site.</li>
              <br />
              We may consider and approve other link requests from the following types of
              organizations:
              <li>commonly-known consumer and/or business information sources;</li>
              <li>dot.com community sites;</li>
              <li>associations or other groups representing charities;</li>
              <li>online directory distributors;</li>
              <li>internet portals;</li>
              <li>accounting, law, and consulting firms; </li>
              <li>educational institutions and trade associations.</li>
              <br />
              <br />
              We will approve link requests from these organizations if we decide that: (a) the link
              would not make us look unfavorably to ourselves or to our accredited businesses; (b)
              the organization does not have any negative records with us; (c) the benefit to us
              from the visibility of the hyperlink compensates the absence of waymark enterprises
              private limited; and (d) the link is in the context of general resource information.
              <br />
              <br />
              These organizations may link to our home page so long as the link: (a) is not in any
              way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the
              linking party and its products or services; and (c) fits within the context of the
              linking party site.
              <br />
              <br />
              If you are one of the organizations listed in paragraph 2 above and are interested in
              linking to our website, you must inform us by sending an e-mail to waymark enterprises
              private limited. Please include your name, your organization name, contact information
              as well as the URL of your site, a list of any URLs from which you intend to link to
              our Website, and a list of the URLs on our site to which you would like to link. Wait
              2-3 weeks for a response.
              <br />
              <br />
              Approved organizations may hyperlink to our Website ethas follows:
              <li>By use of our corporate name; or</li>
              <li>By use of the uniform resource locator being linked to; or</li>
              <li>
                Using any other description of our Website being linked to that makes sense within
                the context and format of content on the linking party site.
              </li>
              No use of waymark enterprises private limited logo or other artwork will be allowed
              for linking absent a trademark license agreement.
            </div>

            <div className="hdddder212">
              3. <span>Content Liability: </span>
            </div>

            <div className="content212">
              We shall not be held responsible for any content that appears on your Website. You
              agree to protect and defend us against all claims that are raised on your Website. No
              link(s) should appear on any Website that may be interpreted as libelous, obscene, or
              criminal, or which infringes, otherwise violates, or advocates the infringement or
              other violation of, any third party rights.
            </div>

            <div className="hdddder212">
              4. <span>Reservation of Rights: </span>
            </div>

            <div className="content212">
              We reserve the right to request that you remove all links or any particular link to
              our Website. You approve to immediately remove all links to our Website upon request.
              We also reserve the right to amend these terms and conditions and its linking policy
              at any time. By continuously linking to our Website, you agree to be bound to and
              follow these linking terms and conditions.
            </div>

            <div className="hdddder212">
              5. <span> Removal of links from our website: </span>
            </div>

            <div className="content212">
              If you find any link on our Website that is offensive for any reason, you are free to
              contact and inform us at any moment. We will consider requests to remove links, but we
              are not obligated to or so or to respond to you directly.
              <br />
              <br />
              We do not ensure that the information on this website is correct. We do not warrant
              its completeness or accuracy, nor do we promise to ensure that the website remains
              available or that the material on the website is kept up to date.
            </div>

            <div className="hdddder212">
              6. <span> Disclaimer: </span>
            </div>

            <div className="content212">
              To the maximum extent permitted by applicable law, we exclude all representations,
              warranties, and conditions relating to our website and the use of this website.
              Nothing in this disclaimer will:
              <br />
              <br />
              <li>limit or exclude our or your liability for death or personal injury;</li>
              <li>
                limit or exclude our or your liability for fraud or fraudulent misrepresentation;
              </li>
              <li>
                limit any of our or your liabilities in any way that is not permitted under
                applicable law; or
              </li>
              <li>
                exclude any of our or your liabilities that may not be excluded under applicable
                law.
              </li>
              The limitations and prohibitions of liability set in this Section and elsewhere in
              this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all
              liabilities arising under the disclaimer, including liabilities arising in contract,
              in tort, and for breach of statutory duty.
              <br />
              <br />
              As long as the website and the information and services on the website are provided
              free of charge, we will not be liable for any loss or damage of any nature.
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default TermsOfUse;
