import React from "react";

import Accordion from "./Accordion";

function FAQ() {
  return (
    <div className="App12652">
      <br></br>
      <h1>Frequently Asked Questions</h1>
      <Accordion
        title="What is Insurance?"
        content="Insurance is a way to manage your risk. When you buy insurance, you purchase protection against unexpected financial losses. The insurance company pays you or someone you choose, if something bad happens to you. The most common type of insurance are Life Insurance, Auto Insurance and Health Insurance."
      />
      <Accordion
        title="What are the key documents required when applying for any new insurance plan?"
        content="Though the documentation requirements many vary from insurance to insurance, some of the key documents that you would need to provide with your insurance application include:
        • Address Proof Documents (Aadhar Card)
        • Identity Proof Documents (PAN Card) and others
        • Passport size photograph"
      />
      <Accordion
        title="What type of Insurance does Stocktick provide?"
        content="Stocktick, a brand of Waymark Enterprises Private Ltd. offers all types of insurance such as life insurance, vehicle insurance (car, bike, heavy duty) and health insurance from leading public as well as private insurance companies of India."
      />
      <Accordion
        title="Why Health Insurance is Important?"
        content="Covid-19 pandemic has made us realize how medical emergencies are unpredictable and can cause a financial turmoil that can be difficult to control. Besides, with the rising cost of medical bills, hospitalization charges and surgery costs, access to good medical facility can be financially draining. However, with a health insurance plan, individuals and families can easily avert such financial crisis during a medical emergency. Besides, a health insurance cover not only beats medical treatment inflation but also protects your hard-earned savings."
      />
      <Accordion
        title="What is the first step when applying for any insurance?"
        content="The first step is to gather everything that is required to apply for the insurance that you need. 
        Documents such as income proof in case of life insurance (if the sum assured/premium is high), medical reports in case of health insurance and registration certificate in case of vehicle insurance 
        However, if you are clueless and seek advice, book a free call with us here."
      />
      <Accordion
        title="How do I apply for any insurance online?"
        content="You can avail any type of insurance online by Booking a Free call with us here. 
        Your insurance process will take in 2 quick and easy steps:
        1. Book a Free Call and 
        2. Our advisor will get in touch with you"
      />
      <Accordion
        title="What is a Premium in Insurance?"
        content="Premium is the periodical amount paid by you (the insured) to the insurer (the insurance company) for covering your risk by providing you with the insurance policy."
      />
      <Accordion
        title="What is Sum Assured?"
        content="‘Sum Assured’ is the term used for an amount that the insurer agrees to pay on death of the insured person or occurrence of any other insured event."
      />
    </div>
  );
}

export default FAQ;
