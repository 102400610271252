import React, {useState, useEffect} from 'react'
import { useForm } from "react-hook-form";
import Footer from '../../Footer/Footer';
import Headline from '../../Headline/Headline';
import Navbar from '../../Navbar/Navbar';
import './css/editProfile.css';
import axios from "axios";

function EditProfile() {
    const [profile, setProfile] = useState({
        name: '',
        email_id: '',
        mobile: '',
        dob: '',
        address: '',
        blood_group: 'A+',
        emergency_name: '',
        emergency_contact: '',
        emergency_address: '',
        
        
      });

  
    console.log("these are the props", profile, setProfile);


    function handleSubmit(e) {
        e.preventDefault();
        const authToken = localStorage.getItem("authToken");
        axios
          .post(process.env.REACT_APP_API_BASE_URL + `/asset-recorder/pers/?authToken=${authToken}`,profile)
          .then((res) => {
            console.log(profile);
            console.log(res);
            // setPage(1);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      useEffect(async () => {
        const authToken = localStorage.getItem("authToken");
        await axios
          .get(process.env.REACT_APP_API_BASE_URL + `/asset-recorder/pers/?authToken=${authToken}`)
          .then((res) => {
            console.log("Data fetched");
            console.log(res.data);
            if (res && res.data && res.data[0]) {
              const user = res.data[0];
              setProfile({
                name: user.name,
                email_id: user.email_id,
                mobile: user.mobile,
                address: user.address,
                dob: user.dob,
                blood_group: user.blood_group,
                emergency_name: user.emergency_name,
                emergency_contact: user.emergency_contact,
                emergency_address: user.emergency_address,
              });
            }
          })
          .catch(() => console.log("error occurred"));
      }, []);


   
      
     
    const today = new Date();

   
    // const [editName , setEditName] = useState(false);
    // const [editMobile, setEditMobile] = useState(false);
    // const [editEmail, setEditEmail] = useState(false);
    // const [editLocation, setEditLocation] = useState(false);
    // const [editBirth, setEditBirth] = useState(false);
    // const [editBloodGroup, setEditBloodGroup] = useState(false);
    // const [editTelephone, setEditTelephone] = useState(false);
    // const [editAddress, setEditAddress] = useState(false);
    // const [seePassword, setSeePassword] = useState(false);
    

    // "name":"verma verma harsh",
    // "address":"ajfnef",
    // "mobile":"8445737698",
    // "email_id":"harshvlmv@gmail.com",
    // "dob":"2000-01-16",
    // "blood_group":"B+",
    // "emergency_name":"aman",
    // "emergency_contact":"27642834224",
    // "emergency_address":"jsehfsjnsjfh"

   

   

    return (
        <>
        <Headline />
        <Navbar />
        <div className='parent-form-prooofilee'>

           <div className="abcdew">
        <div className="customer-edit-profile">
         
        <div className="reactform">
        <div>
               <h1>Personal Details</h1>
            <div className="input-entry">
            <label className="label-cust-edit-profile">Name</label>
            <input
                name="name"
                className="input-text"
                type="text"
                placeholder=""
                // readOnly={!editName}
                value={profile.name || ""}
                onChange={(e) => {
                  setProfile({ ...profile, name: e.target.value });
                }}
                
            />
            {/* <div className="change-button" onClick={() => {setEditName(!editName)}}>{editName ? "save" : "edit"}</div> */}
          
           
            </div>


            <div className="input-entry">
            <label className="label-cust-edit-profile"> Current Location</label>
            <input
                name="current_location"
                className="input-text"
                type="text"
                placeholder=""
                // readOnly={!editLocation}
                value={profile.address || ""}
                onChange={(e) => {
                  setProfile({ ...profile, address: e.target.value });
                }}
                
               
            />
            {/* <div className="change-button" onClick={() => {setEditLocation(!editLocation)}}>{editLocation ? "save" : "edit"}</div> */}
          
           
            </div>

            <div className="input-entry">
            <label className="label-cust-edit-profile"> Mobile Number</label>
            <div className="mobnumber-input-box">
                <select
                name="dial-code"
                className="dial-code-drop"
                type="text"
                // readOnly={!editMobile}
               >
                   
                    <option>+91</option>
                  
                </select>
                <input
                name="mob_number"
                className="input-text input-number"
                type="tel"
                placeholder=""
                value={profile.mobile}
                // readOnly={!editMobile}
                onChange={(e) => {
                    setProfile({ ...profile, mobile: e.target.value });
                  }}
                />
            </div>
           
            {/* <div className="change-button" onClick={() => {setEditMobile(!editMobile)}}>{editMobile ? "save" : "edit"}</div> */}
           
            </div>

            <div className="input-entry">
            <label className="label-cust-edit-profile"> Email ID</label>
            <input
                name="email"
                className="input-text-email"
                type="email"
                placeholder=""
                value={profile.email_id}
                // readOnly={!editEmail}
                
                onChange={(e) => {setProfile({...profile , email_id : e.target.value})}}
            />
           
            {/* <div className="change-button" onClick={() => {setEditEmail(!editEmail)}}>{editEmail ? "save" : "edit"}</div> */}
           
            </div>

            <div className="input-entry">
            <label className="label-cust-edit-profile"> Date of Birth</label>
            <input
                name="date_of_birth"
                className="input-text-email"
                type="date"
                placeholder=""
                value={profile.dob}
                min="1950-01-01" max={(parseInt(today.getFullYear()) - 18) + "-" + (today.getMonth() + 1) + "-" + today.getDate()}
                // readOnly={!editBirth}
               
                onChange={(e) => {setProfile({...profile , dob : e.target.value})}}
            />
            
            {/* <div className="change-button" onClick={() => {setEditBirth(!editBirth)}}>{editBirth ? "save" : "edit"}</div> */}
           
            </div>

            

            <div className="input-entry">
                <label className="label-cust-edit-profile">Blood Group</label>
                <select
                    name="blood_group"
                    className="blood-group-drop"
                    type="text"
                    value={profile.blood_group}
                    // readOnly={!editBloodGroup}
                   
                    onChange = {(e) => {
                      setProfile({...profile , blood_group : e.target.value})
                    }}
                >
                    <option value="A+">A+</option>
                    <option value="B+">B+</option>
                    <option value="O+">O+</option>
                    <option value="AB+">AB+</option>
                    <option value="A-">A-</option>
                    <option value="B-">B-</option>
                    <option value="O-">O-</option>
                    <option value="AB-">AB-</option>
                </select>
              
                {/* <div className="change-button" onClick={() => {setEditBloodGroup(!editBloodGroup)}}>{editBloodGroup ? "save" : "edit"}</div> */}
            </div>

            <button className="form-btn-edit-profile" onClick={(e)=>handleSubmit(e)} >
                save 
            </button>
        </div>
        </div>
        </div>

        <div className='second-div-flexx'>
            <h1>Emergency Contact</h1>

            <div className="input-entry">
            <label className="label-cust-edit-profile">Name</label>
            <input
                name="name"
                className="input-text"
                type="text"
                placeholder=""
                value={profile.emergency_name}
                // readOnly={!editName}
                onChange={(e) => {setProfile({...profile , emergency_name : e.target.value})}}
            />
            {/* <div className="change-button" onClick={() => {setEditName(!editName)}}>{editName ? "save" : "edit"}</div> */}
            
           
            </div>


            <div className="input-entry">
            <label className="label-cust-edit-profile"> Address</label>
            <input
                name="address"
                className="input-text"
                type="text"
                placeholder=""
                value={profile.emergency_address}
                // readOnly={!editAddress}
              
                onChange={(e) => {setProfile({...profile , emergency_address : e.target.value})}}
            />
            {/* <div className="change-button" onClick={() => {setEditAddress(!editAddress)}}>{editAddress ? "save" : "edit"}</div> */}
         
           
            </div>

            <div className="input-entry">
            <label className="label-cust-edit-profile"> Telephone</label>
            <div className="mobnumber-input-box">
                
                <input
                name="telephone_no"
                className="input-text input-number1"
                type="tel"
                placeholder=""
                value={profile.emergency_contact}
                // readOnly={!editTelephone}
                onChange={(e) => {setProfile({...profile , emergency_contact : e.target.value})}}
                
                />
            </div>
            
            {/* <div className="change-button" onClick={() => {setEditTelephone(!editTelephone)}}>{editTelephone ? "save" : "edit"}</div> */}
           
            </div>


            

        </div>
        </div>
        </div>

         <Footer />
        </>
    )
}

export default EditProfile
