import React from "react";
import { useEffect } from 'react';
import { Button, Stack } from "@mui/material";
import "./financial.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Chart from "react-apexcharts";

function AssetAllocation ({page, setPage}) {
    const [stats3, setStats3] = React.useState({
        gold: 6000,
        equity: 6000,
        realEstate: 6000,
        debt: 6000
    });
    const [series3, setSeries3] = React.useState([6000,6000,6000,6000]);
    const [option3, setOption3] = React.useState({chart : {id: "asset-allocation", type: "donut"},
    labels: ["Gold", "Equity", "Real Estate", "Debt"], 
    responsive: [{
        breakpoint: 180,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
    legend: {
        position: 'right',
        offsetY: 0,
    }});

    useEffect(()=>{
        setSeries3([stats3.gold, stats3.equity, stats3.realEstate, stats3.debt]);
    },[stats3]);

    async function handleSubmit(e){
        const token = localStorage.getItem("authToken")
        const url = process.env.REACT_APP_API_BASE_URL + "/finantialplanning/finnantial-Q3?authToken="+token
        await fetch(url, {
            method : "POST",
            headers : {
                // "authToken" : token,
                "Content-Type" : "application/json"
            },
            body  : JSON.stringify({
                gold : stats3.gold,
                equity : stats3.equity,
                estate : stats3.realEstate,
                debt : stats3.debt
            }) 
        })
        .then((res)=> res.json())
        .then(data => {
            console.log("response from adding data ", data)
            if(data.message){
                setPage(page+1);
            }
            else {
                alert("Something went wrong while submitting your data, please try again")
            }
        })
        .catch( err => {
            console.log(err)
            alert("Something went wrong, please check your internet connection and try again")
        })
        e.preventDefault();
    }

    useEffect( async () => {
        const token = localStorage.getItem("authToken");
        const url = process.env.REACT_APP_API_BASE_URL + "/finantialplanning/finnantial-Q3?authToken=" + token
        await fetch(url, {
            method : "GET",
            // headers : {
            //     "authToken" : token
            // }
        })
        .then(res => res.json())
        .then(data => {
            console.log(data)
            if(data && data.gold){
                setStats3({
                    gold: data.gold,
                    equity: data.equity,
                    realEstate: data.estate,
                    debt: data.debt,
                })
            }
        })
        .catch(err => {
            console.log(err)
        })
    }, [])

    return(
        <div className="profile-info-container">
            <div className="profile-head">WELCOME TO FINANCIAL PLANNING</div>
            <div className="profile-subheading">{"Current asset allocation"}</div>

            <div className="div-flex">
            <Chart 
                type = 'donut'
                options = {option3}
                series = {series3}
            >
            </Chart> 
            <div style={{display: "flex", flexDirection: "row", minWidth:"200px"}}>
                <div style={{display: "flex", flexDirection:"column", marginRight:"-20px"}}>
                    <label style={{ color:"grey", backgroundColor:"white"}}>Gold</label>
                    <label style={{ color:"grey", backgroundColor:"white"}}>Equity</label>
                    <label style={{ color:"grey", backgroundColor:"white"}}>Real Estate</label>
                    <label style={{ color:"grey", backgroundColor:"white"}}>Debt</label>
                </div>
                <Stack className="abcde123" alignItems="space-between" justifyContent="space-between" direction="column" style={{margin:"20px"}}>
                    <input
                        type="number"
                        placeholder="0"
                        value={stats3.gold}
                        style={{margin:"20 40", padding: "20 40"}}
                        onChange={(e) => {
                            setStats3({ ...stats3, gold: parseInt(e.target.value) });
                        }}></input>
                    <input
                        type="number"
                        placeholder="0"
                        value={stats3.equity}
                        onChange={(e) => {
                            setStats3({ ...stats3, equity: parseInt(e.target.value) });
                        }}
                    />
                    <input
                        type="number"
                        placeholder="0"
                        value={stats3.realEstate}
                        onChange={(e) => {
                            setStats3({ ...stats3, realEstate: parseInt(e.target.value) });
                        }}
                    />
                    <input
                        type="number"
                        placeholder="0"
                        value={stats3.debt}
                        onChange={(e) => {
                            setStats3({ ...stats3, debt: parseInt(e.target.value) });
                        }}
                    />
                </ Stack>
            </div>
            </div>
            <div className="profile-button-row">
            <button className="hedfa" variant="contained" style={{margin:"20px",padding:"20 40"}} onClick={() => setPage(page-1)}  startIcon={
                    <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>}>Previous   </button>
                <button className="jkrd" variant="outlined" style={{margin:"20px",padding:"20 40"}} onClick={() => setPage(page+1)}>SKIP</button>
                <button className="hedfa" variant="contained" style={{margin:"20px",padding:"20 40"}} onClick={handleSubmit} endIcon={
                    <FontAwesomeIcon  icon={faArrowRight}></FontAwesomeIcon>}>NEXT   </button>
            </div>
        </div>
    )
}

export default AssetAllocation;