import React,{useState, useEffect} from "react";
import { ToastContainer } from "react-toastify";
import "./App.css";
import Modal from '@mui/material/Modal';
// import Bubbles from "./components/Bubble/Bubbles";
import Routes from "./routes";

export const  tawkToLoadScripts=async ()=> {
  // var Tawk_API = Tawk_API || {};

  // var Tawk_LoadStart = new Date();

  await (function () {
      var s1 = document.createElement('script'),
      s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = process.env.REACT_APP_CHAT_BOT_URL;
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
  })();
}

window.Tawk_API = window.Tawk_API || {}
window.Tawk_LoadStart = new Date();

window.Tawk_API.onLoad = function () {
  console.log("chat loaded");
  window.Tawk_API.setAttributes(
    // {
    //   ...attritbutesToSet
    // },
    function (error) {
      console.log(error)
      //console.log("window.Tawk_API errorr",error)
    }
  );
}

function App() {
  useEffect(() => {
    tawkToLoadScripts()
  }, [])
  
  const [open,setOpen]=useState(false);
  return (
    <>
      <div className="App">
        {/* <div style={{ zIndex: 0, height: "100%", width: "100%" }}>
          <Bubbles />
        </div> */}
        <Routes />
        <ToastContainer />
      </div>
      {/* <Modal open={open} onClose={()=>setOpen(false)}>
        <iframe src="../public/chatbot.js" name="iframe_a" height="400px" width="300px" style={{position:"relative", left:"70%", top:"60%"}} title="Iframe Example"></iframe>
      </Modal>
      <button className="chat-bot1" onClick={()=>setOpen(true)}><img src="/images/icons/chatbot.svg" alt="" /></button>
      <button className="chat-bot"><img src="/images/icons/whatsapp.svg" alt="" /></button> */}
    </>
  );
}

export default App;
