/* eslint-disable react/prop-types */
import { Grid, Dialog } from "@mui/material";
import React, { useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import Appbar from "../components/Appbar/Appbar";
import Footer from "../components/Footer/Footer";
import LoginLeft from "../components/Login/LoginLeft";
import LoginRight from "../components/Login/LoginRight";
import Navbar from "../components/Navbar/Navbar";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import Headline from "../components/Headline/Headline";
import { AnimationRounded } from "@mui/icons-material";
import './login.css'

const Background = styled.div`

`;

export const LoginModal = ({showLoginModal, setShowLoginModal}) => {

  const modalRef = useRef();

  const animation = useSpring({
    config: {
      duration: 250
    },
    opacity: showLoginModal ? 1 : 0,
    transform: showLoginModal ? `translateY(0%)` : `translateY(-100%)`

  });

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setShowLoginModal(false);
    }
  };

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showLoginModal) {
        setShowLoginModal(false);
        console.log('I pressed');
      }
    },
    [setShowLoginModal, showLoginModal]
  );

  useEffect(
    () => {
      document.addEventListener('keydown', keyPress);
      return () => document.removeEventListener('keydown', keyPress);
    },
    [keyPress]
  );

  return (
    <>
    {showLoginModal ? 
      
      
      (<div className="parent-login">
      <Background onClick={closeModal} ref={modalRef} className="hedaaaa">
        <animated.div style={animation} className="kjjh">
      <div className="login-box" showLoginModal={showLoginModal}>

        <LoginLeft />
        <LoginRight />
        
        <button className="close-modal"  onClick={() => setShowLoginModal(prev => !prev)}> <img src="./images/icons/close.svg" alt="" /> </button>
      </div>
      </animated.div>
      </Background>
      </div>
    ) : null}
    </>
  );
}


