import React from "react";
import { useEffect, useState } from 'react';
import { Button, Stack } from "@mui/material";
import "./financial.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

function NetworkHistory ({page, setPage}) {

    const [ year, setYear] = useState("2018-2019");
    const [data, setData] = useState([
        {
            year: "2018-2019",
            asset: "50",
            liability : "104",
            net_worth: "500"
        },
        {
            year: "2019-2020",
            asset: "50",
            liability: "100",
            net_worth: "500"
        },
        {
            year: "2020-2021",
            asset: "50",
            liability: "100",
            net_worth : "50"
        }
    ]);
    const idxes = {
        "2018-2019" : 0,
        "2019-2020" : 1,
        "2020-2021" : 2,
    }
    useEffect( async () => {
        const token = localStorage.getItem("authToken");
        const url = process.env.REACT_APP_API_BASE_URL + "/finantialplanning/finnantial-Q8?authToken=" + token
        await fetch(url, { 
            method : "GET"
        })
        .then(res => res.json())
        .then(async (data) => {
            console.log(data)
            if(data.data){
                setData(data.data);
            }
        })
        .catch(err => {
            console.log(err)
        })
    }, [])

    async function handleSubmit(e){
        const token = localStorage.getItem("authToken")
        const url = process.env.REACT_APP_API_BASE_URL + "/finantialplanning/finnantial-Q8?authToken="+token
        console.log({data : data})
        const tempdata = data.map(item => {
            return {
                year : item.year,
                asset : item.asset,
                liability : item.liability,
                networth : item.net_worth
            }
        })
        await fetch(url, {
            method : "POST",
            headers : {
                // "authToken" : token,
                "Content-Type" : "application/json"
            },
            body  : JSON.stringify({data : tempdata}) 
        })
        .then((res)=> res.json())
        .then(data => {
            console.log("response from adding data ", data)
            if(data.message){
                setPage(page+1);
            }
            else {
                alert("Something went wrong while submitting your data, please try again")
            }
        })
        .catch( err => {
            console.log(err)
            alert("Something went wrong, please check your internet connection and try again")
        })
        e.preventDefault();
    }

    useEffect(() => {
        console.log(data)
    }, [data[0].asset])

    return(
        <div className="profile-info-container">
            <div className="profile-head">WELCOME TO FINANCIAL PLANNING</div>
            <Stack direction="row" alignItems="space-between" justifyContent="space-between">
                <Stack direction="column">
                </Stack>
                    <Stack direction="column">
                        <div className="profile-subheading" style={{fontSize:"30px", justifyContent:"center", alignSelf:"center"}}>{"Network History"}</div>
                    <Stack direction="row">
                        <label style={{ color:"grey", backgroundColor:"white"}}> Choose Financial Year </label>
                        <select value={year} onChange={(e) => {setYear(e.target.value)}} style={{margin:"20px"}}>
                            <option value="2018-2019" selected>2018-2019</option>
                            <option value="2019-2020">2019-2020</option>
                            <option value="2020-2021">2020-2021</option>
                        </select>
                    </Stack>
                    <Stack direction="row">
                        <Stack direction="column">
                            <label style={{ color:"grey", backgroundColor:"white"}}>Assets</label>
                            <label style={{ color:"grey", backgroundColor:"white"}}>Liabilities</label>
                            <label style={{ color:"grey", backgroundColor:"white"}}>Net Worth</label>
                        </Stack>
                        <Stack className="abcde123" alignItems="space-between" justifyContent="space-between" direction="column" style={{margin:"20px"}}>
                            <input
                                type="number"
                                placeholder="0"
                                value={data[idxes[year]].asset}
                                // style={{margin:"20 40", padding: "20 40"}}
                                onChange={(e) => {
                                    const temp_data = [...data];
                                    console.log(e.target.value)
                                    temp_data[idxes[year]] = {...temp_data[idxes[year]], asset : e.target.value}
                                    console.log(temp_data)
                                    setData(temp_data)
                                }} />
                            <input
                                type="number"
                                placeholder="0"
                                // defaultValue={6000}
                                value={data[idxes[year]].liability}
                                style={{margin:"20 40", padding: "20 40"}}
                                onChange={(e) => {
                                    let temp_data = [...data];
                                    temp_data[idxes[year]].liability = e.target.value
                                    // console.log(temp_data)
                                    setData(temp_data)
                                }}
                            />
                            <input
                                type="number"
                                value={data[idxes[year]].net_worth}
                                style={{margin:"20 40", padding: "20 40"}}
                                onChange={(e) => {
                                    let temp_data = [...data];
                                    temp_data[idxes[year]].net_worth = e.target.value
                                    // console.log(temp_data)
                                    setData(temp_data)
                                }}
                            />
                        </ Stack>
                    </Stack>
                </Stack>
            </Stack>
            <div className="profile-button-row">
            
            <button className="hedfa" variant="contained" style={{margin:"20px",padding:"20 40"}} onClick={()=> setPage(page-1)} startIcon={
                    <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>}>Previous  </button>
                <button className="jkrd" variant="outlined" style={{margin:"20px",padding:"20 40"}} onClick={() => setPage(page+1)}>SKIP</button>
                <button className="hedfa" variant="contained" style={{margin:"20px",padding:"20 40"}} onClick={handleSubmit} endIcon={
                    <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>}>NEXT   </button>
            </div>
        </div>
    )
}

export default NetworkHistory;