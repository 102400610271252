import axios from "axios";

async function getProfile() {
    var profile = null;
    const authToken= localStorage.getItem('authToken')
    await axios.get( process.env.REACT_APP_API_BASE_URL + `/get/userinfo/?authToken=${authToken}`).then(res => {
        profile = res.data;
        return(profile)
    }).catch(() => {profile = null})
    return(profile)
}

export default getProfile 