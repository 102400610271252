import React, { useState, useEffect } from 'react'
import Explore from '../DiscoverMutualFunds'
import axios from 'axios';
import './result.css';
import GaugeChart from 'react-gauge-chart'
import up from '../../images/trending-up.png'
const res = "/images/misc/risk-factor.png";
// const up = '../../images/trending-up.png'
import DiscoverMutualFunds from "./../DiscoverMutualFunds";



function RiskFactorResult({ handleRetry }) {
  const [score, setScore] = useState(0);
  const [suggestion, setSuggestion] = useState(0);
  const [pdf_url, setURL] = useState("")
  useEffect(() => {
    const authToken = localStorage.getItem('authToken')
    axios.get(process.env.REACT_APP_API_BASE_URL + `/riskfactor/result/?authToken=${authToken}`).then((res) => {
      setScore(res.data['total score'])
      setSuggestion(res.data.range[0].description)
    })
    axios.get(process.env.REACT_APP_API_BASE_URL + `/riskfactor/pdf?authToken=${authToken}`).then(res => {
      console.log("this is the pdf ", res)
      setURL(res.data.url)
    }).catch(() => {
      alert("Something went wrong while generating pdf, please try again")
    })
  }, [])

  return (
    <div className='riskfactor-container-result'>
      <div className="profile-form-head">Based on your answers, Here is your Report</div>
      <GaugeChart id="gauge-chart1"
        nrOfLevels={6}
        colors={["#FF5F6D", "#FFC371", "#39B54A"]}
        arcWidth={0.6}
        percent={score / 100}
        textColor="#000000"
        needleColor='#434343'
        hideText={true}
        needleBaseColor="#616262"
        className='hgj'
        formatTextValue={(value) => 'Your performance rating is ' + value + '%'}
      />
      {score >= 90 ? <div className='perform-report best'>Performance Report : {score}%</div> : score >= 80 ? <div className='perform-report avg'>Performance Report : {score}%</div> : score >= 60 ? <div className='perform-report mid'>Performance Report : {score}% </div> : score >= 40 ? <div className='perform-report bad'>Performance Report : {score}% </div> : score >= 20 ? <div className='perform-report worst'>Performance Report : {score}% </div> : score >= 0 ? <div className='perform-report mostworst'>Performance Report : {score}% </div> : ""}
      <p className='riskfactor-suggestion'>Your ability to take risks is evaluated by reviewing your assets and liabilities. An individual with many assets and few liabilities has a high ability to take on risk. Conversely, an individual with few assets and high liabilities has a low ability to take on risk. Also different phases of life affect the capacity to take risk </p>
      <div className='riskfactor-score-board'>
        <div className='riskfactor-score'>You have scored <strong>{score} out of 100</strong></div>
        <div className='riskfactor-score-comment'>Based on your score, we have investment opportunities that suit your profile perfectly to provide peace of mind to attain your financial freedom journey. Get personalised advice to improve your profile and make it healthy today!
        </div>
      </div>
      <p className='riskfactor-suggestion'>{suggestion}</p>
      <button className='riskfactor-retry' onClick={(e) => handleRetry(e)}>Retry</button>
      <div className='riskfactor-btn-row'>
        <button>Share</button>
        <button><a href={pdf_url} taget="_blank">Download</a></button>
        <button><a href="https://calendly.com/vijaymaheshwari/financialfreedom" taget="_blank">Book A Free Call</a></button>

      </div>
      <div className='riskfactor-discover'>
        {/* <div className='riskfactor-discover-head'>Discover Mutual Funds</div> */}
        <DiscoverMutualFunds />
      </div>
      {/* <Explore handleClick={() => {console.log("polre")}}/> */}
    </div>
  )
}

export default RiskFactorResult