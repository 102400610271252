/* eslint-disable react/prop-types */
import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions } from "@mui/material";
import { Button } from "../Navbar/Button";

const arrow = "/images/icons/forward-arrow.svg";
// eslint-disable-next-line no-unused-vars
function MediaCard({ long_desc, short_desc, image_url, video_link }) {
  const openLink = () => {
    window.open(video_link, "_blank");
  };

  return (
    <div className="media-card" onClick={openLink}>
      <img className="media-card-image" src={image_url} alt={short_desc} />
      <div className="media-description" onClick={openLink}>{short_desc}</div>

      {/* <img src={arrow} className='media-arrow1' /> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        height="30"
        width="30"
        className="arrowicon">
        <path d="M0 0h24v24H0z" fill="none"></path>
        <path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"></path>
      </svg>
    </div>
  );
}

export default MediaCard;
