import React, { useState } from "react";
import Footer from "../components/Footer/Footer";
import Poster from "../components/Insurance/Poster";
import MiddleInsurance from "../components/Insurance/MiddleInsurance";
import ExploreInsurance from "../components/Insurance/ExploreInsurance";
import InsuranceFAQ from "../components/Insurance/FAQ";
import Appbar from "../components/Appbar/Appbar";
import Car from "../components/Insurance/CarInsurance/Car";
import Life from "../components/Insurance/LifeInsurance/Life";
import Health from "../components/Insurance/HealthInsurance/Health";
import Headline from "../components/Headline/Headline";
import Navbar from "../components/Navbar/Navbar";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import { LoginModal } from "./Login";

function Insurance() {
  const [open, setOpen] = useState(false);
  const [login, setLogin] = useState(false);
  const [type, setType] = useState("1");
  const handleClick = (e) => {
  
      setOpen(true);
      console.log(open);
      console.log(e.target.value);
      setType(e.target.value);
    
    
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
    
    <div style={{ background: "white" }}>
      {open && type == "3" && <Car open={open} handleClose={handleClose} />}
      {open && type == "2" && <Life open={open} handleClose={handleClose} />}
      {open && type == "1" && <Health open={open} handleClose={handleClose} />}
      <ScrollToTop />
      <Headline />
      <Navbar />
      <Poster />
      <MiddleInsurance />
      <ExploreInsurance handleClick={handleClick} />
      <InsuranceFAQ />
      <Footer />
    </div>
    </>
  );
}

export default Insurance;
