/* eslint-disable react/prop-types */
import React, { useState } from "react";
import sendRequest from "../../../helpers/sendPostRequest";
import axios from "axios";
import HealthStepOne from "./HealthStepOne";
import HealthStepTwo from "./HealthStepTwo";

function Health({ open, handleClose }) {
  const [step, setStep] = useState(1);
  console.log("Inside car");
  const handleNext = () => {
    setStep(step + 1);
  };
  const handlePrev = () => {
    setStep(step - 1);
  };
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDOB] = useState(new Date());
  const [add1, setAdd1] = useState("");
  const [add2, setAdd2] = useState("");
  const [family, setFamily] = useState("");
  const [disease, setDisease] = useState("");
  const [success, setSuccess] = useState(false);
  const handleName = (e) => setName(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);
  const handlePhone = (e) => setPhone(e.target.value);
  const handleDOB = (val) => setDOB(val);
  const handleAdd1 = (e) => setAdd1(e.target.value);
  const handleAdd2 = (e) => setAdd2(e.target.value);
  const handleFamily = (e) => setFamily(e.target.value);
  const handleDisease = (e) => setDisease(e.target.value);
  const handleSubmit = () => {
    const body = {
      type: "health",
      name,
      email,
      mobile: "3896535493",
      location: add1 + add2,
      age: 50,
      insurance_for: family,
      insurer_name: name,
      insurer_age: "12",
      medical: "Cold,dust allergy"
    };

    axios.post(process.env.REACT_APP_API_BASE_URL + `/add/insurance/details/`, body)
      .then((data) => {
        if (data.status === 200) {
          console.log(data);
          setSuccess(true);
          handleClose();
          alert("Insurance added.");
        }
      })
      .catch((e) => console.log(e.message));
  };
  return (
    <>
      {step === 1 && (
        <HealthStepOne
          open={open}
          handleClose={handleClose}
          handlePrev={handlePrev}
          handleNext={handleNext}
          name={name}
          email={email}
          phone={phone}
          dob={dob}
          add1={add1}
          add2={add2}
          handleName={handleName}
          handleEmail={handleEmail}
          handlePhone={handlePhone}
          handleDOB={handleDOB}
          handleAdd1={handleAdd1}
          handleAdd2={handleAdd2}
        />
      )}
      {step === 2 && (
        <HealthStepTwo
          handlePrev={handlePrev}
          open={open}
          handleClose={handleClose}
          family={family}
          disease={disease}
          handleFamily={handleFamily}
          handleDisease={handleDisease}
          handleSubmit={handleSubmit}
          success={success}
        />
      )}
    </>
  );
}

export default Health;
