import React, { useRef, useState } from "react";
import Headline from "../Headline/Headline";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Carousel from "./Carousel/Carousel";
import Enroll from "./Enroll";
import "./partner.css";
import "../../App.css";
import img1 from "../images/A.png";
import img2 from "../images/B.png";
import img3 from "../images/C.png";
import Frame14 from "../images/Frame14.svg";
import icon1 from "../images/cmpy/icon1.png";
import icon2 from "../images/cmpy/icon2.png";
import icon3 from "../images/cmpy/icon3.png";
import icon4 from "../images/cmpy/icon4.png";
import icon5 from "../images/cmpy/icon5.png";
import icon6 from "../images/cmpy/icon6.png";
import icon7 from "../images/cmpy/icon7.png";
import icon8 from "../images/cmpy/icon8.png";
import icon9 from "../images/cmpy/icon9.png";

import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

function getImagesNumber() {
  const { innerWidth: width } = window;
  console.log("Width", width);

  const num = width > 700 ? 4 : width > 500 ? 3 : 1;

  console.log("Number", num);

  return num;
}

function Partner() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="home">
      <Headline />
      <Navbar />

      <div className="p_main">
        <div className="p_article">
          <div className="p_left">
            <div className="p_title">Associate With Us</div>

            <div className="p_data">
              Full of opportunities, we create wealth and transform lives. Become our distributor
              and get access to a wide range of financial products under one window – Mutual Funds,
              Bonds &amp; FD, Equity &amp; ETFS, Insurance Products and Loans.
            </div>
          </div>

          <div className="p_right">
            <div className="p_img">
              <img src={img1} alt="pic" width={"70%"} height={"70%"} />
            </div>
          </div>
        </div>

        <div className="p_article p_rev">
          <div className="p_left">
            <div className="p_title">Comprehensive Services for Wealth Generation</div>

            <div className="p_data">
              Varied Product Offerings | Sales Support | Technology Solutions | Research Support |
              Marketing Support | Competitive Edge | Training Support | Online Query Model | Quick
              TAT for Faster Resolution
            </div>
          </div>

          <div className="p_right">
            <div className="p_img">
              <img src={img2} alt="pic" width={"70%"} height={"70%"} />
            </div>
          </div>
        </div>

        <div className="p_article">
          <div className="p_left">
            <div className="p_title">Our Advantage</div>
            <div className="p_data">
              High Revenue Sharing Model | Investor Awareness | Personal Assistance | Cutting- edge
              Technology Platform | Personalized Branding | Risk Management Systems
            </div>
          </div>

          <div className="p_right">
            <div className="p_img">
              <img src={img3} alt="pic" width={"70%"} height={"70%"} />
            </div>
          </div>
        </div>

        <div className="p_article">
          <div className="p_head">
            <div>Our esteemed partners</div>
            <hr style={{ width: "40%", "margin-left": "-20px" }} />
          </div>
          <div className="p_company">
            <Carousel show={getImagesNumber()}>
              <div>
                <div>
                  <img src={icon1} alt="pic" width={"250px"} height={"150px"} />
                </div>
              </div>
              <div>
                <div>
                  <img src={icon2} alt="pic" width={"250px"} height={"150px"} />
                </div>
              </div>
              <div>
                <div>
                  <img src={icon3} alt="pic" width={"250px"} height={"150px"} />
                </div>
              </div>
              <div>
                <div>
                  <img src={icon4} alt="pic" width={"250px"} height={"150px"} />
                </div>
              </div>
              <div>
                <div style={{ padding: 2 }}>
                  <img src={icon5} alt="pic" width={"250px"} height={"150px"} />
                </div>
              </div>
              <div>
                <div style={{ padding: 2 }}>
                  <img src={icon6} alt="pic" width={"250px"} height={"150px"} />
                </div>
              </div>
              <div>
                <div style={{ padding: 2 }}>
                  <img src={icon7} alt="pic" width={"250px"} height={"150px"} />
                </div>
              </div>
              <div>
                <div style={{ padding: 2 }}>
                  <img src={icon8} alt="pic" width={"250px"} height={"150px"} />
                </div>
              </div>
              <div>
                <div style={{ padding: 2 }}>
                  <img src={icon9} alt="pic" width={"250px"} height={"150px"} />
                </div>
              </div>
            </Carousel>
          </div>
        </div>

        <Enroll />
      </div>

      <Footer />
    </div>
  );
}

export default Partner;
