import { Button, styled } from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Frame from "../images/Frame.svg";
import insu2 from "../images/insu2.svg";
import car from "../images/car_insu.png";
import "./ins.css";
import "./explore.css";

function Explore({ handleClick }) {
  useEffect(() => {
    getInsurance();
  }, []);
  const [cards, setCards] = useState([]);
  function getInsurance() {
    axios.get(process.env.REACT_APP_API_BASE_URL + "/get/Insurance?platform=W").then((data) => {
      console.log(data);
      setCards(data.data);
    });
  }
  const parseType = (cat) => {
    if (cat == "Health Insurance") {
      return 1;
    } else if (cat == "Term Life Insurance") {
      return 2;
    } else if (cat == "Motor Insurance") {
      return 3;
    } else return 3;
  };
  return (
    <div className="explore-insurance-parent">
      <h1>Secure your Wealth, Start Today!</h1>
      <div className="explore-insurance">
        {cards.map((card) => {
          return (
            <div className="explore-insurance-card stocktick-card" key={card.category}>
              <img src={card.image_url} />
              <h3>{card.category}</h3>
              <p>{card.short_desc}</p>
              <button onClick={handleClick} value={parseType(card.category)}>
                Get a Quote
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Explore;
