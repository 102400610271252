/* eslint-disable react/prop-types */
import {
  Button,
  Grid,
  styled,
  TextField,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  InputLabel,
  Select,
  MenuItem,
  Alert
} from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import GetAssistanceForm from "../Slider/GetAssistanceForm";
import "./loan.css";

const PosterLeft = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    marginLeft: "0%"
  },
  display: "flex",
  padding: "10%",
  position: "relative",
  top: "20%",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "left",
  marginLeft: "20%"
}));

const Image = styled("img")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100vw",
    height: "30vh"
  },
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "40vw",
  height: "auto"
}));

const loans = "/images/misc/loan22.svg";
const mob_loan = "/images/misc/mob-loan.png";

function Poster({ open, handleClose, handleOpen }) {
  console.log("hi");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [msg, setMsg] = useState("");
  const [type, setType] = useState("Car");
  const [assistance, setAssistance] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    query: ""
  });

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePhoneChange = (e) => setPhone(e.target.value);
  const handleNameChange = (e) => setName(e.target.value);
  const handleCompanyChange = (e) => setCompany(e.target.value);
  const handleTypeChange = (e) => setType(e.target.value);
  const handleSubmit = () => {
    const sendRequest = async () => {
      const body = {
        name,
        phone,
        email,
        username: phone,
        loan_type: type,
        business_name: company
      };

      const data = await axios.post(process.env.REACT_APP_API_BASE_URL + `/add/loandetails/`, body);
      return data;
    };
    sendRequest()
      .then((data) => {
        console.log(data);
        if (data.status === 200) {
          handleClose();
          setCompany("");
          setEmail("");
          setName("");
          setPhone("");
          setType("");
          setMsg("");
          alert("Loan added.");
        }
      })
      .catch((e) => setMsg(e.response.data.message));
  };

  const handleAssistance = async () => {
    const data = await axios
      .post(process.env.REACT_APP_API_BASE_URL + "/getassistance/", form)
      .then(() => {
        setForm({
          name: "",
          email: "",
          phone: "",
          query: ""
        });
        setAssistance(false);
      });
    console.log(data);
  };

  return (
    <>
      <div className="loans-poster">
        <div className="loans-poster-left">
          <div className="loan-mobile">
            <img src={mob_loan} alt="" />
          </div>
          <h1>
            <span> Loans </span> Made Simple. Borrow Better. Faster{" "}
          </h1>

          <p>
            Loans that are quickly processed, are available at competitive interest rate and in
            hassle free process.
          </p>

          <button className="apply-now-btn-loan">
            <a href="https://calendly.com/vijaymaheshwari/financialfreedom"
              target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#fff' }}>Book A Free Call</a>
          </button>

          <Dialog open={open} onClose={handleClose}>
            {msg && <Alert severity="error">{msg}</Alert>}
            <DialogTitle>Fill the below details</DialogTitle>
            <button className="riskfactor-close-button" onClick={handleClose}>
              <img src="./images/icons/close.svg" alt="" />{" "}
            </button>
            <DialogContent>
              <input
                type="text"
                placeholder="Name"
                className="assistance-input-name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="email"
                placeholder="Email"
                className="assistance-input-name"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="tel"
                placeholder="Phone Number"
                className="assistance-input-name"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <input
                type="text"
                placeholder="Amount Required"
                className="assistance-input-name"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
              <select value={type} onChange={(e) => setType(e.target.value)}>
                <option disabled selected>
                  Loan Type
                </option>
                <option value="Car">Car</option>
                <option value="Bike">Bike</option>
                <option value="Business">Business</option>
                <option value="Home">Home</option>
                <option value="Home">Personal</option>
                <option value="Home">Education</option>
                <option value="Home">Unsecured Funding</option>
              </select>
            </DialogContent>
            <DialogActions>
              <button className="cancel-assistance" onClick={handleClose}>
                Cancel
              </button>
              <button className="submit-assistance" onClick={handleSubmit}>
                Submit
              </button>
            </DialogActions>
          </Dialog>
          {/* <Dialog open={open} onClose={handleClose}>
              {msg && <Alert severity="error">{msg}</Alert>}
              <DialogTitle>Fill the below details</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Email Address"
                  InputLabelProps={{
                    style: { color: "#45A69F", fontSize: "20px", fontWeight: "600", marginLeft: '0px', marginBottom: '30px', opacity: '50%'}
                  }}
                  type="email"
                  fullWidth
                  value={email}
                  variant="standard"
                  onChange={handleEmailChange}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Name"
                  InputLabelProps={{
                    style: { color: "#45A69F", fontSize: "20px", fontWeight: "600", position: 'absolute', left: '2px'}
                  }}
                  type="Name"
                  value={name}
                  fullWidth
                  variant="standard"
                  onChange={handleNameChange}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Phone"
                  InputLabelProps={{
                    style: { color: "#45A69F", fontSize: "20px", fontWeight: "600", position: 'absolute', left: '2px'}
                  }}
                  type="Phone"
                  value={phone}
                  fullWidth
                  variant="standard"
                  onChange={handlePhoneChange}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  value={company}
                  label="Business name"
                  InputLabelProps={{
                    style: { color: "#45A69F", fontSize: "20px", fontWeight: "600", position: 'absolute', left: '2px'}
                  }}
                  type="email"
                  fullWidth
                  variant="standard"
                  onChange={handleCompanyChange}
                />
                <InputLabel id="demo-simple-select-label" style={{ marginTop: "20px" }}>
                  Loan Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  label="Loan Type"
                  fullWidth
                  onChange={handleTypeChange}>
                  <MenuItem value={"Car"}>Car</MenuItem>
                  <MenuItem value={"Bike"}>Bike</MenuItem>
                  <MenuItem value={"Business"}>Business</MenuItem>
                  <MenuItem value={"House"}>House</MenuItem>
                </Select>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit}>Submit</Button>
              </DialogActions>
            </Dialog> */}
        </div>

        <div className="loans-poster-right">
          <img src={loans} alt="" />
        </div>

        <div className="head-div-1">
          <button className="heda123" onClick={() => setAssistance(true)}>
            {" "}
            Get Assistance!
          </button>
        </div>
        <GetAssistanceForm
          open={assistance}
          handleClose={() => {
            setAssistance(false);
          }}
          handleSubmit={handleAssistance}
          form={form}
          setForm={setForm}
        />
        {/* <div
        className="assistance"  style={{height:"150px",width:"90px",color:"white"
      }}>
        <p style={{ margin: "10px" }}><Button style={{color:"white"}} onClick={handleAssistance}>Get Assistance!</Button></p>
      </div> */}
        {/* <GetAssistanceForm open={open} handleClose={() => {setOpen(false)}} /> */}
      </div>
    </>
  );
}

export default Poster;
