import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Typography
} from "@mui/material";
import React, { useState } from "react";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    height: "auto",
    marginBottom: "50px",
    background: "rgba(255, 118, 102, 0.1)"
  },
  margin: "50px",
  display: "flex",
  width: "100%",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "90vh",
  marginBottom: "50px"
}));

const ButtonStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    height: "auto",
    marginBottom: "50px",
    background: "rgba(255, 118, 102, 0.1)"
  },
  margin: "50px",
  display: "flex",
  width: "100%",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  height: "90vh",
  marginBottom: "50px"
}));

function StepOne() {
  const [age, setAge] = useState("");
  return (
    <RootStyle>
      <Typography variant="h6">Select type of insurance</Typography>
      <FormControl sx={{ margin: "auto", width: "50vw" }}>
        <InputLabel id="demo-simple-select-label">Age</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label="Age"
          onChange={(e) => setAge(e.target.value)}>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
      <ButtonStyle>
        <Button>Previous</Button>
        <Button>Next</Button>
      </ButtonStyle>
    </RootStyle>
  );
}

export default StepOne;
