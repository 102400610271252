/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./ourservices.css";

const ServiceItem = [
  {
    index: 0,
    image: "/images/misc/asset344.svg",
    content: "Financial Independence",
    info: "Financial Independence is the state of having sufficient income generating assets regardless of your income level - where you can have enough wealth to live a life you desire, without having to work for regular income throughout your life!"
  },
  {
    index: 1,
    image: "/images/misc/asset1.svg",
    content: "Liquidity management",
    info: "Liquidity is where an individual possesses the ability to generate cash at short notice. It is not about how wealthy one is, but about how much of cash one can pull out as and when needed. A strategy that plans liquidity needs and a buffer for unexpected liquidity needs."
  },
  {
    index: 2,
    image: "/images/misc/Wealth_Protection.png",
    content: "Wealth Protection",
    info: "Wealth protection is investing in a variety of financial assets as well as insurance products to help you prepare for uncertainties and unexpected situations. An armour, it aids your family and help them live a healthy sustainable life even in your absence."
  }
];

export function ServiceCard({ val }) {
  console.log({ val });
  const [hover, setHover] = useState(false);
  const handleHover = () => setHover(true);
  const closeHover = () => setHover(false);
  return (
    <div className="service-card">
      <div className="service-card-front">
        <div className="service-img-box">
          <img className="service-img" src={val.image} alt="" />
        </div>

        <div className="box-content">{val.content}</div>
      </div>

      <div className="service-card-back">
        <div className="hover-title">
          <h2>{val.content}</h2>
        </div>
        <div className="hover-info">
          <p>{val.info}</p>
        </div>
      </div>
    </div>
  );
}

function MiddleLanding() {
  const state = ServiceItem;
  return (
    <div className="our-services-parent">
      <div className="ourservices" style={{ zIndex: "2" }}>
        <div className="service-tag">
          Your Key to Grow Money is
          <br /> Just one click away{" "}
        </div>
        <div className="service-sub-tag">Our 3 Pillars Towards your Financial Freedom</div>

        <div className="service-parent">
          {state.map((val, index) => (
            <ServiceCard val={val} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default MiddleLanding;
