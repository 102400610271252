import React from "react";
import Appbar from "../components/Appbar/Appbar";
import Learn from "../components/Education/Learn";
import Middle from "../components/Education/Middle";
import Poster from "../components/Education/Poster";
import Webinar from "../components/Education/Webinar";
import Footer from "../components/Footer/Footer";
import Headline from "../components/Headline/Headline";
import Navbar from "../components/Navbar/Navbar";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";

function Education() {
  return (
    <div style={{ background: "white" }}>
      <ScrollToTop />
      <Headline />
      <Navbar />
      <Poster />
      <Middle />
      <Webinar />
      <Learn />
      <Footer />
    </div>
  );
}

export default Education;
