/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React from "react";
import "./middle.css";

const InvestItem = [
  {
    image: "/images/misc/questions.svg",
    heading: "Simply answer a few questions.",
    description: "Your responses help us to understand your goals."
  },
  {
    image: "/images/misc/revenue.svg",
    heading: "Building a Portfolio for you",
    description: "Our W.R.A.P program creates a perfect portfolio"
  },
  {
    image: "/images/misc/investment.svg",
    heading: "Putting your money into work",
    description: "Visualize your investment stratergy better."
  }
];

export function InvestCard({ val }) {
  console.log(val);

  return (
    <div className="invest-card">
      <div className="invest-img-box">
        <img className="invest-img" src={val.image} alt="" />
      </div>

      <div className="near-content">
        <div className="box-heading">{val.heading}</div>
        <div id="box-description">{val.description}</div>
      </div>
    </div>
  );
}

function MiddleLanding() {
  const state = InvestItem;
  return (
    <div className="investment-parent">
      <div className="investment-div">
        <div className="invest-tag">
          Why <span>INVEST</span> with us{" "}
        </div>
        <div className="invest-sub-tag">Our Fundamentals are our Best Features</div>

        <div className="invest-parent">
          {state.map((val) => (
            <InvestCard val={val} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default MiddleLanding;
