import React from 'react'
import './riskfactor.css'
import axios from 'axios';

const token = 'b6ceeaf9-ee67-4b40-906e-97125eae5bff'

// const number = 1;
// const question = "What is the current stage of life?";
// const options = {
//     option1: "Single with dependent parents",
//     option2: "Single with dependent parents",
//     option3: "Single with dependent parents",
//     option4: "Single with dependent parents",
//     option5: "Single with dependent parents",
//     option6: "Single with dependent parents",
// }

function RiskFactorQuestion({pageNumber, question, setPageNumber, setOption, options}) {
  function handleSubmit(e) {
    e.preventDefault();
    console.log(options);
    const authToken = localStorage.getItem("authToken");
    axios.post(process.env.REACT_APP_API_BASE_URL + `/riskfactor/response/?authToken=${authToken}`, options).then((res) => {
      console.log("Hey krishna save me")
      console.log(res);
      setPageNumber(11);
    })
  }

  return (
    <div className='riskfactor-question-container'>
        {pageNumber === 1 ? <><div className='riskfactor-heading'>RISK FACTOR</div>
        <div className='riskfactor-subheading'>Answer the following set of questions to know your Risk Factor</div></> : null}
        <div className='riskfactor-question'>{pageNumber}. {question.questions}</div>
        <div className='riskfactor-options'>
        {[...Array(6).keys()].map((optionNumber) => {
            if(question['op' + (optionNumber + 1)]) {
            return(<div className={options['option' + pageNumber] == optionNumber + 1 ? "selected-option" : "option"} onClick={() => {setOption(optionNumber + 1)}}>{String.fromCharCode(optionNumber + 97)}. {question['op' + (optionNumber + 1)]}</div>)
            }
        })}
        </div>
        <div className='riskfactor-button-row'>
          {pageNumber > 0 ? <button onClick={() => {setPageNumber(pageNumber -1)}}>Previous</button> : null}
          {(pageNumber < 10 && options['option' + (pageNumber)]) ? <button onClick={() => setPageNumber(pageNumber + 1)}>Next</button> : null}
          {pageNumber === 10 ? <button onClick={handleSubmit}>Submit</button> : null}
        </div>
    </div>
  )
}

export default RiskFactorQuestion