import React from "react";
import Footer from "../components/Footer/Footer";

import Headline from "../components/Headline/Headline";
import Navbar from "../components/Navbar/Navbar";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import "./css/privacypolicy.css";

function About() {
  const particlesInit = async (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  return (
    <div className="privacy-policy">
      <ScrollToTop />
      <Headline />
      <Navbar />

      <div className="div-aboutt">
        <div className="about-box">
          <h1>How financially prepared do you feel for retirement?</h1>

          <p>
            We look at all aspects of your financial life and help you personally to achieve the
            financial goals you envision. In the process, you generate wealth for your future,
            become financially independent, and retire rich.
          </p>
        </div>

        <div className="heikaaaaa">
          <h1>Celebrating 6 years of putting Clients first </h1>
          <p>Ensuring they are able to meet their financial goals for the next 60 years</p>
          <div className="jkhtyr">
            <div className="slab12">
              <img src="./images/misc/financial.svg" alt="" />
              <div className="kljyu">
                <h1>One to one financial advice</h1>
                <p>Based on your goals and needs</p>
              </div>
            </div>
            <div className="slab12">
              <img src="./images/misc/two.png" alt="" />
              <div className="kljyu">
                <h1>Personalized recommendations</h1>
                <p>For a diversified portfolio</p>
              </div>
            </div>
            <div className="slab12">
              <img src="./images/misc/three.png" alt="" />
              <div className="kljyu">
                <h1>Regular meetings</h1>
                <p>To review your goals, progress and investments</p>
              </div>
            </div>
            <div className="slab12">
              <img src="./images/misc/investment accesss.svg" alt="" />
              <div className="kljyu">
                <h1>Anytime access to your investments</h1>
                <p>And digital tools to keep you on track </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default About;
