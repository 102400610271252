import { styled } from "@mui/material";
import React, { useState } from "react";
import RiskFactorModals from "./RiskFactorModals/RiskFactorModals";
import FinancialPlanningModals from "./FinancialPlanningModals/FinancialPlanningModals";
import "../Insurance/ins.css";
import stress from "../images/predict_ur_future.png";

import asset from "../images/Frame5.png";
import discover from "../images/Frame58.png";
import expense from "../images/Frame98.png";
import familycalculartor from "../images/fam-calculator.svg";
import dreamBox from "../images/dreambox.png";
import risk from "../images/Group 2270.png";
import "../Mutual_Funds/mf.css";
import "../Mutual_Funds/FundzbazarModal.css";
import { Link } from "react-router-dom";
// import FundzbazarModal from "./FundzbazarModal";
import CancelIcon from "@mui/icons-material/Cancel";
import DreamBox from "./DreamBox";
import { LoginModal } from "../../pages/Login";
// import dreamBoxIcon from "../images/dreamboxIcon.svg"

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "0px",
    paddingBottom: "0px"
  },
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  marginBottom: "20vh"
}));

const Cards = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center"
    // margin: "20px"
  },
  width: "100vw",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  margin: "50px",
  marginBottom: "-10px"
}));

const Card = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    width: "270px"
  },
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  background: `linear-gradient(180.03deg, #F8F8F8 0.03%, #FFFFFF 0.04%, #FFFFFF 60.38%, rgba(216, 216, 216, 0.28) 138.52%)`,
  boxShadow: "4px 4px 15px 2px #E3E4E4",
  borderRadius: "12px",
  width: "370px",
  height: "285px",
  padding: "20px",
  paddingBottom: "40px",
  marginTop: "20px",
  // paddingBottom: "20px",
  objectFit: "contain"
}));

const Modals = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "100%"
  },
  width: "95%",
  height: "85vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "auto"
}));

function Explore({ handleClick }) {
  const [open, setOpen] = useState(false);
  const [openFP, setOpenFP] = useState(false);
  const [editmodal, setEditmodal] = useState(false);
  const [login, setLogin] = useState(false);

  const FundzbazarModal = () => {
    return (
      <>
        <Modals>
          <div className="modal__container" style={{ width: "90%" }}>
            <div className="modalTitle">
              {/* <h2></h2> */}
              <CancelIcon onClick={() => setEditmodal(false)} />
            </div>
            <iframe
              src="https://www.fundzbazar.com/signin/"
              width={1300}
              height={505}
              frameBorder="0"
              allowFullScreen
              target="_parent"></iframe>
          </div>
        </Modals>
      </>
    );
  };

  const isLoggedIn = localStorage.getItem("authToken");

  const showLoginPopup = () => {
    setLogin(true);
  };

  return (
    <>
      {editmodal ? (
        FundzbazarModal()
      ) : (
        <>
          <RootStyle>
            <RiskFactorModals
              open={open}
              handleClose={() => {
                setOpen(false);
              }}
            />
            <FinancialPlanningModals
              open={openFP}
              handleClose={() => {
                setOpenFP(false);
              }}
            />
            <LoginModal showLoginModal={login} setShowLoginModal={setLogin} />

            <div className="explore-card-parent">
              <div className="explore-card">
                <img src={risk} />

                <div className="exploretitle">
                  Know your
                  <br></br> Risk Factor
                </div>
                {isLoggedIn ? (
                  <button
                    className="explorebutton"
                    onClick={() => {
                      setOpen(true);
                    }}>
                    Get Started
                  </button>
                ) : (
                  <button className="explorebutton yellow" onClick={showLoginPopup}>
                    Login To Explore
                  </button>
                )}
              </div>

              <div className="explore-card">
                <img src={stress} />

                <div className="exploretitle">
                  Predict your
                  <br></br>
                  Financial Future
                </div>

                {isLoggedIn ? (
                  <button
                    className="explorebutton"
                    onClick={() => {
                      setOpenFP(true);
                    }}>
                    Explore More
                  </button>
                ) : (
                  <button className="explorebutton yellow" onClick={showLoginPopup}>
                    Login To Explore
                  </button>
                )}
              </div>
              {/* <a href="https://www.fundzbazar.com/signin"> */}
              <div className="explore-card">
                <img src={asset} />

                <div className="exploretitle">Asset Recorder</div>
                {isLoggedIn ? (
                  <Link className="explorebutton" to="/mutualfund/assetreconciliation">
                    Explore More
                  </Link>
                ) : (
                  <button className="explorebutton yellow" onClick={showLoginPopup}>
                    Login To Explore
                  </button>
                )}
              </div>
              {/* </a> */}
            </div>
            <div className="explore-card-parent">
              <div className="explore-card">
                <img src={dreamBox} />

                <div className="exploretitle">DreamBox</div>

                {/* <Link to="mutual_funds/explore" className="explorebutton yellow" >Explore More</Link> */}
                <DreamBox />
              </div>

              <div className="explore-card">
                <img src={expense} />

                <div className="exploretitle">Expense Calculator</div>
                {isLoggedIn ? (
                  <Link className="explorebutton" to="/user-profile">
                    Explore More
                  </Link>
                ) : (
                  <button className="explorebutton yellow" onClick={showLoginPopup}>
                    Login To Explore
                  </button>
                )}
              </div>

              <div className="explore-card">
                <img src={familycalculartor} />

                <div className="exploretitle">Financial Calculator</div>
                <Link to="mutualfunds/calculators" className="explorebutton yellow">
                  Explore More
                </Link>
              </div>
            </div>
          </RootStyle>
        </>
      )}
    </>
  );
}

export default Explore;
