/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./Navbar.css";
import { Redirect } from "react-router";
import { Button } from "./Button";
import { LoginModal } from "../../pages/Login";

function Navbar() {
  const [click, setClick] = useState(false);
  const [profile, setProfile] = useState({
    name: null,
    photo: null
  });

  const [showLoginModal, setShowLoginModal] = useState(false);

  const openModal = () => {
    setShowLoginModal((prev) => !prev);
  };

  const [button, setButton] = useState(true);
  const [navbar, setNavbar] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const [dropdown, setDropdown] = useState(false);

  const [showList, setShowList] = useState(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
    const authToken = localStorage.getItem("authToken");
    axios
      .get(process.env.REACT_APP_API_BASE_URL + `/get/userinfo?authToken=${authToken}`)
      .then((res) => {
        setProfile({
          name: res.data.name,
          photo: res.data.profile_url
        });
      });
    // console.log(state)
    // if(state.personalDetails.fname != "")
    // {
    //   setName(state.personalDetails.fname);
    // }
  }, []);

  window.addEventListener("resize", showButton);

  const changeBackground = () => {
    if (window.scrollY >= 47) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);

  return (
    <nav className={navbar ? "navbar active" : "navbar"}>
      <div className="navbar-container">
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img src="/images/misc/logo.svg" />

          {/* <div className="logo-img"></div> */}
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          <img src={click ? "./images/icons/close.png" : "./images/icons/menu.png"} />
        </div>

        {localStorage.getItem("authToken") ? (
          <div className="topright-menu-mobile">
            <div
              className="profile-btn"
              onClick={() => {
                window.location = "/user-profile";
              }}>
              <img src={profile.photo ? profile.photo : "/images/icons/profile.svg"} />
              <div>{profile.name ? profile.name.split(" ")[0] : "User"}</div>
            </div>
            <div
              className="logout-btn"
              onClick={() => {
                localStorage.removeItem("authToken");
                window.location.reload();
              }}>
              <img src="/images/icons/login.png" />
            </div>
          </div>
        ) : (
          <button className="nav-links-mobile" onClick={openModal}>
            Login
          </button>
        )}
        {/* {localStorage.getItem('authToken') ? <div><div onClick={() => {window.location = '/user-profile'}}>Profile</div><div onClick={() => {localStorage.removeItem('authToken'); window.location.reload()}}>Logout</div></div> : <button className="login-btn-main"  onClick={openModal}>
              Login
              <img src="/images/icons/login.png" />{" "}
            </button>} */}

        <LoginModal showLoginModal={showLoginModal} setShowLoginModal={setShowLoginModal} />

        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link to="/mutualfunds" className="nav-links" onClick={closeMobileMenu}>
              Mutual Funds
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/insurance" className="nav-links" onClick={closeMobileMenu}>
              Insurance
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/loans" className="nav-links" onClick={closeMobileMenu}>
              Loans
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/education" className="nav-links" onClick={closeMobileMenu}>
              Education
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/media" className="nav-links" onClick={closeMobileMenu}>
              Media
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/blogs" className="nav-links" onClick={closeMobileMenu}>
              Blogs
            </Link>
          </li>
        </ul>

        <div className="button-div">
          {localStorage.getItem("authToken") ? (
            <div className="topright-menu">
              <div
                className="profile-btn"
                onClick={() => {
                  window.location = "/user-profile";
                }}>
                <img src={profile.photo ? profile.photo : "/images/icons/profile.svg"} />{" "}
                {profile.name ? profile.name.split(" ")[0] : "User"}
              </div>
              <div
                className="logout-btn"
                onClick={() => {
                  localStorage.removeItem("authToken");
                  window.location.reload();
                }}>
                <img src="/images/icons/login.png" />
              </div>
            </div>
          ) : (
            <button className="login-btn-main" onClick={openModal}>
              Login
              <img src="/images/icons/login.png" />{" "}
            </button>
          )}

          <LoginModal showLoginModal={showLoginModal} setShowLoginModal={setShowLoginModal} />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
