import React, {useState, useEffect} from 'react'
import { useForm } from "react-hook-form";
import axios from "axios";
import Headline from '../../Headline/Headline';
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';
import './css/keypeople.css'

function KeyPeople() {
    const {
      register,
        handleSubmit,
        formState: { errors },
      } = useForm();
      
    const [details , setDetails ]  = useState(
      {
          family_doctor: '',
          family_doctor_c1: '',
          family_doctor_c2: '',
          CA: '',
          CA_c1: '',
          CA_c2: '',
          advocate: '',
          advocate_c1: '',
          advocate_c2: '',
          advisor: '',
          advisor_c1: '',
          advisor_c2: '',
      }
    )

    const today = new Date();

    useEffect(async () => {
        const authToken = localStorage.getItem("authToken");
        await axios
          .get(process.env.REACT_APP_API_BASE_URL + `/asset-recorder/keyp/?authToken=${authToken}`)
          .then((res) => {
            console.log("Key People : Data fetched");
            console.log(res.data);
            if (res && res.data && res.data[0]) {
              const d = res.data[0];     
              console.log(d.CA)
              setDetails({
                family_doctor: d.family_doctor || "",
                family_doctor_c1: d.family_doctor_c1 || "",
                family_doctor_c2: d.family_doctor_c2 || "",
                CA: d.CA || "",
                CA_c1: d.CA_c1 || "",
                CA_c2: d.CA_c2 || "",
                advocate: d.advocate || "",
                advocate_c1: d.advocate_c1 || "",
                advocate_c2: d.advocate_c2 || "",
                advisor: d.advisor || "",
                advisor_c1: d.advisor_c1 || "",
                advisor_c2: d.advisor_c2 || "",
              });
            }
          })
          .catch(() => console.log("error occurred"));
    }, []) 
  
    function handleSub(e) {
        e.preventDefault();
        const authToken = localStorage.getItem("authToken");
        axios
          .post(process.env.REACT_APP_API_BASE_URL + `/asset-recorder/keyp/?authToken=${authToken}`,details)
          .then((res) => {
            console.log(details);
            console.log(res);
            // setPage(1);
          })
          .catch((err) => {
            console.log(err);
          });
      }
  
    // const [editName , setEditName] = useState(false);
    // const [editMobile, setEditMobile] = useState(false);
    // const [editEmail, setEditEmail] = useState(false);
    // const [editLocation, setEditLocation] = useState(false);
    // const [editBirth, setEditBirth] = useState(false);
    // const [editBloodGroup, setEditBloodGroup] = useState(false);
    // const [editTelephone, setEditTelephone] = useState(false);
    // const [editAddress, setEditAddress] = useState(false);
    // const [seePassword, setSeePassword] = useState(false);

    return (
    <>
    <Headline />
    <Navbar />
        <div className='parent-form-prooofilee'>

           <div className="abcdew">
        <div className="customer-edit-profile">
         
        <div className="reactform">
        <form onSubmit={handleSubmit(() => {
            //console.log(details)
            })}>
            <div className="input-entry">
            <h1>Family Doctor</h1>
                    
            <label className="label-cust-edit-profile">Name</label>
            <input
                name="family_doctor"
                className="input-text"
                type="text"
                // readOnly={!editName}
                {...register("family_doctor", {
                  required: "This is a required field",
                  minLength: { value: 3, message: "Too Short!" },
                  maxLength: { value: 50, message: "Too Long!" },
                })}
                value={details.family_doctor}
                onChange={(e) => {
                  setDetails({ ...details, family_doctor: e.target.value })
                }}
            />
            {/* <div className="change-button" onClick={() => {setEditName(!editName)}}>{editName ? "save" : "edit"}</div> */}
            {errors.family_doctor && <div className="error-message">*{errors.family_doctor.message}</div>}
           
            </div>

            <div className="input-entry">
            <label className="label-cust-edit-profile"> Contact Number </label>
            <div className="mobnumber-input-box">
                
                <input
                name="family_doctor_c1"
                className="input-text input-number12"
                type="text"
                placeholder=""
                value={details.family_doctor_c1}
                onChange={(e) => {
                  setDetails({ ...details, family_doctor_c1: e.target.value })
                }}
                // readOnly={!editMobile}
                // {...register("family_doctor_c1", {
                // required: "This is a required field",
                // minLength: { value: 3, message: "Too Short!" },
                // maxLength: { value: 50, message: "Too Long!" },
                // })}
                />
            </div>
            {errors.family_doctor_c1 && <div className="error-message">*{errors.family_doctor_c1.message}</div>}
            {/* <div className="change-button" onClick={() => {setEditMobile(!editMobile)}}>{editMobile ? "save" : "edit"}</div> */}
           
            </div>


            <div className="input-entry">
            <label className="label-cust-edit-profile"> Alternate Contact Number </label>
            <input
                name="family_doctor_c2"
                className="input-text"
                type="text"
                placeholder=""
                value={details.family_doctor_c2}
                // readOnly={!editLocation}
                {...register("family_doctor_c2", {
                required: "This is a required field",
                minLength: { value: 3, message: "Too Short!" },
                maxLength: { value: 50, message: "Too Long!" },
                })}
                onChange={(e) => {
                  setDetails({ ...details, family_doctor_c2: e.target.value })
                }}
                // onChange={(e) => {setDetails({...details , current_location : e.target.value})}}
            />
            {/* <div className="change-button" onClick={() => {setEditLocation(!editLocation)}}>{editLocation ? "save" : "edit"}</div> */}
            {errors.family_doctor_c2 && <div className="error-message">*{errors.family_doctor_c2.message}</div>}
           
            </div>

            
            <div className="input-entry">
            <h1>Advocate</h1>
                    
            <label className="label-cust-edit-profile">Name</label>
            <input
                name="advocate"
                className="input-text"
                type="text"
                // readOnly={!editName}
                {...register("advocate", {
                  required: "This is a required field",
                  minLength: { value: 3, message: "Too Short!" },
                  maxLength: { value: 50, message: "Too Long!" },
                })}
                value={details.advocate}
                onChange={(e) => {
                  setDetails({ ...details, advocate: e.target.value })
                }}
            />
            {/* <div className="change-button" onClick={() => {setEditName(!editName)}}>{editName ? "save" : "edit"}</div> */}
            {errors.advocate && <div className="error-message">*{errors.advocate.message}</div>}
           
            </div>

            <div className="input-entry">
            <label className="label-cust-edit-profile"> Contact Number </label>
            <div className="mobnumber-input-box">
                
                <input
                name="advocate_c1"
                className="input-text input-number12"
                type="text"
                placeholder=""
                value={details.advocate_c1}
                onChange={(e) => {
                  setDetails({ ...details, advocate_c1: e.target.value })
                }}
                // readOnly={!editMobile}
                // {...register("advocate_c1", {
                // required: "This is a required field",
                // minLength: { value: 3, message: "Too Short!" },
                // maxLength: { value: 50, message: "Too Long!" },
                // })}
                />
            </div>
            {errors.advocate_c1 && <div className="error-message">*{errors.advocate_c1.message}</div>}
            {/* <div className="change-button" onClick={() => {setEditMobile(!editMobile)}}>{editMobile ? "save" : "edit"}</div> */}
           
            </div>

            <div className="input-entry">
            <label className="label-cust-edit-profile"> Alternate Contact Number </label>
            <input
                name="advocate_c2"
                className="input-text"
                type="text"
                placeholder=""
                value={details.advocate_c2}
                // readOnly={!editLocation}
                {...register("advocate_c2", {
                required: "This is a required field",
                minLength: { value: 3, message: "Too Short!" },
                maxLength: { value: 50, message: "Too Long!" },
                })}
                onChange={(e) => {
                  setDetails({ ...details, advocate_c2: e.target.value })
                }}
                // onChange={(e) => {setDetails({...details , current_location : e.target.value})}}
            />
            {/* <div className="change-button" onClick={() => {setEditLocation(!editLocation)}}>{editLocation ? "save" : "edit"}</div> */}
            {errors.advocate_c2 && <div className="error-message">*{errors.advocate_c2.message}</div>}
           
            </div>
            

            




            

            <button className="form-btn-edit-profile" onClick={handleSub}>
                save 
            </button>
        </form>
        </div>
        </div>

        <div className='second-div-flexx'>

            <div className="input-entry">
            <h1>Chartered Accountant </h1>
            <label className="label-cust-edit-profile">Name</label>
            <input
                name="CA"
                className="input-text"
                type="text"
                placeholder=""
                value={details.CA}
                // readOnly={!editName}
                {...register("CA", {
                required: "This is a required field",
                minLength: { value: 3, message: "Too Short!" },
                maxLength: { value: 50, message: "Too Long!" },
                })}
                onChange={(e) => {setDetails({...details , CA : e.target.value})}}
            />
            {/* <div className="change-button" onClick={() => {setEditName(!editName)}}>{editName ? "save" : "edit"}</div> */}
            {errors.Ca && <div className="error-message">*{errors.CA.message}</div>}
           
            </div>


            <div className="input-entry">
            <label className="label-cust-edit-profile"> Contact Number</label>
            <input
                name="CA_c1"
                className="input-text"
                type="text"
                placeholder=""
                value={details.CA_c1}
                // readOnly={!editAddress}
                {...register("CA_c1", {
                required: "This is a required field",
                minLength: { value: 3, message: "Too Short!" },
                maxLength: { value: 50, message: "Too Long!" },
                })}
                onChange={(e) => {setDetails({...details , CA_c1 : e.target.value})}}
            />
            {/* <div className="change-button" onClick={() => {setEditAddress(!editAddress)}}>{editAddress ? "save" : "edit"}</div> */}
            {errors.CA_c1 && <div className="error-message">*{errors.CA_c1.message}</div>}
           
            </div>

            <div className="input-entry">
            <label className="label-cust-edit-profile"> Alternate Contact Number</label>
            <div className="mobnumber-input-box">
                
                <input
                name="CA_c2"
                className="input-text input-number1"
                type="tel"
                placeholder=""
                value={details.CA_c2}
                // readOnly={!editTelephone}
                {...register("CA_c2", {
                required: "This is a required field",
                minLength: { value: 3, message: "Too Short!" },
                maxLength: { value: 50, message: "Too Long!" },
                })}
                onChange={(e) => {
                  setDetails({ ...details, CA_c2: e.target.value })
                }}
                />
            </div>
            {errors.CA_c2 && <div className="error-message">*{errors.CA_c2.message}</div>}
            {/* <div className="change-button" onClick={() => {setEditTelephone(!editTelephone)}}>{editTelephone ? "save" : "edit"}</div> */}
           
              </div>
              
            <div className="input-entry">
            <h1>Financial Advisor</h1>
                    
            <label className="label-cust-edit-profile">Name</label>
            <input
                name="advisor"
                className="input-text"
                type="text"
                // readOnly={!editName}
                {...register("advisor", {
                  required: "This is a required field",
                  minLength: { value: 3, message: "Too Short!" },
                  maxLength: { value: 50, message: "Too Long!" },
                })}
                value={details.advisor}
                onChange={(e) => {
                  setDetails({ ...details, advisor: e.target.value })
                }}
            />
            {/* <div className="change-button" onClick={() => {setEditName(!editName)}}>{editName ? "save" : "edit"}</div> */}
            {errors.advisor && <div className="error-message">*{errors.advisor.message}</div>}
           
            </div>

            <div className="input-entry">
            <label className="label-cust-edit-profile"> Contact Number </label>
            <div className="mobnumber-input-box">
                
                <input
                name="advisor_c1"
                className="input-text input-number12"
                type="text"
                placeholder=""
                value={details.advisor_c1}
                onChange={(e) => {
                  setDetails({ ...details, advisor_c1: e.target.value })
                }}
                // readOnly={!editMobile}
                // {...register("advisor_c1", {
                // required: "This is a required field",
                // minLength: { value: 3, message: "Too Short!" },
                // maxLength: { value: 50, message: "Too Long!" },
                // })}
                />
            </div>
            {errors.advisor_c1 && <div className="error-message">*{errors.advisor_c1.message}</div>}
            {/* <div className="change-button" onClick={() => {setEditMobile(!editMobile)}}>{editMobile ? "save" : "edit"}</div> */}
           
            </div>

            <div className="input-entry">
            <label className="label-cust-edit-profile"> Alternate Contact Number </label>
            <input
                name="advisor_c2"
                className="input-text"
                type="text"
                placeholder=""
                value={details.advisor_c2}
                // readOnly={!editLocation}
                {...register("advisor_c2", {
                required: "This is a required field",
                minLength: { value: 3, message: "Too Short!" },
                maxLength: { value: 50, message: "Too Long!" },
                })}
                onChange={(e) => {
                  setDetails({ ...details, advisor_c2: e.target.value })
                }}
                // onChange={(e) => {setDetails({...details , current_location : e.target.value})}}
            />
            {/* <div className="change-button" onClick={() => {setEditLocation(!editLocation)}}>{editLocation ? "save" : "edit"}</div> */}
            {errors.advisor_c2 && <div className="error-message">*{errors.advisor_c2.message}</div>}
           
            </div>
            

            
        </div>
        </div>
        </div>
        <Footer />
    </>
    )
}

export default KeyPeople;
