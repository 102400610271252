import { styled } from "@mui/material";
import React from "react";
// import img from "../images/green-ellipse.svg";
import img from "../images/Ellipse9.svg";
import TickList from "../Insurance/TickList";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    height: "100%",
    marginBottom: "0px",
    paddingTop: "20px",
    paddingBottom: "40px",
    background: "rgba(82, 204, 195, 0.1)"
    // background: "#52CCC31A"
  },
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  // backgroundImage: `url(${img})`,
  backgroundImage: `url(${img})`,
  height: "40vh",
  marginBottom: "50px",
  backgroundSize: "cover",
  marginTop: "30px"
}));

const MiddleLeft = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: "auto"
  },
  flex: "0.4",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "center",
  height: "60%",
  margin: "20px"
}));

const MiddleCenter = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    width: "100%",
    marginLeft: "20%"
  },
  flex: "0.3"
}));

const MiddleRight = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    marginLeft: "20%",
    width: "100%"
  },
  flex: "0.3"
}));
function MiddleInsurance() {
  return (
    <RootStyle>
      <MiddleLeft>
        <h2 className="borrow-heading">
          Protect your Wealth with <span style={{ color: "#FF6553" }}>Confidence.</span>
        </h2>
      </MiddleLeft>
      <MiddleCenter>
        <TickList
          heading="Easy and Hassle-Free"
          list={[
            "Digitally Enabled, No Paperwork",
            "Affordable Premiums",
            "Plan Based on your Needs",
            "Save on Taxes"
          ]}
        />
      </MiddleCenter>
      <MiddleRight>
        <TickList
          heading="Helpful and Friendly"
          list={[
            "Customized Products and Services",
            "Quick Claim Process and Support",
            "Customer First-Approach",
            "Wide Branch Network Across India"
          ]}
        />
      </MiddleRight>
    </RootStyle>
  );
}

export default MiddleInsurance;
