import React from "react";
import "./ticklist.css";

function TickList({ heading, list }) {
  return (
    <div className="tick-list">
      <div className="tick-list-heading">
        <h4>{heading}</h4>
      </div>
      {list.map((item) => {
        return (
          <div className="tick-list-item" key={item}>
            <img src="/images/icons/tick.svg" alt=''/>
            <div>{item}</div>
          </div>
        );
      })}
    </div>
  );
}

export default TickList;
