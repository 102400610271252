import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Rings } from "react-loader-spinner";
import "./pagination.css";
import { Grid, Card } from "@material-ui/core/";
// import icon from "/images/icons/chevron.svg";
// import authorPic from "/images/icons/chevron.svg";
// import pic from "/images/icons/chevron.svg";
import dateFormatter from "./DateFormatter";

function Blog(blog) {
  return (
    <Link className="blog-link" to={"/blog/" + blog.slug}>
      {/* <div className="blog-box1">
        <div className="blog-box-upper">
          <img src={blog.pic} alt="blog-pic" className="image" />
          <div id="bmi">
            <img
              src="/images/icons/bookmark.svg"
              alt="bookmark-icon"
              className={blog.bookmarked ? "bookmark-icon" : "bookmark-icon-inactive"}
            />
          </div>
          <div className="heading">
            <img src={blog.authorPic} alt="author-pic" className="author-pic" />
            <div className="info">
              <d id="username">{blog.user}</d>
            </div>

          </div>
          <div className="footing">
            <a href="#" className="author">
              <img src="/images/icons/user.svg" /> {blog.author}
            </a>
            <a href="#" className="tags">
              <img src="/images/icons/label.svg" /> {blog.tags}
            </a>
            <a href="#" className="time">
              <img src="/images/icons/clock.svg" /> {blog.time}
            </a>
          </div>
        </div>
        <div className="blog-box-lower">
          <div className="info-bar">
            <div className="info-bar-category">{blog.category}</div>
            <div className="info-bar-date">{blog.date}</div>
          </div>
          <div className="content">
            <h1>{blog.heading}</h1>
            <p>{blog.content}</p>
          </div>
        </div>
      </div> */}
      <div className="blogcard">
        {blog.pic ? <img src={blog.pic} alt="blog-pic" /> : null}

        <div className="heading-box-blog">
          <div className="blogcard-heading">{blog.heading}</div>
        </div>

        <div className="blogcard-date">{blog.date}</div>
      </div>
    </Link>
  );
}

let arrayForHoldingBlogs = [];

const Blogs = ({ blogs, loading, error }) => {
  if (loading) {
    return (
      <div id="load-ani">
        <Rings color="#FFBD06" height={100} width={100} />
      </div>
    );
  }
  if (error) {
    return (
      <div id="load-ani">
        Oops! There seems to be an error.
        <br />
        Please try again.
      </div>
    );
  }

  var blogHolder = [];
  blogs.map((blog) =>
    blogHolder.push(
      <Blog
        id={blog.id}
        user="silverduck204"
        authorPic="images/misc/generic-user.jpg"
        date={blog.category_id.title}
        likes="24"
        pic={blog.featured_image_url}
        heading={blog.title}
        content={blog.short_desc}
        author={blog.created_by}
        tags="Horror,timepass"
        time="2 min"
        category={blog.category_id[-1]}
        bookmarked={true}
        slug={blog.slug}
      />
    )
  );

  return <div className="blogs-rows">{blogHolder}</div>;
};

function Pagination() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [blogsToShow, setBlogsToShow] = useState([]);
  const [next, setNext] = useState(0);
  const [blogsPerPage, setBlogsPerPage] = useState(9);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_BASE_URL + "/blogs").then((res) => setBlogs([...res.data]));
    updateBlogsPerPage();
  }, []);

  useEffect(() => {
    console.log("Blogs: ", blogs);
  });

  const loopWithSlice = (start, end) => {
    const slicedBlogs = blogs.slice(start, end);
    // console.log(slicedBlogs);
    // arrayForHoldingBlogs = [...arrayForHoldingBlogs, ...slicedBlogs];
    // setBlogsToShow([...arrayForHoldingBlogs]);
    console.log('Loop with slice', next);
  };

  useEffect(() => {
    if (next === 0 && blogs.length > 0) {
      setBlogsToShow(blogs)
      // loopWithSlice(0, blogsPerPage);
      // setNext(next + blogsPerPage);
      // console.log("Initial", next);
    }
  }, [blogs]);

  window.addEventListener("resize", updateBlogsPerPage);
  function updateBlogsPerPage() {
    if (window.innerWidth < 700) {
      setBlogsPerPage(10);
    } else if (window.innerWidth < 1000) {
      setBlogsPerPage(15);
    } else {
      setBlogsPerPage(20);
    }
  }

  const handleShowMoreBlogs = () => {
    loopWithSlice(next, next + blogsPerPage);
    setNext(next + blogsPerPage);
    console.log('Showing more', next)
  };

  return (
    <div className="pagination1">
      <h1>STOCKTICK Blogs</h1>
      <Blogs blogs={blogsToShow} loading={false} error={false} />
      <div
        id={next >= blogs.length || loading || error ? "load-inactive" : "load-up"}
        onClick={handleShowMoreBlogs}>
        <a id="load">Load more</a>
      </div>
    </div>
  );
}

export default Pagination;
