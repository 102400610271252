import { styled } from "@mui/material";
import React from "react";
import tata from "../images/tata.svg";

const RootStyle = styled("div")(({ theme }) => ({
  //   [theme.breakpoints.down("md")]: {
  //     width: "100vw"
  //   },
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "start",
  width: "100%",
  background: "#FBFBFB",
  border: "1px solid #DDDDDD",
  padding: "20px"
}));

const CardStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100vw"
  },
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  marginLeft: "20px",
  width: "100%"
}));

const TextStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    flex: "0.8"
  },
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "start",
  margin: "20px"
}));

const InterestStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  marginLeft: "20px"
}));

function MFListCard(props) {
  console.log(props);
  const data = props.item
  return (
    <div className="mf-list257496">
      <div className="mf-list-card">
        <img src={data.icon}></img>
        
        <div className="kjhyj12">
        <div className="fund-name215">{data.fund_name}</div>
        </div>
        
        <div className="interst-mf-fund">
          
            <span>1 Day</span> <br /> {data.oneday}%
          
        </div>
        <div className="interst-mf-fund">
          
            <span>1 Year</span> <br /> {data.oneyear}%
          
        </div>
        <div className="interst-mf-fund">
          
            <span>3 Year</span> <br /> {data.threeyear}%
          
        </div>
      </div>
    </div>
  );
}

export default MFListCard;
