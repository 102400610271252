import React, { useState, useEffect } from "react";
import axios from "axios";
import Headline from '../../Headline/Headline';
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';

function Loan() {
  // const [editBankName, setEditBankName] = useState(false);
  // const [editBranchAddress, setEditBranchAddress] = useState(false);
  // const [editAccountHolderName, setEditAccountHolderName] = useState(false);
  // const [editAccountNumber, setEditAccountNumber] = useState(false);
  // const [editAccountType, setEditAccountType] = useState(false);
  // const [editNomineeName, setEditNomineeName] = useState(false);
  // const [editRelationship, setEditRelationship] = useState(false);
  // const [editAllocationPercent, setEditAllocationPercent] = useState(false);
  // const [editNomineeName2, setEditNomineeName2] = useState(false);
  // const [editRelationship2, setEditRelationship2] = useState(false);
  // const [editAllocationPercent2, setEditAllocationPercent2] = useState(false);

  const [multiInput, setMultiInput] = useState([{ 
    id: "",
    bank_name: "",
    loan_account_number: "",
    loan_amount: "",
    asset_name: "",
    asset_type: "",
    loan_start_date: "",
    loan_tenure: "",
    emi_amount: "",
    roi: "",
    ecs_bank: "",
    insurance_protect_loan: "",
    current_outstanding: "",
  }]);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const inputList = [...multiInput];
    inputList[index][name] = value;
    setMultiInput(inputList);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (e, index) => {
    e.preventDefault();
  
    const id = multiInput[index].id;
    
    if (!id) {
      const inputList = [...multiInput];
      inputList.splice(index, 1);
      setMultiInput(inputList);
    }
    else {
      const authToken = localStorage.getItem("authToken");
      axios
        .post(process.env.REACT_APP_API_BASE_URL + `/asset-recorder/data-delete/?authToken=${authToken}&deleteId=${id}`)
        .then(() => {
          const inputList = [...multiInput];
          inputList.splice(index, 1);
          setMultiInput(inputList);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // handle click event of the Add button
  const handleAddClick = (e) => {
    e.preventDefault();
    setMultiInput([...multiInput, {     id: "",
    bank_name: "",
    loan_account_number: "",
    loan_amount: "",
    asset_name: "",
    asset_type: "",
    loan_start_date: "",
    loan_tenure: "",
    emi_amount: "",
    roi: "",
    ecs_bank: "",
    insurance_protect_loan: "",
    current_outstanding: "",}]);
  };

  function handleSubmit(e, index) {
    e.preventDefault();
    const data = multiInput[index];
    console.log(data);

    const authToken = localStorage.getItem("authToken");
    axios
      .post(process.env.REACT_APP_API_BASE_URL + `/asset-recorder/data/?authToken=${authToken}&asset=loan&editId=${data.id}`,data)
      .then((res) => {
        console.log("ser1", res.data.ser1);  
        const inputList = [...multiInput];
        inputList[index].id = res.data.ser1.id;
        setMultiInput(inputList);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(async () => {
        const authToken = localStorage.getItem("authToken");
        await axios
          .get(process.env.REACT_APP_API_BASE_URL + `/asset-recorder/data/?authToken=${authToken}&asset=loan`)
          .then((res) => {
            console.log("FD : Data fetched");
            console.log(res.data);
            if (res && res.data && res.data[0]) {
              var newData = res.data.map(d => {
                const newD = {
                  id:d.id|| "",
                  bank_name:d.bank_name|| "",
                  loan_account_number:d.loan_account_number|| "",
                  loan_amount:d.loan_amount|| "",
                  asset_name:d.asset_name|| "",
                  asset_type:d.asset_type|| "",
                  loan_start_date:d.loan_start_date|| "",
                  loan_tenure:d.loan_tenure|| "",
                  emi_amount:d.emi_amount|| "",
                  roi:d.roi|| "",
                  ecs_bank:d.ecs_bank|| "",
                  insurance_protect_loan:d.insurance_protect_loan|| "",
                  current_outstanding:d.current_outstanding|| "",
                };
                return newD;
              });
              console.log(newData);
              setMultiInput(newData);
            }
          })
          .catch(() => console.log("error occurred"));
      }, []);

  return (
    <>
    <Headline />
    <Navbar />
    <div className="parent-key">
      <div className="parent-key-card">
        <h1> Loan Details 
          <button className="add-card-btn" onClick={handleAddClick}>
              Add
          </button>
        </h1>
        <div className="parent-card-true">
          {multiInput.map((x, i) => {
            return (
              <div className="parent-card-true1" key={i}>
                <div className="div-flex">
                <div className="div-flex-one">

                <div className="input-entry1">
                  <label className="label-cust-edit-profile">Bank Name   </label>
                  <input
                    type="text"
                    className="input-text"
                    name="bank_name"
                    value={multiInput[i].bank_name}
                    // readOnly={!editBranchAddress}
                    onChange={(e) => handleInputChange(e, i)}
                  />

                  {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditBranchAddress(!editBranchAddress);
                    }}>
                    {editBranchAddress ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                </div>
                 
                <div className="input-entry1">
                  <label className="label-cust-edit-profile">Loan Account Number   </label>
                  <input
                    type="text"
                    className="input-text"
                    name="loan_account_number"
                    value={multiInput[i].loan_account_number}
                    // readOnly={!editAccountNumber}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                  {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAccountNumber(!editAccountNumber);
                    }}>
                    {editAccountNumber ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                </div>
              

                <div className="input-entry1">
                  <label className="label-cust-edit-profile">Loan Amount  </label>
                  <input
                    type="number"
                    className="input-text"
                    name="loan_amount"
                    value={multiInput[i].loan_amount}
                    // readOnly={!editAccountNumber}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                  {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAccountNumber(!editAccountNumber);
                    }}>
                    {editAccountNumber ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                </div>

                <div className="input-entry1">
                  <label className="label-cust-edit-profile">Asset Name  </label>
                  <input
                    type="text"
                    className="input-text"
                    name="asset_name"
                    value={multiInput[i].asset_name}
                    // readOnly={!editAccountHolderName}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                  {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAccountHolderName(!editAccountHolderName);
                    }}>
                    {editAccountHolderName ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                </div>


                <div className="input-entry1">
                  <label className="label-cust-edit-profile">Asset Type </label>
                  <input
                    type="text"
                    className="input-text"
                    name="asset_type"
                    value={multiInput[i].asset_type}
                    // readOnly={!editAccountHolderName}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                  {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAccountHolderName(!editAccountHolderName);
                    }}>
                    {editAccountHolderName ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                </div>


                <div className="input-entry1">
                  <label className="label-cust-edit-profile">Loan Start Date </label>
                  <input
                    type="date"
                    className="input-text"
                    name="loan_start_date"
                    value={multiInput[i].loan_start_date}
                    // readOnly={!editNomineeName}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                   {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditNomineeName(!editNomineeName);
                    }}>
                    {editNomineeName ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                  </div>
                </div>
                <div className="div-flex-two">


                <div className="input-entry1">
                  <label className="label-cust-edit-profile">Loan Tenure  </label>
                  <input
                    type="number"
                    className="input-text"
                    name="loan_tenure"
                    value={multiInput[i].loan_tenure}
                    // readOnly={!editRelationship2}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                  {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditRelationship2(!editRelationship2);
                    }}>
                    {editRelationship2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                </div>
                 

                <div className="input-entry1">
                  <label className="label-cust-edit-profile">EMI Amount   </label>
                  <input
                    type="number"
                    className="input-text"
                    name="emi_amount"
                    value={multiInput[i].emi_amount}
                    // readOnly={!editRelationship2}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                  {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditRelationship2(!editRelationship2);
                    }}>
                    {editRelationship2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                </div>


                <div className="input-entry1">
                  <label className="label-cust-edit-profile">Rate of Interest    </label>
                  <input
                    type="number"
                    className="input-text input-percent"
                    name="roi"
                    value={multiInput[i].roi}
                    // readOnly={!editRelationship2}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                      <span className="input-postfix">%</span>
                  {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditRelationship2(!editRelationship2);
                    }}>
                    {editRelationship2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                </div>


                <div className="input-entry1">
                  <label className="label-cust-edit-profile">ECS Bank  </label>
                  <input
                    type="number"
                    className="input-text"
                    name="ecs_bank"
                    value={multiInput[i].ecs_bank}
                    // readOnly={!editRelationship2}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                  {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditRelationship2(!editRelationship2);
                    }}>
                    {editRelationship2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                </div>

                



                <div className="input-entry1">
                  <label className="label-cust-edit-profile">Insurance Protect Loan</label>
                  <input
                    type="text"
                    className="input-text"
                    name="insurance_protect_loan"
                    value={multiInput[i].insurance_protect_loan}
                    // readOnly={!editAllocationPercent2}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                  {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAllocationPercent2(!editAllocationPercent2);
                    }}>
                    {editAllocationPercent2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                </div>

                <div className="input-entry1">
                  <label className="label-cust-edit-profile">Current Outstanding </label>
                  <input
                    type="text"
                    className="input-text"
                    name="current_outstanding"
                    value={multiInput[i].current_outstanding}
                    // readOnly={!editAllocationPercent2}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                  {/* <div
                    className="change-button1"
                    onClick={() => {
                      setEditAllocationPercent2(!editAllocationPercent2);
                    }}>
                    {editAllocationPercent2 ? (
                      <button> save</button>
                    ) : (
                      <button>
                        {" "}
                        <img src="/images/icons/pencil.svg" alt="" /> edit
                      </button>
                    )}
                  </div> */}
                </div></div>
                </div>
                <div className="div-flex">
                  <button className="div-form-button" onClick={(e) => handleSubmit(e, i)} > save </button>
                </div>
                {i >= 0 && (
                  <button className="delete-card-btn" onClick={(e) => handleRemoveClick(e, i)}>
                    <img src="/images/icons/delete.svg" alt="" /> Delete
                  </button>
                )}
              </div>

              
            );
          })}
        </div>
      </div>
      </div>
      <Footer />
    </>
  );
}

export default Loan;
