import React from "react";
import { useEffect } from 'react';
import { Button, Stack } from "@mui/material";
import "./financial.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Chart from "react-apexcharts";

function FinancialInstruments ({page, setPage}) {
    const [stats, setStats] = React.useState({
        fd: 6000,
        mf: 6000,
        epf_nps: 6000,
        shares: 6000
    });
    const [series, setSeries] = React.useState([{data:[stats.fd,stats.mf,stats.epf_nps,stats.shares]}]);
    const [option, setOption] = React.useState({chart : {id: "asset-allocation", type: "bar"},
    plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false
      },
    xaxis: {
        categories: ['FD', 'MF', 'EPF / PPF / NPS', 'Shares'],
    }});

    useEffect(()=>{
        setSeries([stats.fd, stats.mf, stats.epf_nps, stats.shares]);
    },[stats]);

    async function handleSubmit(e){
        const token = localStorage.getItem("authToken")
        const url = process.env.REACT_APP_API_BASE_URL + "/finantialplanning/finnantial-Q4?authToken="+token
        await fetch(url, {
            method : "POST",
            headers : {
                // "authToken" : token,
                "Content-Type" : "application/json"
            },
            body  : JSON.stringify({
                md : stats.fd,
                mf : stats.mf,
                nps : stats.epf_nps,
                shares : stats.shares
            }) 
        })
        .then((res)=> res.json())
        .then(data => {
            console.log("response from adding data ", data)
            if(data.message){
                setPage(page+1);
            }
            else {
                alert("Something went wrong while submitting your data, please try again")
            }
        })
        .catch( err => {
            console.log(err)
            alert("Something went wrong, please check your internet connection and try again")
        })
        e.preventDefault();
    }

    useEffect( async () => {
        const token = localStorage.getItem("authToken");
        console.log("token", token)
        const url = process.env.REACT_APP_API_BASE_URL + "/finantialplanning/finnantial-Q4?authToken=" + token
        await fetch(url, {
            method : "GET",
            // headers : {
            //     "authToken" : token
            // }
        })
        .then(res => res.json())
        .then(data => {
            console.log(data)
            if(data && data.md){
                setStats({
                    fd : data.md,
                    mf : data.mf,
                    epf_nps : data.nps,
                    shares : data.shares
                })
            }
        })
        .catch(err => {
            console.log(err)
        })
    }, [])

    return(
        <div className="profile-info-container">
            <div className="profile-head">WELCOME TO FINANCIAL PLANNING</div>
            <div className="profile-subheading">{"Financial Instruments"}</div>
            <Chart 
                type = 'bar'
                options = {option}
                series = {[{data: series}]}
            >
            </Chart>
            <div style={{display: "flex", flexDirection: "row", minWidth:"200px"}}>
                <div style={{display: "flex", flexDirection:"column", marginRight:"-20px"}}>
                    <label style={{ color:"grey", backgroundColor:"white"}}>FD</label>
                    <label style={{ color:"grey", backgroundColor:"white"}}>MF</label>
                    <label style={{ color:"grey", backgroundColor:"white"}}>EPF / PPF / NPS</label>
                    <label style={{ color:"grey", backgroundColor:"white"}}>Shares</label>
                </div>
                <Stack className="abcde123" alignItems="space-between" justifyContent="space-between" direction="column" xs={3} style={{margin:"20px"}}>
                    <input
                        type="number"
                        placeholder="0"
                        value={stats.fd}
                        style={{margin:"20 40", padding: "20 40"}}
                        onChange={(e) => {
                            setStats({ ...stats, fd: parseInt(e.target.value) });
                        }}></input>
                    <input
                        type="number"
                        placeholder="0"
                        value={stats.mf}
                        onChange={(e) => {
                            setStats({ ...stats, mf: parseInt(e.target.value) });
                        }}
                    />
                    <input
                        type="number"
                        placeholder="0"
                        value={stats.epf_nps}
                        onChange={(e) => {
                            setStats({ ...stats, epf_nps: parseInt(e.target.value) });
                        }}
                    />
                    <input
                        type="number"
                        placeholder="0"
                        value={stats.shares}
                        onChange={(e) => {
                            setStats({ ...stats, shares: parseInt(e.target.value) });
                        }}
                    />
                </ Stack>
            </div>
            <div className="profile-button-row">
            <button className="hedfa" variant="contained" style={{margin:"20px",padding:"20 40"}} onClick={() => setPage(page-1)}  startIcon={
                    <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>}>Previous   </button>
                <button className="jkrd" variant="outlined" style={{margin:"20px",padding:"20 40"}} onClick={() => setPage(page+1)}>SKIP</button>
                <button className="hedfa" variant="contained" style={{margin:"20px",padding:"20 40"}} onClick={handleSubmit} endIcon={
                    <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>}>NEXT   </button>
            </div>
        </div>
    )
}

export default FinancialInstruments;