import React from 'react';
import Footer from '../components/Footer/Footer';
import Headline from '../components/Headline/Headline';
import Calculator from '../components/Mutual_Funds/Calculator';
import Navbar from '../components/Navbar/Navbar';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';

function MF_Calculator(){
    return(
        <>
        <ScrollToTop />
        <Headline />
        <Navbar />
        <Calculator />
        <Footer />
        </>
    );
}

export default MF_Calculator;