/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown";
// import heart from "../images/icons/chevron.svg";
// import share from "../images/icons/chevron.svg";
// import eye from "../images/icons/chevron.svg";
// import chevronDown from "../images/icons/chevron.svg";
// import authorPic from "../images/icons/chevron.svg";
// import pic from "/images/icons/chevron.svg";
import dateFormatter from "../components/Blogs/DateFormatter";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import "./css/blog.css";
import { TagSharp } from "@mui/icons-material";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import Headline from "../components/Headline/Headline";
import draftToHtml from "draftjs-to-html";

const recent = [
  "A Comprehensive Personal Finance Checklist for 20-Year-Olds"
];
const related = [
  "A Comprehensive Personal Finance Checklist for 20-Year-Olds"
];

const replies = [
  {
    user: "santaslabs",
    content: "Lol! Says the one who was literally on four legs last night at the bar XD"
  },
  { user: "notanimposter", content: "This is cringe worthy" },
  {
    user: "goldenduck04",
    content: "It's very sad how people misinterpret things for their own benefit"
  }
];

var blogID = "";
var slug = "Test3-blog-2-4438";

function Replies() {
  if (replies.length === 0)
    return (
      <div id="replies-list">
        <div>No replies to show</div>
      </div>
    );

  var replyHolder = [];
  replies.map((reply) =>
    replyHolder.push(
      <div id="reply-box">
        <div id="reply-username">{reply.user}</div>
        <div id="reply">{reply.content}</div>
      </div>
    )
  );

  return <div id="replies-list">{replyHolder}</div>;
}

function PostList({ title, posts }) {
  return (
    <div className="posts-list-container">
      <div className="posts-list-title">{title}</div>
      {/* <hr /> */}
      <div className="posts-list">
        {posts.map((item) => {
          return (
            <div key={item} className="post-container">
              {/* <img src='https://images.unsplash.com/photo-1579353977828-2a4eab540b9a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80' /> */}
              <div>
                {item}

              </div>
              <span>9 July, 2023</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function Blog() {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [author, setAuthor] = useState("");
  const [date, setDate] = useState("02/19/2022");
  const [time, setTime] = useState("15:03");
  const [featuredImage, setFeaturedImage] = useState(
    "https://techcrunch.com/wp-content/uploads/2021/08/pokemon-legends-arceus.jpeg"
  );
  const [tags, setTags] = useState([]);
  const [blogUrl, setBlogUrl] = useState("");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    slug = window.location.href.split("/").slice(-1)[0];
  }, []);

  useEffect(() => {
    // function axe() {
    //   axios({
    //     method: "post",
    //     url: "https://dnul4ngbfk.execute-api.ap-south-1.amazonaws.com/Prod/read1Blog",
    //     data: { id: blogID }
    //   })
    //     .then((response) => {
    //       setTitle(response.data.message.Item.Title.S);
    //       setContent(response.data.message.Item.Content.S);
    //       setDate(response.data.message.Item.Date.S);
    //       setAuthor(response.data.message.Item.Author.S);
    //       setFeaturedImage(response.data.message.Item.Feature_Img);
    //     })
    //     .catch((err) => console.log(err));
    // }
    // axe();
    axios.get(process.env.REACT_APP_API_BASE_URL + "/blogs/blog/" + slug).then((res) => {
      console.log(res.data);
      // var arr = Object.keys(res.data.keyword_ids)
      // .map(function(key) {
      //     return obj[key];
      // })
      // console.log(arr)

      setTitle(res.data.title);
      setContent(res.data.body);
      setDate(res.data.created_at.slice(0, 10));
      setTime(res.data.created_at.slice(11, 16));
      setAuthor(res.data.created_by);
      setFeaturedImage(res.data.featured_image_url);
      setTags(res.data.keyword_ids);
      setBlogUrl("https://stocktick.in/blog/" + slug)
      // console.log(Object.keys(res.data.keyword_ids))
      // setTags([...arr]);
    });
    console.log(tags);
  }, []);

  const [showReplies, setShowReplies] = useState(false);
  return (
    <div id="page">
      <ScrollToTop />
      <Headline />
      <Navbar />
      {/* <div id="author-info">
        <img id="profilepic" src="/images/misc/generic-user.jpg" alt='Profile Pic' />
        <div id="info">
          <div id="username-blog">silverduck04</div>
          <div id="badge-info">Silver Badge Owner</div>
        </div>
      </div> */}
      <div id="body">
        {/* <div id="sidebar">
          <div id="author">
            Author
            <hr />
          </div>
          <div>
            <div id="author-username">{author}</div>
            <div id="author-bio">
              I am a jobless, hopeless, senseless individual who's still struggling to figure out
              why he's alive :)
            </div>
            <hr />
          </div>
          <div id="icons">
            <img id="like" src="/images/icons/heart.svg" />
            <img id="share" src="/images/icons/share.svg" />
          </div>
        </div> */}
        <div id="content">
          <div id="head">
            <div id="title">{title}</div>
            <div id="head-info">
              <div id="time-date">

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="cald"
                  fill="currentColor"
                  height="18"
                  width="18">
                  <path d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"></path>
                </svg>
                {dateFormatter(date)},<pre> </pre>
                <div id="time-1"> {time} IST </div>
              </div>

              {/* <div id="time-1"> {time} IST </div> */}
              {/* <div id="views">
                {" "}
                <img id="eye" src="/images/icons/heart.svg" /> 204
              </div> */}
            </div>
          </div>
          <div id="image">
            <img id="featured-image" src={featuredImage} />
            <div className="img-courtesy">Image Courtesy: Stocktick</div>
          </div>
          <div className="social-panel">

            <a href={`https://api.whatsapp.com/send?text=${encodeURIComponent(`${title} ${blogUrl}`)}`} target="_blank" rel="noreferrer">
              <img src="/images/social/whatsapp.svg" alt="Share on WhatsApp" />
            </a>
            <a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(blogUrl)}&text=${encodeURIComponent(title)}`} target="_blank" rel="noreferrer">
              <img src="/images/social/facebook.svg" alt="Share on Facebook" />
            </a>

            <a href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(blogUrl)}&summary=${encodeURIComponent(title)}`} target="_blank" rel="noreferrer">
              <img src="/images/social/linkedin.svg" alt="Share on LinkedIn" />
            </a>

            <a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(blogUrl)}&text=${encodeURIComponent(title)}`} target="_blank" rel="noreferrer">
              <img src="/images/social/twitter.svg" alt="Share on Twitter" />
            </a>


          </div>

          <div id="blog-body">
            <ReactMarkdown className="react-markdown-test" >{content}</ReactMarkdown>
          </div>
          <div>
            <div id="comments">Tags</div>
            <div className="tags-list-sel">
              {tags.map((item) => {
                return <div key={item.id} style={{ margin: 5, padding: 5 }} >{item.title}</div>;
              })}
            </div>
          </div>
          <div id="comments-box">
            {/* <div id="comments">
              Comments <hr />{" "}
            </div> */}
            {/* <textarea id="comment-input" placeholder="Write your comments"></textarea>
            <div id="post-btn">
              <div id="post">Post</div>
            </div> */}
            <div id="replies">
              {/* <img id="userpic" src="/images/misc/generic-user.jpg" /> */}
              <div>
                {/* <div id="profile-name">silverduck04</div>
                <div id="comment">
                  Alcohol based exposures through inadvertently consuming hand sanitizers have been
                  observed to produce more negative side effects for children than non-alcohol
                  based.
                </div> */}
                {/* <div
                  id="reply-dropdown"
                  onClick={() => {
                    setShowReplies(!showReplies);
                  }}>
                  Replies{" "}
                  <img
                    id={showReplies ? "chevron-up" : "chevron-down"}
                    src="/images/icons/chevron.svg"
                  />
                </div>
                <div id="reply-btn">Click here to reply</div>
                {showReplies ? <Replies /> : null} */}
              </div>
            </div>
          </div>
        </div>
        <div className="blog-side-menu">
          <PostList title="Recent Posts" posts={recent} />
          <PostList title="Related Posts" posts={related} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Blog;
