import React, {useState, useEffect} from 'react'
import { Button, Stack } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';


const DownloadPDF = ({page, setPage, handleClose}) => {
    const [loading, setLoading ] = useState(true)
    const [result, setResult] = useState("");

    const handleDownload= async ()=>{
        const token = localStorage.getItem("authToken")
        const url = process.env.REACT_APP_API_BASE_URL + "/finantialplanning/pdf?authToken="+token;
        const resp = await fetch(url).then(res => res.json()).catch(err => {
            console.log(err);
            alert("Something went wrong while fetching the results, please try again");
            // setLoading(false)
        })
        console.log(resp)
        if(resp.message === "Report Generated and Uploaded"){
            setResult(resp.url)
            window.open(resp.url, '_blank', 'noopener,noreferrer');
        }
    }

    return (
        <>
        <h1 className='resulittttt' style={{margin:"auto"}}>Here are your results</h1>
         <div className="profile-button-row">
                <button className="hedfa" variant="outlined" style={{margin:"20px",padding:"20 40"}} onClick={handleDownload}>Download</button>
                <button className='jkrd' variant="contained" style={{margin:"20px",padding:"20 40"}} onClick={(e) => {setPage(1); handleClose(); }}>Close</button>
        </div>
        </>
    )
}

export default DownloadPDF;