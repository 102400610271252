import { styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import up from "../images/trending-up.png";
import "../Insurance/ins.css";
import axios from "axios";
import "./mf.css";
import DiscoverMutualFundModal from "./DiscoverMutualFundModal";

const lock = "./images/misc/lock.svg";

function Explore({ handleClick }) {
  const fetchOptions = async () => {
    const data = await axios.get(
      process.env.REACT_APP_API_BASE_URL +
      "/discover-mutual-funds/catg?authToken=b6ceeaf9-ee67-4b40-906e-97125eae5bff"
    );
    console.log(data.data);
    setResponse(data.data);
  };

  const [response, setResponse] = useState([]);
  const [open, setOpen] = useState(false);
  const [catgId, setCatgId] = useState(0);
  const [desc, setDesc] = useState('');

  useEffect(() => {
    fetchOptions();
  }, []);
  return (
    <div id="discover_mf">
      <h1>W.R.A.P Based MF Portfolio</h1>

      <DiscoverMutualFundModal
        open={open}
        catgId={catgId}
        desc={desc}
        handleClose={() => {
          setOpen(false);
        }}
      />

      <div className="discover-mf-cards">
        {response
          .slice()
          .splice(0, 3)
          .map((crd) => (
            <div key={1232}>
              <div>
                {crd.lock_status == "UNLOCKED" ? (
                  <button
                    className="discover-mf-card"
                    onClick={() => {
                      setCatgId(crd.id);
                      setDesc(crd.description)
                      setOpen(true);
                    }}>
                    <img src={crd.icon} alt="icon" />
                    <h3>{crd.catg_name}</h3>
                  </button>
                ) : (
                  <button className="discover-mf-card">
                    <img src={lock} alt="icon" />
                  </button>
                )}
              </div>
            </div>
          ))}
      </div>
      <div className="discover-mf-cards">
        {response
          .slice()
          .splice(-3)
          .map((crd) => (
            <div key={45678}>
              <div>
                {crd.lock_status == "UNLOCKED" ? (
                  <button
                    className="discover-mf-card"
                    onClick={() => {
                      setCatgId(crd.id);
                      setDesc(crd.description)
                      setOpen(true);
                    }}>
                    <img src={crd.icon} alt="icon" />
                    <h3>{crd.catg_name}</h3>
                  </button>
                ) : (
                  <button className="discover-mf-card">
                    <img src={lock} alt="icon" />
                  </button>
                )}
              </div>
              <br />
            </div>
          ))}
      </div>
    </div>
  );
}

export default Explore;
