import React, {useState, useEffect} from 'react'
import { Dialog,DialogTitle,DialogContent, DialogContentText,Button,TextField,DialogActions } from '@mui/material';
import './assistance.css';


const assistanceImg = '/images/misc/assistance-img.svg';
function GetAssistanceForm({open, handleClose, handleSubmit, form ,setForm}) {

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className="assistance-modal">

        <div className="assistance-sub-box">
        <h1>Get Assistance</h1>
        <img src={assistanceImg} alt="" />
        </div>
        

        <button className="riskfactor-close-button" onClick={handleClose}><img src="./images/icons/close.svg" alt="" /> </button>
        <DialogContent>
          
          <input type="text" placeholder='Enter your Name' className='assistance-input-name' value={form.name}
            onChange={(e) => setForm({...form, name: e.target.value})}/>
          {/* <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={form.name}
            onChange={(e) => setForm({...form, name: e.target.value})}
          /> */}

          <input type="email" placeholder='Enter Email Address' className='assistance-input-name' value={form.email}
            onChange={(e) => setForm({...form, email: e.target.value})}/>
          
          <input type="tel" placeholder='Enter Phone Number' className='assistance-input-name' value={form.phone}
            onChange={(e) => setForm({...form, phone: e.target.value})}/>
          {/* <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={form.email}
            onChange={(e) => setForm({...form, email: e.target.value})}
          /> */}
          
          <textarea name="message" placeholder='Enter your message' className='assistance-textarea' cols="30" rows="10" value={form.query} onChange={(e) => setForm({...form, query: e.target.value})}></textarea>
          {/* <TextField
          id="message"
          label="Message"
          multiline
          fullWidth
          rows={4}
          value={form.query}
          onChange={(e) => setForm({...form, query: e.target.value})}
        /> */}
        </DialogContent>
        <DialogActions>
          <button className='cancel-assistance' onClick={handleClose}>Cancel</button>
          <button className='submit-assistance' onClick={handleSubmit}>Submit</button>
        </DialogActions>

        </div>
      </Dialog>
  )
}

export default GetAssistanceForm