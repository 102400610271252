import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  styled,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MFListCard from "./MFListCard";
import './mflist.css';
import axios from "axios";

const RootStyle = styled("div")(({ theme }) => ({
  marginTop: "100px",
  height: "100vh"
}));

const RowStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    flexDirection: "column"
  },
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "70px"
}));

const FilterStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "0.3",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "left",
  marginLeft: "10%",
}));

const FilterRowStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "50%",
  minWidth: "200px"
}));

const CategoryStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "start",
  marginTop: "40px"
}));

const ListStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "0.7",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center"
}));



function MFList() {



 
  const [response, setResponse] = useState([])
  const [cate_id, setCategoryId] = useState(1);
  const [resp_details, setRespDetails] = useState([]);
  const token = localStorage.getItem("authToken")
  const fetchOptions = async () => {
    const data = await axios.get(process.env.REACT_APP_API_BASE_URL + `/discover-mutual-funds/explore-catg`);
    console.log(data.data);
    setResponse(data.data);
  };

  const handleChange = (id) => {
    console.log("this is the new id " , id)
    setCategoryId(id);
  }
  useEffect( async () => {
    const data  = await axios.get(process.env.REACT_APP_API_BASE_URL + `/discover-mutual-funds/explore-funds/?catg_id=${cate_id}`)
    console.log("the", data.data);
    setRespDetails(data.data)
  }, [cate_id])

  useEffect(()=>{
    fetchOptions();
  }, [])

 

  return (
    <div className="parent-mf-list">
    
     <h1>Explore Mutual Fund </h1>
      
      
          
        


            <div className="mf-list-2569">    
              <div className="mf-float-left">
             
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Wealth Protect"
                name="radio-buttons-group">
                {response.map((item, idx)=>{ 
                    if(item.lock_status == "UNLOCKED")
                  return <FormControlLabel key={idx+1} className="unlock-mf-list" value={item.id}
                   control={<Radio/>} label={item.fund_catg_name} onClick={()=>handleChange(item.id)}  />
                  else {
                    item.catg_name = "LOCKED"
                    return <FormControlLabel key={idx+1} className="locked-mf-list" value={item.id} control={<Radio/>} label={item.catg_name} onClick={()=>handleChange(item.id)} disabled />
                  }
                })}
                
              </RadioGroup>
              </div>
            
          
        <div className="float-right-mf">

        <ListStyle>
          {
            resp_details.map((item, index) => {
              return <MFListCard key={index+1} item={item} />
            })
          }
        </ListStyle>

        </div>
        
        </div>
     
    
    </div>
  );
}

export default MFList;
