/* eslint-disable react/prop-types */
import { AccountCircle, CheckCircleOutlined } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  Button,
  Dialog,
  InputAdornment,
  MenuItem,
  Select,
  styled,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import React from "react";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "90%",
    height: "100vh",
    alignItems: "center",
    marginBottom: "50px",
    minWidth: 0
  },
  display: "flex",
  width: "50%",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
  padding: "10px",
  marginBottom: "50px",
  backgroundColor: "white",
  minWidth: "500px"
}));

const ButtonStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "90%",
    height: "100vh",
    alignItems: "center",
    marginBottom: "50px"
  },
  display: "flex",
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  marginBottom: "50px",
  backgroundColor: "white"
}));

const RowStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "90%",
    alignItems: "center",
    marginBottom: "30px",
    marginLeft: "10px"
  },
  display: "flex",
  width: "50%",
  flexDirection: "row",
  justifyContent: "left",
  alignItems: "center",
  marginBottom: "50px"
}));

const SwitchStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "90%",
    alignItems: "center",
    marginBottom: "20px",
    marginLeft: "10px"
  },
  display: "flex",
  width: "50%",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "50px"
}));

const SuccessStyle = styled("div")(() => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "90vh",
  marginBottom: "50px",
  backgroundColor: "white",
  minWidth: "500px"
}));

function CarStepThree({
  success,
  own,
  pucExp,
  puc,
  polExp,
  currInsurer,
  handleCurrInsurer,
  handlePolExp,
  handlePuc,
  handlePucExp,
  handleOwn,
  handleSubmit,
  open,
  handleClose,
  handlePrev
}) {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"500px"}>
      {success ? (
        <SuccessStyle>
          <h1>SUCCESS!!</h1>
          <CheckCircleOutlined style={{ color: "#00C314", fontSize: "40px" }} />
          <p>Our team will contact you shortly.</p>
        </SuccessStyle>
      ) : (
        <RootStyle>
          <div style={{ flex: "0.1", marginTop: '40px' }}>
            <h1 style={{ color: "#45A69F" }}>CAR INSURANCE</h1>
          </div>
          <RowStyle>
            <TextField
              id="input-with-icon-textfield"
              label="Current Insurer"
              InputLabelProps={{
                style: { color: "#45A69F", fontSize: "20px", fontWeight: "600", marginLeft: '2px'  }
              }}
              value={currInsurer}
              onChange={handleCurrInsurer}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {/* <AccountCircle /> */}
                  </InputAdornment>
                )
              }}
              variant="standard"
              sx={{ marginBottom: "15px" }}
            />
          </RowStyle>
          <RowStyle>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Policy expiry date"
                value={polExp}
                onChange={handlePolExp}
                style={{ border: "none" }}
                //   onChange={handleDOB}
                renderInput={(params) => (
                  <TextField
                    id="icon-textfield"
                    variant="standard"
                    {...params}
                    InputLabelProps={{
                      style: { color: "#45A69F", fontSize: "20px", fontWeight: "600", marginLeft: '2px'  }
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </RowStyle>
          <SwitchStyle>
            <h4 style={{ color: "#45A69F" }}>Do you have PUC?</h4>
            <Switch checked={puc} onChange={handlePuc} style={{ color: "#45A69F" }}></Switch>
          </SwitchStyle>
          <RowStyle>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="PUC expiry date"
                value={pucExp}
                style={{ border: "none" }}
                onChange={handlePucExp}
                renderInput={(params) => (
                  <TextField
                    id="icon-textfield"
                    variant="standard"
                    {...params}
                    InputLabelProps={{
                      style: { color: "#45A69F", fontSize: "20px", fontWeight: "600", marginLeft: '2px'  }
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </RowStyle>
          <SwitchStyle>
            <h4 style={{ color: "#45A69F" }}>Has ownership changed in last one year?</h4>
            <Switch checked={own} onChange={handleOwn} style={{ color: "#45A69F" }}></Switch>
          </SwitchStyle>
          <ButtonStyle>
            <Button
              sx={{ background: "#45A69F", color: "white", width: "200px", margin: "10px" , "&:hover": {backgroundColor: "#45A69F", } }}
              onClick={handlePrev}>
              Prev <img src="/images/icons/login.png" />{" "}
            </Button>
            <Button
              sx={{ background: "#45A69F", color: "white", width: "200px", margin: "10px" , "&:hover": {backgroundColor: "#45A69F", } }}
              onClick={handleSubmit}>
              Submit <img src="/images/icons/login.png" />{" "}
            </Button>
          </ButtonStyle>
        </RootStyle>
      )}
    </Dialog>
  );
}

export default CarStepThree;
