import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  styled,
  Typography
} from "@mui/material";
import axios from "axios";
import "./webinar.css";
import React, { useEffect, useState } from "react";
import WebinarRegisterationModal from "./WebinarRegisterationModal";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    marginTop: "20px"
  },
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "50px"
}));

const WebinarStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    margin: "20px",
    height: "auto"
  },
  display: "flex",
  flexDirection: "row",
  width: "100%",
  background: "#F1F8FF",
  justifyContent: "space-around",
  alignItems: "center",
  marginTop: "50px",
  height: "60vh"
}));

// const ChildEducation = ({page, setPage}) => {
// }
const WebinarCard = (props) => {
  console.log(props.data.webinar_redirect_url, props.data.hosted_by);

  // const selfRegister = async (webinar_id) => {
  //   console.log({ webinar_id });
  //   const sendRequest = async (webinar_id) => {
  //     const body = {
  //       webinar_id
  //     };
  //     const authToken = localStorage.getItem('authToken')
  //     const data = await axios.post(process.env.REACT_APP_API_BASE_URL + `subscribe/webinar/?authToken=${authToken}`, body);
  //     return data;
  //   };
  //   sendRequest(webinar_id)
  //     .then((data) => {
  //       console.log(data);
  //       if (data.status === 200) {
  //         alert("Registered successfully.");
  //       }
  //     })
  //     .catch((e) => alert(e.response.data.message));
  // };

  function openModal(e) {
    props.setWebinarIdSelected({
      id: props.data.id,
      selfHosted: props.data.hosted_by,
      redirectUrl: props.data.webinar_redirect_url
    });
    if (props.data.hosted_by == "self") {
      props.openFromParent(e);
    } else {
      location.replace(props.data.webinar_redirect_url);
    }
  }
  return (
    <>
      <div className="desktop-webinar">
        <div className="webinar-card1">
          <div className="webinar-image">
            <img src={props.data.image_url} alt="" />
          </div>

          <div className="webinar-title">{props.data.title}</div>
          <div className="webinar-description">{props.data.short_desc}</div>

          <div className="butttttonnnnn">
            {props.data.hosted_by === "self" ? (
              <button className="webinar-card-btn1" onClick={(e) => openModal(e)}>
                Register Now
              </button>
            ) : (
              <button className="webinar-card-btn1" onClick={(e) => openModal(e)}>
                Register Now
              </button>
              //
            )}
          </div>
        </div>
      </div>

      <div className="mobile-webinar">
        <div className="webinar-card">
          <div className="webinar-left">
            <h1>{props.data.title}</h1>
            <p>{props.data.short_desc}</p>

            {props.data.hosted_by === "self" ? (
              <button className="webinar-card-btn" onClick={(e) => openModal(e)}>
                Register Now
              </button>
            ) : (
              <button className="webinar-card-btn" onClick={(e) => openModal(e)}>
                Register Now
              </button>
            )}
          </div>
          <div className="webinar-right">
            <img src={props.data.image_url} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

function Webinar() {
  const [webinars, setWebinars] = useState([]);
  useEffect(() => {
    const sendRequest = async () => {
      const data = await axios.get(process.env.REACT_APP_API_BASE_URL + "/get/webinars");
      console.log(data);
      return data;
    };
    sendRequest().then((data) => setWebinars(data.data));
  }, []);

  const modalData = {
    title: "My Title From Parent",
    body: ["Apple", "Ipple", "Opple", "Upple", "Epple"]
  };
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [webinarIdSelected, setWebinarIdSelected] = useState({
    id: 0,
    selfHosted: "self",
    redirectUrl: ""
  });

  function openFromParent(event) {
    setIsOpen(true);
  }

  function handleCloseModal(event, data) {
    console.log(event, data);
    setIsOpen(false);
  }

  function handleAfterOpen(event, data) {
    console.log(event, data);
  }

  const selfRegister = async (webinar_id, isSelfHosted, redirectUrl) => {
    console.log("Testing1234");
    // setWebinarIdSelected(webinar_id)
    // const authToken = localStorage.getItem("authToken");
    // const body = {
    //   webinar_id
    // };
    // axios
    //   .post(process.env.REACT_APP_API_BASE_URL + `/subscribe/webinar/?authToken=${authToken}`, body)
    //   .then((res) => {
    //     console.log(res);
    //     if (res.status === 200) {
    //       if (isSelfHosted) {
    //         alert("Registered successfully.");

    //       } else {
    //         // Redirect to another page
    //         alert("Registered successfully! Redirecting to site......");
    //          location.replace(redirectUrl)
    //       }

    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err.response.status)
    //     if (err.response.status === 400) {
    //       if(!isSelfHosted){
    //         location.replace(redirectUrl)
    //       }
    //       alert(err.response.data.message)
    //     }
    //     console.log(err);
    //   });
  };

  return (
    <RootStyle>
      <h1 className="webinar-heading1">Enrol in Webinars</h1>
      <WebinarRegisterationModal
        open={modalIsOpen}
        handleClose={handleCloseModal}
        webinarIdSelected={webinarIdSelected}
      />
      <div className="parent-parent-loan-card">
        {webinars.map((data) => {
          return (
            <WebinarCard
              data={data}
              modalIsOpen={modalIsOpen}
              openFromParent={openFromParent}
              setWebinarIdSelected={setWebinarIdSelected}
              key={data.title}
            />
          );
        })}
      </div>
    </RootStyle>
  );
}

export default Webinar;
