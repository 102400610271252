import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "@mui/material/Modal";
import "./discovermodal.css";
import lockImg from "./../images/lock_image.jpeg";
import { Link } from "react-router-dom";

function DiscoverMutualFundModal({ open, catgId, handleClose, desc }) {
  const fetchDetails = async () => {
    const authToken = localStorage.getItem("authToken");

    const data = await axios.get(
      process.env.REACT_APP_API_BASE_URL +
      `/discover-mutual-funds/funds/?authToken=${authToken}&catg_id=` +
      catgId
    );
    console.log(data.data);
    setResponse(data.data.slice(0, 3));
  };

  function handleSubmit(e, redirect_url) {
    e.preventDefault();
    location.replace(redirect_url);
  }

  useEffect(() => {
    if (open) fetchDetails();
  }, [open]);

  const [response, setResponse] = useState([]);

  return (
    <Modal className="risk-modal25" open={open} onClose={handleClose}>
      <div className="riskfactor-modal-box">
        <button className="riskfactor-close-button" onClick={handleClose}>
          <img src="./images/icons/close.svg" alt="" />{" "}
        </button>

        <div className="mf-list257496">
          <div key={123} onClick={(e) => handleSubmit(e, fund.redirect_url)}>
            <div className="mf-list-card">
              {/* <img src={fund.icon}></img>
                    <div className="kjhyj12">
                      <div className="fund-name215">{fund.fund_name}</div>
                    </div> */}
              {desc}
              {/* <div className="interst-mf-fund">
                      <span>1 Day</span> <br /> {fund.oneday}%
                    </div>
                    <div className="interst-mf-fund">
                      <span>1 Year</span> <br /> {fund.oneyear}%
                    </div>
                    <div className="interst-mf-fund">
                      <span>3 Year</span> <br /> {fund.threeyear}%
                    </div> */}
            </div>
          </div>

          <div
            key={123}
          >
            {/* <a href='https://www.fundzbazar.com/signin' target="_blank" rel="noreferrer" className="mf-list-card213">
                  <img src='./images/misc/lock.png'></img>
                  <h3>click here to login fundzbazar to unlock this </h3>
                    <div className="kljh">
                    
                    </div>
                    
                    
                  </a> */}

            {/* <a
              target="_blank"
              rel="noreferrer"
              className="mf-list-card213"
              style={{
                textDecoration: "none",
                display: "flex",
              }}
              href="https://www.fundzbazar.com/customisedlinkregistration/7C7723/2364257C77233F63263F63263F63262A74255E6D3A266B7C"> */}
            <div className="mf-list-card213">



              <button className="slider-btn"><a href="https://calendly.com/vijaymaheshwari/financialfreedom" style={{ textDecoration: 'none', color: '#fff' }} > Book A Free Call </a></button>
              <button className="slider-btn">
                <a
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none', color: '#fff' }}
                  href="https://www.fundzbazar.com/customisedlinkregistration/7C7723/2364257C77233F63263F63263F63262A74255E6D3A266B7C">
                  Open An Account
                </a>
              </button>
            </div>
          </div>

        </div>

      </div>
    </Modal>
  );
}

export default DiscoverMutualFundModal;
