import React, { useEffect } from "react";
import { Button, Stack } from "@mui/material";
import "./financial.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

function InfoFrom({ info, setInfo, setPage, page, option, profile }) {
    console.log(profile);
    console.log(info)

    async function handleSubmit(e) {
        const token = localStorage.getItem("authToken")
        const url = process.env.REACT_APP_API_BASE_URL + "/finantialplanning/finnantial-Q1?authToken=" + token
        await fetch(url, {
            method: "POST",
            headers: {
                // "authToken" : token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(info)
        })
            .then((res) => res.json())
            .then(data => {
                console.log("response from adding data ", data)
                if (data.message === "Data saved" || data.message === "Data saved") {
                    setPage(page + 1);
                }
                else {
                    alert("Something went wrong while submitting your data, please try again")
                }
            })
            .catch(err => {
                console.log(err)
                alert("Something went wrong, please check your internet connection and try again")
            })

        e.preventDefault();
    }

    function back() {
        setPage(page - 1);
    }


    useEffect(async () => {
        const token = localStorage.getItem("authToken");
        console.log("token", token)
        const url = process.env.REACT_APP_API_BASE_URL + "/finantialplanning/finnantial-Q1?authToken=" + token
        await fetch(url, {
            method: "GET",
            // headers : {
            //     "authToken" : token
            // }
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                if (data.length > 0) {
                    setInfo({
                        status: data[0].lstatus,
                        children: data[0].children,
                        parents: data[0].parents
                    })
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [])



    return (
        <div className="profile-info-container">
            <div className="profile-head">WELCOME TO FINANCIAL PLANNING</div>
            <div className="profile-subheading">{`Hey!..  ${profile}`}</div>
            {/* <div className="profile-dropdowns"> */}
            <Stack direction="row" className="jhtr">
                <Stack direction="column" alignItems="space-between" justifyContent="space-between">
                    <label style={{ color: "grey", backgroundColor: "white" }}> Marital Status </label>
                    <label style={{ color: "grey", backgroundColor: "white" }}> Children </label>
                    <label style={{ color: "grey", backgroundColor: "white" }}> Parents </label>
                </Stack>
                <Stack direction="column" alignItems="space-between" justifyContent="space-between">
                    <select value={info.status}
                        onChange={(e) => { setInfo({ ...info, status: e.target.value }) }} style={{ margin: "20px" }}>
                        <option value={null} disabled selected>Status</option>
                        <option value="married">Married</option>
                        <option value="unmarried">UnMarried</option>
                    </select>
                    <select value={info.children}
                        onChange={(e) => { setInfo({ ...info, children: e.target.value }) }} style={{ margin: "20px" }}>
                        <option value={null} disabled selected>Children</option>
                        <option value="0">NA</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select>
                    <select value={info.parents}
                        onChange={(e) => { setInfo({ ...info, parents: e.target.value }) }} style={{ margin: "20px" }}>
                        <option value={null} disabled selected>Parents</option>
                        <option value="both alive">Both Alive</option>
                        <option value="mother">Mother</option>
                        <option value="father">Father</option>
                        <option value="na">NA</option>
                    </select>
                </Stack>
            </Stack>
            <div className="profile-button-row">
                <button variant="contained" className="hedfa" style={{ margin: "20px", padding: "20 40" }} onClick={back} startIcon={
                    <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>}> Previous     </button>
                <button variant="outlined" className="jkrd" style={{ margin: "20px", padding: "20 40" }} onClick={() => setPage(page + 1)}>SKIP</button>
                <button variant="contained" className="hedfa" style={{ margin: "20px", padding: "20 40" }} onClick={handleSubmit} endIcon={
                    <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>}>NEXT   </button>
            </div>
        </div>
    )
}

export default InfoFrom;