import React, { useEffect, useState } from "react";
import Footer from "../components/Footer/Footer";

import Headline from "../components/Headline/Headline";
import Navbar from "../components/Navbar/Navbar";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import "./css/privacypolicy.css";
import axios from "axios";

function ContactUs() {
  const particlesInit = async (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  const [name, setName] = useState("");
  const [query, setQuery] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const submitForm = async () => {
    console.log("Submit form called");
    const body = {
      name: name,
      email: email,
      phone: phone,
      query: query
    };
    const formResp = await axios.post(
      process.env.REACT_APP_API_BASE_URL + "/post/contactus/",
      body
    );
    console.log(formResp);
    if (formResp.status == 200) {
      alert("Message has been sent succesfully. Team will get back to you shortly.");
    } else {
      alert("Sending message failed. Please retry again!");
    }
  };

  return (
    <div className="privacy-policy">
      <ScrollToTop />
      <Headline />
      <Navbar />

      <div className="privacy-main">
        <div className="privacy-sidebar">
          <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
              fullScreen: {
                enable: false,
                zIndex: 0
              },
              particles: {
                number: {
                  value: 50,
                  density: {
                    enable: true,
                    value_area: 2000
                  }
                },
                color: {
                  value: "#fff"
                },
                shape: {
                  type: "circle"
                },
                opacity: {
                  value: 0.5,
                  random: true,
                  anim: {
                    enable: false,
                    speed: 1,
                    opacity_min: 0.1,
                    sync: false
                  }
                },
                size: {
                  value: 18,
                  random: true,
                  anim: {
                    enable: false,
                    speed: 40,
                    size_min: 0.1,
                    sync: false
                  }
                },
                line_linked: {
                  enable: false,
                  distance: 500,
                  color: "#ffffff",
                  opacity: 0.4,
                  width: 2
                },
                move: {
                  enable: true,
                  speed: 2,
                  direction: "top-right",
                  random: false,
                  straight: false,
                  out_mode: "out",
                  attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200
                  }
                }
              },
              interactivity: {
                events: {
                  onhover: {
                    enable: true,
                    mode: "bubble"
                  },
                  onclick: {
                    enable: true,
                    mode: "repulse"
                  },
                  resize: true
                },
                modes: {
                  grab: {
                    distance: 400,
                    line_linked: {
                      opacity: 0.5
                    }
                  },
                  bubble: {
                    distance: 400,
                    size: 4,
                    duration: 0.3,
                    opacity: 1,
                    speed: 3
                  },
                  repulse: {
                    distance: 200
                  },
                  push: {
                    particles_nb: 4
                  },
                  remove: {
                    particles_nb: 2
                  }
                }
              },
              retina_detect: true,
              background: {
                color: "#2e3092",
                image: "",
                position: "50% 50%",
                repeat: "no-repeat",
                size: "cover"
              }
            }}
          />
          <h1>Get in touch </h1>
          <p>Ask a question, reach out for assistance, or simply say hello.</p>
        </div>
        <div className="aaabb212">
          <div className="abcde212">
            <div className="office1">
              <h1>
                {" "}
                <img src="/images/social/place.png" alt="" /> Mumbai
              </h1>
              <p>
                Research Lab <br />
                Stocktick Capital
                <br />
                Address- A Venture Of Waymark Enterprises Pvt Ltd,
                {/* <br /> */}
                B wing 105, Yogeshwar CHS, Near Shahasdrabude Hospital,
                {/* <br /> */}
                Opposite Chintamani Hall, Old Panvel 410206.
              </p>
            </div>
            {/* <div className="office1">
                            <h1> <img src="/images/social/place.png" alt="" /> Pune</h1>
                            <p>Ascend Co working, B 205-206, <br />
                                Dhanashree Heights, <br />
                                Off Veera Desai Rd, Andheri West, <br />
                                Mumbai, Maharashtra 400058</p>
                        </div> */}

            <div className="office1">
              <h1>
                {" "}
                <img src="/images/social/clock.png" alt="" /> Office Timing{" "}
              </h1>
              <p>
                Mon-Fri <br />
                10am to 7pm
              </p>
            </div>

            <div className="office1">
              <h1>
                {" "}
                <img src="/images/social/call.png" alt="" /> Contact{" "}
              </h1>
              <p>
                <a href="tel:+918829306812">+91 8829306812</a> <br />
                <a href="tel:+917045872668">+91 7045872668</a>{" "}
              </p>
            </div>

            <div className="office1">
              <img src="/images/social/email.png" alt="" />{" "}
              <a href="mailto: info@stocktick.in">info@stocktick.in</a>
            </div>

            <div className="office1" style={{ marginTop: "20px" }}>
              <h1>
                <img src="/images/social/place.png" style={{ width: "26px", height: "26px" }} />
                Our Presence
              </h1>
              <p> Mumbai - Navi Mumbai - Bengaluru - Delhi - Chhattisgarh.</p>
            </div>
          </div>

          <div className="abcde213">
            <div className="contact-form212">
              <input
                type="text"
                placeholder="Your Name *"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <input
                type="email"
                placeholder="Email *"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <input
                type="tel"
                placeholder="Phone No. *"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Say Something... "
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                }}></textarea>

              <button className="send-msg" onClick={submitForm}>
                {" "}
                Send Message{" "}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ContactUs;
