import React from "react";
import { useEffect } from 'react';
import { Button, Stack } from "@mui/material";
import "./financial.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Chart from "react-apexcharts";

function AnnualExpanses({ page, setPage }) {
    const [stats, setStats] = React.useState({
        investmentAmount: 6000,
        householdExpenses: 6000,
        lifestyleExpenses: 6000,
        surplus: 6000,
        taxPaid: 6000,
        emiPaid: 6000,
    });
    const [series, setSeries] = React.useState([6000, 6000, 6000, 6000, 6000, 6000]);
    const [option, setOption] = React.useState({
        chart: { id: "annual-expenses", type: "donut" },
        labels: ["Investment Amt", "Household", "Lifestyle", "Surplus", "Tax", "EMI"],
        responsive: [{
            breakpoint: 180,
            options: {
                chart: {
                    width: 200,

                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        legend: {
            position: 'right',
            offsetY: 0,
        }
    });

    useEffect(() => {
        setSeries([stats.investmentAmount, stats.householdExpenses, stats.lifestyleExpenses, stats.surplus, stats.taxPaid, stats.emiPaid]);
    }, [stats]);

    useEffect(async () => {
        const token = localStorage.getItem("authToken");
        console.log("token", token)
        const url = process.env.REACT_APP_API_BASE_URL + "/finantialplanning/finnantial-Q2?authToken=" + token
        await fetch(url, {
            method: "GET",
            // headers : {
            //     "authToken" : token
            // }
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                if (data.length > 0) {
                    setStats({
                        investmentAmount: data[0].invst_amount,
                        householdExpenses: data[0].household_expns,
                        lifestyleExpenses: data[0].lifestyle_expns,
                        surplus: data[0].surplus,
                        taxPaid: data[0].tax_paid,
                        emiPaid: data[0].emi_paid
                    })
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [])


    async function handleSubmit(e) {
        const token = localStorage.getItem("authToken")
        const url = process.env.REACT_APP_API_BASE_URL + "/finantialplanning/finnantial-Q2?authToken=" + token
        await fetch(url, {
            method: "POST",
            headers: {
                // "authToken" : token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                invst_amount: stats.investmentAmount,
                household_expns: stats.householdExpenses,
                lifestyle_expns: stats.lifestyleExpenses,
                surplus: stats.surplus,
                tax_paid: stats.taxPaid,
                emi_paid: stats.emiPaid
            })
        })
            .then((res) => res.json())
            .then(data => {
                console.log("response from adding data ", data)
                if (data.message) {
                    setPage(page + 1);
                }
                else {
                    alert("Something went wrong while submitting your data, please try again")
                }
            })
            .catch(err => {
                console.log(err)
                alert("Something went wrong, please check your internet connection and try again")
            })
        e.preventDefault();
    }



    return (
        <div className="profile-info-container">
            <div className="profile-head">WELCOME TO FINANCIAL PLANNING</div>
            <div className="profile-subheading">{"Lets estimate your annual expenses (in Rs.)"}</div>

            <div className="div-flex">
                <Chart
                    type='donut'
                    options={option}
                    series={series}

                >
                </Chart>
                <div style={{ display: "flex", flexDirection: "row", minWidth: "200px" }}>
                    <div style={{ display: "flex", flexDirection: "column", marginRight: "-20px" }}>
                        <label style={{ color: "grey", backgroundColor: "white" }}>Investment Amount</label>
                        <label style={{ color: "grey", backgroundColor: "white" }}>Household Expenses</label>
                        <label style={{ color: "grey", backgroundColor: "white" }}>Lifestyle Expenses</label>
                        <label style={{ color: "grey", backgroundColor: "white" }}>Surplus</label>
                        <label style={{ color: "grey", backgroundColor: "white" }}>Tax Paid</label>
                        <label style={{ color: "grey", backgroundColor: "white" }}>EMI Paid</label>
                    </div>
                    <Stack alignItems="space-between" className="abcde123" justifyContent="space-between" direction="column" xs={3} style={{ margin: "20px" }}>
                        <input
                            type="number"
                            placeholder="0"
                            value={stats.investmentAmount}
                            style={{ margin: "20 40", padding: "20 40" }}
                            onChange={(e) => {
                                setStats({ ...stats, investmentAmount: parseInt(e.target.value) });
                            }}></input>
                        <input
                            type="number"
                            placeholder="0"
                            value={stats.householdExpenses}
                            onChange={(e) => {
                                setStats({ ...stats, householdExpenses: parseInt(e.target.value) });
                            }}
                        />
                        <input
                            type="number"
                            placeholder="0"
                            value={stats.lifestyleExpenses}
                            onChange={(e) => {
                                setStats({ ...stats, lifestyleExpenses: parseInt(e.target.value) });
                            }}
                        />
                        <input
                            type="number"
                            placeholder="0"
                            value={stats.surplus}
                            onChange={(e) => {
                                setStats({ ...stats, surplus: parseInt(e.target.value) });
                            }}
                        />
                        <input
                            type="number"
                            placeholder="0"
                            value={stats.taxPaid}
                            onChange={(e) => {
                                setStats({ ...stats, taxPaid: parseInt(e.target.value) });
                            }}
                        />
                        <input
                            type="number"
                            placeholder="0"
                            value={stats.emiPaid}
                            onChange={(e) => {
                                setStats({ ...stats, emiPaid: parseInt(e.target.value) });
                            }}
                        />
                    </ Stack>
                </div>
            </div>
            <div className="profile-button-row">

                <button variant="contained" className="hedfa" style={{ margin: "20px", padding: "20 40" }} onClick={() => setPage(page - 1)} startIcon={
                    <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>}>Previous </button>
                <button variant="outlined" className="jkrd" style={{ margin: "20px", padding: "20 40" }} onClick={() => setPage(page + 1)}>SKIP</button>
                <button variant="contained" className="hedfa" style={{ margin: "20px", padding: "20 40" }} onClick={handleSubmit} endIcon={
                    <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>}>NEXT</button>
            </div>
        </div>
    )
}

export default AnnualExpanses;