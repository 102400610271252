import React, {useState, useEffect}  from "react";
import Slider from "@material-ui/core/Slider";
import Box from "@material-ui/core/Box";
import {format} from "indian-number-format"
import { Button, Stack } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const ChildEducation = ({page, setPage}) => {
  //input variables
  const [currentCost, setCurrentCost ] = useState(10000)
  const [inflation, setInflation] = useState(1);
  const [currentAge, setCurrentAge] = useState(0);
  const [requiredAge, setRequiredAge] = useState(1);
  const [returnRate, setReturnRate] = useState(1);
  
  //output variables
  const [futureCost, setFutureCost] = useState(0);
  const [monthlyInvestment, setMonthlyInvestment] = useState(0);

  const handleChange1=(e, value) => {
    setCurrentCost(value)
  }
  const handleChange2=(e, value) => {
    setInflation(value)
  }
  const handleChange3  = (e, value) => {
    setCurrentAge(value)
    if(requiredAge < value){
      setRequiredAge(value)
    }
  }
  const handleChange4 = (e, value) => {
    setRequiredAge(value);
    if(value < currentAge){
      setCurrentAge(value)
    }
  }
  const handleChange5 = (event, value) => {
    setReturnRate(value);
  };

  useEffect(()=>{
    //the calculation occurs here
    let f_cost, m_invest;
    f_cost = currentCost*Math.pow(1+inflation/100, requiredAge-currentAge)
    let r = returnRate;
    r = r/1200;
    m_invest = f_cost/(((Math.pow(1+r, (requiredAge-currentAge)*12)-1)/r)*(r+1));
    setFutureCost(parseInt(f_cost));
    setMonthlyInvestment(parseInt(m_invest))
  },
  [currentAge, currentCost, inflation, requiredAge, returnRate])



  useEffect(async () => {
    const token = localStorage.getItem("authToken");
    console.log("token", token)
    const url = process.env.REACT_APP_API_BASE_URL + "/finantialplanning/finnantial-ChildEduCalc?authToken=" + token
    await fetch(url,
      {
        method: "GET",
      })
      .then(res => res.json())
      .then(data => {
        console.log(data)
        if (data && data.length > 0) {
          const res = data[0]
          setCurrentCost(res.current_edu_cost)
          setInflation(res.inflation_rate)
          setCurrentAge(res.current_age)
          setRequiredAge(res.corpus_age)
          setReturnRate(res.expected_investment_return)
          setFutureCost(res.future_investment_cost)
          setMonthlyInvestment(res.monthly_investment_reqd)
        }
      })

      .catch(err => {
        console.log(err)
      })

  }, [])

  async function handleSubmit(e) {
    const token = localStorage.getItem("authToken")
    const url = process.env.REACT_APP_API_BASE_URL + "/finantialplanning/finnantial-ChildEduCalc?authToken=" + token
    await fetch(url, {
      method: "POST",
      headers: {
        // "authToken" : token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        current_edu_cost: currentCost,
        inflation_rate: inflation,
        current_age: currentAge,
        corpus_age: requiredAge,
        expected_investment_return: returnRate,
        future_edu_cost: futureCost,
        monthly_investment_reqd: monthlyInvestment

      })
    })
      .then((res) => res.json())
      .then(data => {
        console.log("response from adding data ", data)
        if (data.message == "Data saved") {
          console.log("Data saved succesfully")
          setPage(page+1);
        }
        else {
          alert("Something went wrong while submitting your data, please try again")
        }
      })
      .catch(err => {
        console.log(err)
        alert("Something went wrong, please check your internet connection and try again")
      })
    e.preventDefault();
  }









  return (
    <>
      <h1 className="hedarf">Child Education Calculator</h1>
      <div className="marriage-modal">
        <div className="marriageinput2">
          <div className="marriageheading">
            Systematic Investment Plan (SIP) allows you to make small investment at regular
            intervals to help you achieve your dreams.
          </div>
          <div className="goalslider">
            <div className="goalinfo-title-text">
              <h1>Current cost of the desired education</h1>
              <div className="goalinfo-title-text-sub">
               <span> &#8377;</span> 
               <input type="number" placeholder="0" value={currentCost} onChange={(e) => setCurrentCost(e.target.value)}  />
               </div>
            </div>
            <Box >
              <Slider
                aria-label="Volume"
                value={currentCost}
                onChange={handleChange1}
                max={2000000}
              />
            </Box>
          </div>
          <div className="goalslider">
            <div className="goalinfo-title-text">
              <h1>Inflation Rate</h1>
              <div className="goalinfo-title-text-sub">  
              <input type="number" placeholder="0" value={inflation} onChange={(e) => setInflation(e.target.value)}  />
              
              <span>&nbsp;%</span>
              </div>
            </div>
            <Box >
              <Slider
                aria-label="Volume"
                value={inflation}
                onChange={handleChange2}
                max={50}
              />
            </Box>
          </div>
          <div className="goalslider">
            <div className="goalinfo-title-text">
              <h1>Current Age of Child</h1>
              <div className="goalinfo-title-text-sub">
              <input type="number" placeholder="0" value={currentAge} onChange={(e) => setCurrentAge(e.target.value)}  />
              <span>&nbsp;Y</span>
               </div>
            </div>
            <Box >
              <Slider
                aria-label="Volume"
                value={currentAge}
                onChange={handleChange3}
                max={60}
              />
            </Box>
          </div>
          <div className="goalslider">
            <div className="goalinfo-title-text">
              <h1>Age of child when corpus is required</h1>
              <div className="goalinfo-title-text-sub">
              <input type="number" placeholder="0" value={requiredAge} onChange={(e) => setRequiredAge(e.target.value)}  />
              <span>&nbsp;Y</span>
              </div>
            </div>
            <Box >
              <Slider
                aria-label="Volume"
                value={requiredAge}
                onChange={handleChange4}
                max={60}
              />
            </Box>
          </div>
          <div className="goalslider">
            <div className="goalinfo-title-text">
              <h1>Expected Investment Returns</h1>
              <div className="goalinfo-title-text-sub">
              <input type="number" placeholder="0" value={returnRate} onChange={(e) => setReturnRate(e.target.value)}  />
               <span>&nbsp;%</span>
               </div>
            </div>
            <Box >
              <Slider
                aria-label="Volume"
                value={returnRate}
                onChange={handleChange5}
                max={50}
              />
            </Box>
          </div>

          </div>
        <div className="marriageinfos3">
          <div className="marriageinfo">
            <div className="marriageinfo-title">
              <h1 >
                Future cost of your child education
              </h1>
              <h2>&#8377;  {format(futureCost)} </h2>
            </div>
            <div className="marriageinfo-title">
              <h1 >
                Monthly Investments required
              </h1>
              <h2>&#8377;  {format(monthlyInvestment)} </h2>
            </div>
          </div>
        </div>
        <div className="profile-button-row1">
                
                <button className="hedfa" variant="contained" style={{margin:"20px",padding:"20 40"}} onClick={() => setPage(page - 1)} startIcon={
                    <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>}>Previous</button>
                <button className="jkrd" variant="outlined" style={{margin:"20px",padding:"20 40"}} onClick={() => setPage(page+1)}>SKIP</button>
                <button className="hedfa" variant="contained" style={{margin:"20px",padding:"20 40"}} onClick={handleSubmit} endIcon={
                    <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>}>NEXT</button>
            </div>
      </div>
    </>
  );
};

export default ChildEducation;
