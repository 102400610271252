import { styled } from "@mui/material";
import React, { useRef, useState } from "react";
import "./calculator.css";
import "../Mutual_Funds/mf.css";
import ChildMarriage from "./ChildMarriage";
import ChildEducation from "./ChildEducation.jsx";
import Retirement from "./Retirement";
import WealthSip from "./WealthSip.jsx";
import SipTopUp from "./SipTopUp.jsx";
import Goal from "./Goal";
import EMI from "./EMI.jsx";
import EmergencyFund from "./EmergencyFund";
import KnowYourWealth from "./KnowYourWealth";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0",
    marginTop: "15px"
  },
  display: "flex",
  // flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "20px",
  background: "#F6F6F6",
  borderRadius: "8px",
  marginleft: "20px",
  marginright: "20px"
}));

// const Cards = styled("div")(({ theme }) => ({
//   [theme.breakpoints.down("md")]: {
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//     alignItems: "center",
//     margin: "20px"
//   },
//   width: "100vw",
//   display: "flex",
//   flexDirection: "row",
//   justifyContent: "space-around",
//   alignItems: "center",
//   margin: "50px"
// }));

// const Card = styled("div")(() => ({
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "space-between",
//   alignItems: "center",
//   background: "#EDEDED",

//   borderRadius: "12px",
//   height: "300px",
//   width: "450px",
//   padding: "20px",
//   paddingBottom: "50px",
//   objectFit: "cover"
// }));

function Calculator() {
  const [calculator, setCalculator] = useState("Emergency Fund");
  const element = document.getElementById('showing_calci');

  const showcalculator = () => {
    if (element != null) {
      element.scrollIntoView({ behavior: 'auto' });
    }

    if (calculator === "Emergency Fund") {
      return <EmergencyFund />;
    } else if (calculator === "Retirement") {
      return <Retirement />;
    } else if (calculator === "Goal") {
      return <Goal />;
    } else if (calculator === "ChildMarriage") {
      return <ChildMarriage />;
    } else if (calculator === "ChildEducation") {
      return <ChildEducation />;
    } else if (calculator === "WealthSip") {
      return <WealthSip />;
    } else if (calculator === "EMI") {
      return <EMI />;
    }
    else if (calculator === "SipTopUp") {
      return <SipTopUp />;
    }
    else if (calculator === "KnowYourWealth") {
      return <KnowYourWealth />;
    }


    //document.getElementById("showing-calci").scrollIntoView();
  };
  return (
    <div className="calculator-parent">
      <div className="calculator-heading1">
        Plan your Investment
      </div>
      <div className="pill-nav">
        <button
          className={calculator === "Emergency Fund" ? "active-pill" : "inactive-pill"}
          onClick={() => {
            setCalculator("Emergency Fund");
          }}
        >
          <h3>Emergency Fund <br /> Calculator</h3>
          <img src="/images/icons/cal1.svg" alt="" />
        </button>
        <button
          className={calculator === "Retirement" ? "active-pill" : "inactive-pill"}
          onClick={() => setCalculator("Retirement")}
        >
          <h3>Retirement <br /> Calculator</h3>
          <img src="/images/icons/retirement.png" alt="" />
        </button>
        <button
          className={calculator === "Goal" ? "active-pill" : "inactive-pill"}
          onClick={() => setCalculator("Goal")}
        >
          <h3>Goal <br /> Calculator</h3>
          <img src="/images/icons/cal2.svg" alt="" />
        </button>
        <button
          className={calculator === "ChildMarriage" ? "active-pill" : "inactive-pill"}
          onClick={() => setCalculator("ChildMarriage")}
        >
          <h3>Child Marriage <br /> Calculator</h3>
          <img src="/images/icons/Child-marriage-calculator.svg" alt="" />
        </button>
        {/* /Users/amartya/Documents/projects/stocktick-web/public/images/icons/Sip-Calculator.svg */}
        <button
          className={calculator === "ChildEducation" ? "active-pill" : "inactive-pill"}
          onClick={() => setCalculator("ChildEducation")}
        >
          <h3>Child Education <br /> Calculator</h3>
          <img src="/images/icons/read.png" alt="" />
        </button>
        <button
          className={calculator === "WealthSip" ? "active-pill" : "inactive-pill"}
          onClick={() => setCalculator("WealthSip")}
        >
          <h3>SIP <br /> Calculator</h3>
          <img src="/images/icons/Sip-Calculator.svg" alt="" />
        </button>
        <button
          className={calculator === "EMI" ? "active-pill" : "inactive-pill"}
          onClick={() => setCalculator("EMI")}
        >
          <h3>EMI <br /> Calculator</h3>
          <img src="/images/icons/loan.png" alt="" />
        </button>
        <button
          className={calculator === "SipTopUp" ? "active-pill" : "inactive-pill"}
          onClick={() => setCalculator("SipTopUp")}
        >
          <h3>SIP Top Up <br /> Calculator</h3>
          <img src="/images/icons/cal11.svg" alt="" />
        </button>
        <button
          className={calculator === "KnowYourWealth" ? "active-pill" : "inactive-pill"}
          onClick={() => setCalculator("KnowYourWealth")}
        >
          <h3>Know Your Wealth </h3>
          <img src="/images/icons/Know-your-wealth.svg" alt="" />

        </button>
      </div>

      <div id="showing_calci" className="show-calculator-xz">{showcalculator()}</div>
    </div>
  );
}

export default Calculator;
