import { Link } from "react-router-dom";
import "./asset.css";
import React, { useState, useEffect } from "react";

function AssetRecoder() {
  const [summary, setSummary] = useState({
    assets: 0,
    liabilities: 0
  });

  const [checked, setChecked] = useState(true);

  const handleDownload = async () => {
    const token = localStorage.getItem("authToken");
    const url = process.env.REACT_APP_API_BASE_URL + "/asset-recorder/csv?authToken=" + token;
    const resp = await fetch(url)
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
        alert("Something went wrong while fetching the results, please try again");
        // setLoading(false)
      });
    console.log(resp);
    if (resp.message === "Report Generated and Uploaded") {
      // setResult(resp.url)
      window.open(resp.url, "_blank", "noopener,noreferrer");
    }
  };

  const assetSummary = async () => {
    const token = localStorage.getItem("authToken");
    const url =
      process.env.REACT_APP_API_BASE_URL + "/asset-recorder/data-summary?authToken=" + token;
    const resp = await fetch(url)
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
        alert("Something went wrong while fetching the results, please try again");
        // setLoading(false)
      });
    console.log(resp);
    if (resp) {
      let assets = 0;
      let liabilities = 0;
      if (resp.assets) {
        assets = resp.assets;
      }
      if (resp.liabilities) {
        liabilities = resp.liabilities;
      }

      setSummary({
        assets: assets,
        liabilities: liabilities
      });
    }
  };

  useEffect(() => {
    assetSummary();
  }, []);

  return (
    <div className="hgftds125">
      <h1> Asset Reconciliation</h1>

      <div className="calculation-1">
        <div className="cal879">
          <h2>Total Assets - </h2>
          <br />
          <div className="total-assets">{summary.assets} INR</div>
        </div>

        <div className="cal879">
          <h2>Total Liability = </h2>
          <br />
          <div className="total-lib">{summary.liabilities} INR</div>
        </div>

        <div className="cal879">
          <h2> Net Worth</h2>
          <br />
          <div className="total-worth">{summary.assets - summary.liabilities} INR</div>
        </div>
      </div>

      <div className="all-parent-div">
        <div className="asset-parent">
          <img src="/images/asset/asset1.svg" alt="" />

          <p>Profile Details</p>
          <Link to="/mutualfund/assetreconciliation/profile-page" className="ghdtb-btn">
            <img src="/images/asset/plus.svg" alt="" />{" "}
          </Link>
        </div>

        <div className="asset-parent">
          <img src="/images/asset/asset2.svg" alt="" />

          <p>Key People</p>
          <Link to="/mutualfund/assetreconciliation/key-people" className="ghdtb-btn">
            <img src="/images/asset/plus.svg" alt="" />{" "}
          </Link>
        </div>

        <div className="asset-parent">
          <img src="/images/asset/asset3.svg" alt="" />

          <p>Bank Account</p>
          <Link to="/mutualfund/assetreconciliation/bank-account" className="ghdtb-btn">
            <img src="/images/asset/plus.svg" alt="" />{" "}
          </Link>
        </div>

        <div className="asset-parent">
          <img src="/images/asset/asset4.svg" alt="" />

          <p>Locker</p>
          <Link to="/mutualfund/assetreconciliation/locker" className="ghdtb-btn">
            <img src="/images/asset/plus.svg" alt="" />{" "}
          </Link>
        </div>
        <div className="asset-parent">
          <img src="/images/asset/asset5.svg" alt="" />

          <p>Fixed Deposits</p>
          <Link to="/mutualfund/assetreconciliation/fixed-deposit" className="ghdtb-btn">
            <img src="/images/asset/plus.svg" alt="" />{" "}
          </Link>
        </div>

        <div className="asset-parent">
          <img src="/images/asset/asset6.svg" alt="" />

          <p>Mutual Funds</p>
          <Link to="/mutualfund/assetreconciliation/mutualfunds" className="ghdtb-btn">
            <img src="/images/asset/plus.svg" alt="" />{" "}
          </Link>
        </div>
        <div className="asset-parent">
          <img src="/images/asset/asset7.svg" alt="" />

          <p>Bonds</p>
          <Link to="/mutualfund/assetreconciliation/bonds" className="ghdtb-btn">
            <img src="/images/asset/plus.svg" alt="" />{" "}
          </Link>
        </div>

        <div className="asset-parent">
          <img src="/images/asset/asset8.svg" alt="" />

          <p>EPF</p>
          <Link to="/mutualfund/assetreconciliation/EPF" className="ghdtb-btn">
            <img src="/images/asset/plus.svg" alt="" />{" "}
          </Link>
        </div>
        <div className="asset-parent">
          <img src="/images/asset/asset9.svg" alt="" />

          <p>NPS</p>
          <Link to="/mutualfund/assetreconciliation/NPS" className="ghdtb-btn">
            <img src="/images/asset/plus.svg" alt="" />{" "}
          </Link>
        </div>

        <div className="asset-parent">
          <img src="/images/asset/asset10.svg" alt="" />

          <p>PPF</p>
          <Link to="/mutualfund/assetreconciliation/PPF" className="ghdtb-btn">
            <img src="/images/asset/plus.svg" alt="" />{" "}
          </Link>
        </div>

        <div className="asset-parent">
          <img src="/images/asset/asset11.svg" alt="" />

          <p>PMS</p>
          <Link to="/mutualfund/assetreconciliation/PMS" className="ghdtb-btn">
            <img src="/images/asset/plus.svg" alt="" />{" "}
          </Link>
        </div>

        <div className="asset-parent">
          <img src="/images/asset/asset12.svg" alt="" />

          <p>AIF</p>
          <Link to="/mutualfund/assetreconciliation/AIF" className="ghdtb-btn">
            <img src="/images/asset/plus.svg" alt="" />{" "}
          </Link>
        </div>

        <div className="asset-parent">
          <img src="/images/asset/asset13.svg" alt="" />

          <p>Indian Equity</p>
          <Link to="/mutualfund/assetreconciliation/indian-equity" className="ghdtb-btn">
            <img src="/images/asset/plus.svg" alt="" />{" "}
          </Link>
        </div>

        <div className="asset-parent">
          <img src="/images/asset/asset14.svg" alt="" />

          <p>US Equity</p>
          <Link to="/mutualfund/assetreconciliation/US-equity" className="ghdtb-btn">
            <img src="/images/asset/plus.svg" alt="" />{" "}
          </Link>
        </div>

        <div className="asset-parent">
          <img src="/images/asset/asset15.svg" alt="" />

          <p>Off Market</p>
          <Link to="/mutualfund/assetreconciliation/off-market" className="ghdtb-btn">
            <img src="/images/asset/plus.svg" alt="" />{" "}
          </Link>
        </div>

        <div className="asset-parent">
          <img src="/images/asset/asset16.svg" alt="" />

          <p>Real Estate</p>
          <Link to="/mutualfund/assetreconciliation/real-estate" className="ghdtb-btn">
            <img src="/images/asset/plus.svg" alt="" />{" "}
          </Link>
        </div>

        <div className="asset-parent">
          <img src="/images/asset/asset17.svg" alt="" />

          <p>Life Ins.</p>
          <Link to="/mutualfund/assetreconciliation/life-insurance" className="ghdtb-btn">
            <img src="/images/asset/plus.svg" alt="" />{" "}
          </Link>
        </div>

        <div className="asset-parent">
          <img src="/images/asset/asset18.svg" alt="" />

          <p>Health Ins.</p>
          <Link to="/mutualfund/assetreconciliation/health-insurance" className="ghdtb-btn">
            <img src="/images/asset/plus.svg" alt="" />{" "}
          </Link>
        </div>

        <div className="asset-parent">
          <img src="/images/asset/asset19.svg" alt="" />

          <p>Motor Ins.</p>
          <Link to="/mutualfund/assetreconciliation/motor-insurance" className="ghdtb-btn">
            <img src="/images/asset/plus.svg" alt="" />{" "}
          </Link>
        </div>

        <div className="asset-parent">
          <img src="/images/asset/asset20.svg" alt="" />

          <p>Loan </p>
          <Link to="/mutualfund/assetreconciliation/loan" className="ghdtb-btn">
            <img src="/images/asset/plus.svg" alt="" />{" "}
          </Link>
        </div>
      </div>
      {/* <button className="hedfa"> Download Summary</button> */}

      <div className="dfghtyu">
        <h2 className="willll12">I have made a willl to secure my family&apos;s future.</h2>
        <input
          type="checkbox"
          className="checkbox12"
          checked={checked}
          onChange={(e) => setChecked(!checked)}
        />
      </div>

      <div className="text-center">
        <button
          type="submit"
          className="sgdgdhbd"
          variant="outlined"
          style={{ margin: "20px", padding: "20 40" }}
          onClick={handleDownload}>
          Download Assest Summary
        </button>
      </div>
    </div>
  );
}

export default AssetRecoder;
