import React from "react";
import Footer from "../components/Footer/Footer";
import Headline from "../components/Headline/Headline";
import Explore from "../components/Mutual_Funds/ExploreMutualFunds";
import MFList from "../components/Mutual_Funds/MFList";
import Navbar from "../components/Navbar/Navbar";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";

function Explore_MF() {
  return (
    <>
      <ScrollToTop />
      <Headline />
      <Navbar />
      <MFList />
      <Footer/>
    </>
  );
}

export default Explore_MF;
