import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import "./modal.css";



function WebinarRegisterationModal({ open, handleClose, webinarIdSelected }) {

  const [profile, setProfile] = useState({
    "name": "",
    "phone": "",
    "email": "",
    "city": ""
  });
  const [success, setSuccess] = useState(false);


  useEffect(() => {
    axios.get("/infrom/info")
      .then((res) => {
        setInfo([...res.data]);
        console.log(res);
        console.log(profile.name);
      });

  }, [])

  useEffect(() => {
    console.log(profile.name);
  }, [])

  const selfRegister = async () => {
    const authToken = localStorage.getItem("authToken");
    console.log(webinarIdSelected)
    const body = {
      webinar_id: webinarIdSelected.id,
      name: profile.name,
      email: profile.email,
      phone: profile.phone,
      city: profile.city
    };
    axios
      .post(process.env.REACT_APP_API_BASE_URL + `/subscribe/webinar/`, body)
      .then((res) => {
        console.log(res);

        if (webinarIdSelected.selfHosted == 'self') {
          // alert("Registered successfully.");
          setSuccess(true);
          // dd tick popup here

        } else {
          // Redirect to another page
          alert("Registered successfully! Redirecting to site......");
          location.replace(redirectUrl)
        }
      })
      .catch((err) => {
        // console.log(err.response.status)
        if (webinarIdSelected.selfHosted == 'self') {
          location.replace(redirectUrl)
        }
        // alert(err.response.data.message)
        console.log(err);
      });
  }

   

  // {
  //     "webinar_id":2,
  //     "name":"atm1504.in@gmail.com",
  //     "email":"atm1504.in@gmail.com",
  //     "phone":"8445737698",
  //     "city":"gwalior"
  // }

  return (
    <Modal className="risk-modal" open={open} onClose={handleClose}>
      <div className="riskfactor-modal-box">
        <button className="riskfactor-close-button" onClick={handleClose}>
          <img src="./images/icons/close.svg" alt="" />{" "}
        </button>


       {success === false ? 
        (<>
        <h1 className="modal-heading21">
          Register for webinar
        </h1>

        <div className="input-container">
          <label>Name</label>
          <input type="text" placeholder="Enter your name" value={profile.name || ""}
            onChange={(e) => {
              setProfile({ ...profile, name: e.target.value });
            }} />
        </div>
        <div className="input-container">
          <label>Mobile Number</label>
          <input type="number" placeholder="Mobile no" value={profile.phone || ""}
            onChange={(e) => {
              setProfile({ ...profile, phone: e.target.value });
            }} />
        </div>

        <div className="input-container">
          <label>Email ID</label>
          <input type="email" placeholder="Email" value={profile.email || ""}
            onChange={(e) => {
              setProfile({ ...profile, email: e.target.value });
            }} />
        </div>

        <div className="input-container">
          <label>City</label>
          <input type="text" placeholder="Enter your City" value={profile.city || ""}
            onChange={(e) => {
              setProfile({ ...profile, city: e.target.value });
            }} />
        </div>


        <button className="submit-webinar-form" onClick={selfRegister}>Submit </button>
        </>)
        :<>

           <div className="success-img">
            <img src="/images/icons/checked.png" alt="" />
           </div>
          <h1 className="modal-heading21-51">Congratulations! You have successfully registered for the webinar.</h1>
        
        </>}


      </div>
    </Modal>
  );
}

export default WebinarRegisterationModal;