import { styled } from "@mui/material";
import React from "react";
// import img from "/images/asset/01.svg";
import Carousel from "react-bootstrap/Carousel";
import "./modal.css";
import "bootstrap/dist/css/bootstrap.min.css";

const RootStyle = styled("div")(() => ({
  display: "flex",
  widht: "100vw",
  marginTop: "20px",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "center"
}));

const Image = styled("img")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "200px"
  },
  width: "100%",
  height: "70vh",
  flex: 1,
  backgroundSize: "cover",
  opacity: "0.8"
  // paddingLeft: "10%",
  // paddingRight: "10%"
}));

function Middle() {
  return (
    <RootStyle>
      <div style={{ width: "99%" }}>
        {/* <Image src={img}></Image> */}
        <Carousel>
          <Carousel.Item>
            <Image
              // src={img}
              // className="d-block w-100"
              src="/images/asset/Website slide banner Final 01.svg"
              alt="First slide"
            />
            <Carousel.Caption>
              {/* <h3>First slide label</h3> */}
              {/* <p className="text" style={{ fontWeight: "bold", color: "black", fontSize: "23px" }}>
                {" "}
                “Beware of little expenses; a small leak will sink a great ship.” ~Benjamin Franklin
              </p> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image src="/images/asset/Website slide banner Final 02.svg" alt="Second slide" />

            <Carousel.Caption>
              {/* <h3>Second slide label</h3> */}
              {/* <p className="text" style={{ fontWeight: "bold", color: "white", fontSize: "23px" }}>
                “You cannot escape the responsibility of tomorrow by evading it today.” ~Abraham
                Lincoln~
              </p> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image src="/images/asset/Website slide banner Final 03.svg" alt="Third slide" />

            <Carousel.Caption>
              {/* <h3>Third slide label</h3> */}
              {/* <p className="text" style={{ fontWeight: "bold", color: "white", fontSize: "23px" }}>
                A big part of financial freedom is having your heart and mind free from the what-ifs
                of life. ~ Suze Orman
              </p> */}
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </RootStyle>
  );
}

export default Middle;
