/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Appbar from "../Appbar/Appbar";
import Footer from "../Footer/Footer";
import Car from "./CarInsurance/Car";
import StepOne from "./StepOne";

function ApplyInsurance({ open, handleClose, type }) {
  console.log("apply..");
  return <div>{open && type == 1 && <Car handleClose={handleClose} />}</div>;
}

export default ApplyInsurance;
