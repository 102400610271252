/* eslint-disable react/prop-types */
import {
  AccountCircle,
  AddRoad,
  CalendarToday,
  EmailOutlined,
  PhoneAndroidOutlined
} from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  styled,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker } from "@mui/lab";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    height: "100vh",
    alignItems: "center",
    marginBottom: "50px"
  },
  display: "flex",
  width: "100%",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
  height: "90vh",
  marginBottom: "50px",
  backgroundColor: "white"
}));

const RowStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "100%",
    alignItems: "center",
    marginBottom: "10px"
  },
  display: "flex",
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  marginBottom: "10px",
  color: "#45A69F"
}));

const CheckBoxStyle = styled("div")(({ theme }) => ({
  display: "flex",
  width: "80%",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "10px",
  marginLeft: "20px"
}));

const DateStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "10px",
  marginLeft: "20px"
}));

function HealthStepOne({
  open,
  handleClose,
  handleNext,
  name,
  email,
  phone,
  dob,
  add1,
  add2,
  handleName,
  handleEmail,
  handlePhone,
  handleDOB,
  handleAdd1,
  handleAdd2
}) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <RootStyle>
        <div style={{ flex: "0.1" }}>
          <h1 style={{ color: "#45A69F" }}>HEALTH INSURANCE</h1>
        </div>
        <RowStyle>
          <TextField
            id="input-with-icon-textfield"
            label="Name"
            value={name}
            onChange={handleName}
            InputLabelProps={{
              style: { color: "#45A69F", fontSize: "20px", fontWeight: "600" }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <AccountCircle /> */}
                </InputAdornment>
              )
            }}
            variant="standard"
            style={{ margin: "15px" }}
          />
          <TextField
            id="input-with-icon-textfield"
            label="Email"
            value={email}
            onChange={handleEmail}
            InputLabelProps={{
              style: { color: "#45A69F", fontSize: "20px", fontWeight: "600" }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <EmailOutlined /> */}
                </InputAdornment>
              )
            }}
            variant="standard"
            sx={{ margin: "15px" }}
          />
        </RowStyle>
        <RowStyle>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="DOB"
              value={dob}
              style={{ border: "none" }}
              onChange={handleDOB}
              renderInput={(params) => (
                <TextField
                  id="icon-textfield"
                  variant="standard"
                  {...params}
                  InputLabelProps={{
                    style: { color: "#45A69F", fontSize: "20px", fontWeight: "600" }
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <TextField
            id="input-with-icon-textfield"
            label="City"
            value={add1}
            onChange={handleAdd1}
            InputLabelProps={{
              style: { color: "#45A69F", fontSize: "20px", fontWeight: "600" }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <AddRoad /> */}
                </InputAdornment>
              )
            }}
            variant="standard"
            sx={{ margin: "15px" }}
          />
        </RowStyle>
        <RowStyle>
          <TextField
            id="input-with-icon-textfield"
            label="Phone"
            value={phone}
            onChange={handlePhone}
            InputLabelProps={{
              style: { color: "#45A69F", fontSize: "20px", fontWeight: "600" }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <PhoneAndroidOutlined /> */}
                </InputAdornment>
              )
            }}
            variant="standard"
            sx={{ margin: "15px" }}
          />
          <TextField
            id="input-with-icon-textfield"
            label="Pin Code"
            value={add2}
            onChange={handleAdd2}
            InputLabelProps={{
              style: { color: "#45A69F", fontSize: "20px", fontWeight: "600" }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <AddRoad /> */}
                </InputAdornment>
              )
            }}
            variant="standard"
            sx={{ margin: "15px" }}
          />
        </RowStyle>
        <CheckBoxStyle>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="I accept to the terms and conditions. I autorise company to call me."
            />
          </FormGroup>
          <Button
            sx={{ background: "#45A69F", color: "white", width: "200px", "&:hover": {backgroundColor: "#45A69F", } }}
            onClick={handleNext}>
            Next <img src="/images/icons/login.png" />{" "}
          </Button>
        </CheckBoxStyle>
      </RootStyle>
    </Dialog>
  );
}

export default HealthStepOne;
