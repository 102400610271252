import React, { useEffect, useState } from "react";
import "./EMI.css";
import Slider from "@material-ui/core/Slider";
import Box from "@material-ui/core/Box";

const KnowYourWealth = () => {
  const [value1, setValue1] = useState(1000000);
  const [value2, setValue2] = useState(21);

  let income = value1;
  let age = value2;
  let networth = income * age / 15;


  networth = Math.round(networth);

  console.log(income, age, networth);
  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
  };
  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };

  return (
    <>
      {/* <div>hello</div> */}
      <h1 className="hedarf">Know Your Wealth Calculator </h1>
      <div className="emi">

        <div className="emiinput">
          <div className="emiheading">
            Know Your Wealth Calculator helps you to calculate how much capital, savings, investment and assets you should have based on your age to attain financial freedom
          </div>

          <div className="emislider">
            <div className="emiinfo-title-text">
              <h1>Pretax Income </h1>
              <div className="emiinfo-title-text-sub">
                <span>&#8377; </span>
                <input type="number" placeholder="0" onChange={(e) => setValue1(e.target.value)} value={value1} />
              </div>
            </div>

            <Box
              // sx={{ width: 700 }}
              className="emi-slider"
            >
              <Slider
                aria-label="Volume"
                value={value1}
                onChange={handleChange1}

                max={10000000}
              />
            </Box>
          </div>
          <div className="emislider">
            <div className="emiinfo-title-text">
              <h1>Age </h1>
              <div className="emiinfo-title-text-sub">
                <input type="number" placeholder="0" onChange={(e) => setValue2(e.target.value)} value={value2} />
                <span> &nbsp;Y</span>
              </div>
            </div>

            <Box  // sx={{ width: 700 }}
              className="emi-slider">
              <Slider
                aria-label="Volume"
                value={value2}
                onChange={handleChange2}

                max={90}
                step={1}
              />
            </Box>
          </div>


        </div>
        <div className="emiinfos">

          <div className="emiinfo">

            <div className="emiinfo-title">
              <h1>Pretax Income  :</h1>
              <h1>&#8377;&nbsp;{value1}</h1>
            </div>
            <div className="emiinfo-title">
              <h1>Current Age  :</h1>
              <h1> &nbsp;{value2} Y  </h1>
            </div>
            <div className="emiinfo-title">
              <h1>Net Wealth   :</h1>
              <h1> &#8377;&nbsp;{networth} </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KnowYourWealth;
