import React, { useEffect, useState } from "react";
import Slider from "@material-ui/core/Slider";
import Box from "@material-ui/core/Box";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import "./EmergencyFund.css";
import { format } from "indian-number-format";
import { Button, Stack } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const EmergencyFund = ({page, setPage}) => {

  const [livingExpenses, setLivingExpenses] = useState(10000);
  const [emergencyFundTime, setEmergencyFundTime] = useState(12);
  const [retrievalTime, setRetrievalTime] = useState(5);
  const [returnRate, setReturnRate] = useState(5);



  //output variables hooks 
  
  const [neededEmergencyFund, setNeededEmergencyFund] = useState(0);
  const [monthlyInvestment, setMonthlyInvestment] = useState(0);


  const handleChange1 = (event, newValue) => {
    setLivingExpenses(newValue);
  };
  const handleChange2 = (event, newValue) => {
    setEmergencyFundTime(newValue);
  };
  const handleChange3 = (event, newValue) => {
    setRetrievalTime(newValue);
  };
  const handleChange4 = (event, newValue) => {
    setReturnRate(newValue);
  };

  
  useEffect(() => {
    //the function and the calculation of the values comes here
    let neededFund, neededMonthly;
    neededFund = livingExpenses * emergencyFundTime;
    let r = returnRate;
    r = r / 1200;
    neededMonthly = neededFund / (((Math.pow(1 + r, retrievalTime) - 1) / r) * (r + 1));
    setNeededEmergencyFund(parseInt(neededFund, 10));
    setMonthlyInvestment(parseInt(neededMonthly, 10));
  }, [livingExpenses, emergencyFundTime, retrievalTime, returnRate]);

 

  useEffect(async() => {
    const token = localStorage.getItem("authToken");
    console.log("token",token)
    const url = process.env.REACT_APP_API_BASE_URL + "/finantialplanning/finnantial-EmergencyFund?authToken=" + token 
    await fetch(url,
      {
        method: "GET",
      })
      .then(res => res.json())
      .then(data => {
        console.log(data)
        if(data && data.length > 0){
          const res = data[0]
          setLivingExpenses(res.living_expense)
          setEmergencyFundTime(res.emergency_rate)
          setRetrievalTime(res.emergency_fund)
          setReturnRate(res.investment_return)
          setNeededEmergencyFund(res.emergency_fund_you_need)
          setMonthlyInvestment(res.monthly_investment_reqd)
        }
      })

      .catch(err => {
        console.log(err)
      })
  }, [])

  async function handleSubmit(e) {
    const token = localStorage.getItem("authToken")
    const url = process.env.REACT_APP_API_BASE_URL + "/finantialplanning/finnantial-EmergencyFund?authToken=" + token
    await fetch(url, {
      method: "POST",
      headers: {
        // "authToken" : token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        
        living_expense: livingExpenses,
        emergency_rate: emergencyFundTime,
        emergency_fund: retrievalTime,
        investment_return: returnRate,
        emergency_fund_you_need: neededEmergencyFund,
        monthly_investment_reqd: monthlyInvestment


      })
    })
      .then((res) => res.json())
      .then(data => {
        console.log("response from adding data ", data)
        if (data.message == "Data saved") {
          console.log("Data saved succesfully")
          setPage(page+1);
        }
        else {
          alert("Something went wrong while submitting your data, please try again")
        }
      })
      .catch(err => {
        console.log(err)
        alert("Something went wrong, please check your internet connection and try again")
      })
    e.preventDefault();
  }



  return (
    <>
      <h1 className="hedarf">Emergency Fund Calculator</h1>
      <div className="emergencyfund1">
        
        <div className="sliders1">
          <div className="sliderheading">
            Systematic Investment Plan (SIP) allows you to make small investment at regular
            intervals to help you achieve your dreams.
          </div>
          <div className="slider1">
            <div className="chartinfo-title-text">
              <h1>Living Expenses (p.m.)</h1>
              <div className="chartinfo-title-text-sub">
                <span>&#8377;&nbsp;</span>
              <input type="number" placeholder="0" onChange={(e) => setLivingExpenses(e.target.value)} value={livingExpenses} />
              </div>
            </div>
            <Box>
              <Slider
                aria-label="Volume"
                value={livingExpenses}
                onChange={handleChange1}
                valueLabelDisplay="off"
                max={200000}
              />
            </Box>
          </div>
          <div className="slider2">
            <div className="chartinfo-title-text">
              <h1>Emergency Rate for (months)</h1>
              <div className="chartinfo-title-text-sub">
              <input type="number" placeholder="0" onChange={(e) => setEmergencyFundTime(e.target.value)} value={emergencyFundTime} />
               <span>&nbsp;M</span>
              </div>
            </div>

            <Box>
              <Slider
                aria-label="Volume"
                value={emergencyFundTime}
                onChange={handleChange2}
                valueLabelDisplay="off"
                max={500}
              />
            </Box>
          </div>
          <div className="slider3">
            <div className="chartinfo-title-text">
              <h1>Emergency Fund to be accumulated in (months)</h1>

              
              <div className="chartinfo-title-text-sub">
                <input
                  type="number" placeholder="0"
                  onChange={(e) =>
                    setRetrievalTime(
                      e.target.value 
                    )
                  }
                  value={retrievalTime}
                />
                  <span>&nbsp;M</span>
              </div>
            </div>

            <Box>
              <Slider
                aria-label="Volume"
                value={retrievalTime}
                onChange={handleChange3}
                valueLabelDisplay="off"
                max={500}
              />
            </Box>
          </div>
          <div className="slider4">
            <div className="chartinfo-title-text">
              <h1>Investment Return (p.a.)</h1>
              <div className="chartinfo-title-text-sub">
              <input type="number" placeholder="0" onChange={(e) => setReturnRate(e.target.value)} value={returnRate} />
              <span>%</span>
              </div>
            </div>
            <Box>
              <Slider
                aria-label="Volume"
                value={returnRate}
                onChange={handleChange4}
                valueLabelDisplay="off"
                max={80}
              />
            </Box>
          </div>
        </div>

        <div className="chart23">
          <div className="chartinfo">
            <div className="chartinfo-title">
              <h1>Emergency Fund you need</h1>
              <h1> &#8377;&nbsp;{format(neededEmergencyFund)}</h1>
            </div>
            <div className="chartinfo-title">
              <h1>Monthly Investment Required</h1>
              <h1> &#8377;&nbsp;{format(monthlyInvestment)}</h1>
            </div>
          </div>
        </div>

        <div className="profile-button-row1">
                <button className="hedfa" variant="contained" style={{margin:"20px",padding:"20 40"}} onClick={() => setPage(page - 1)} startIcon={
                    <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>}>Previous</button>
                <button className="jkrd" variant="outlined" style={{margin:"20px",padding:"20 40"}} onClick={() => setPage(page+1)}>SKIP</button>
                <button  className="hedfa" variant="contained" style={{margin:"20px",padding:"20 40"}} onClick={handleSubmit} endIcon={
                    <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>}>NEXT</button>
            </div>
      </div>
     
    </>
  );
};

export default EmergencyFund;
