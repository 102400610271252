/* eslint-disable no-unused-vars */
import { styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import MediaCard from "../components/Media/MediaCard";
import MediaLoading from "../components/Media/MediaLoading";
import GetAssistanceForm from "../components/Slider/GetAssistanceForm";

import axios from "axios";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import './css/media.css'
import Headline from "../components/Headline/Headline";
import Appbar from "../components/Appbar/Appbar";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import { Grid, Card, CardContent, Typography, CardHeader } from "@material-ui/core/";
import { Row } from "react-bootstrap";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "90vw",
    marginLeft: "30vw",
  },
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  width: "80%",
  marginLeft: "16vw",
  padding: "10px"
}));

function Media() {
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(true);

  const [assistance, setAssistance] = useState(false);
  const [form, setForm] = useState({
    name: '',
    email: '',
    phone: '',
    query: '',
  })

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_BASE_URL + "/get/media")
      .then((res) => setMedia([...res.data]));
  }, []);

  let toShow = <MediaLoading />
  if (media) {
    toShow = (
      

        <div className="media-container">

          {media.map((data) => (<div key={1}>  {MediaCard(data)} </div>))}
        </div>
      
    );
  }

  const handleAssistance = async () => {
    const data = await axios.post("https://api.stocktick.in/getassistance/",
      form,
      {
        headers: {
          Connection: 'keep-alive',
          authToken: localStorage.getItem('authToken'),
          accepts: "application/json"
        }
      },
    ).then(() => {
      setForm({
        name: '',
        email: '',
        phone: '',
        query: '',
      });
      setAssistance(false);
    });
    console.log(data);
  }

  return (
    <React.Fragment>
      <ScrollToTop />
      <Headline />
      <Navbar />

      <div className="media-123-parent">

      <h1>
        STOCKTICK : In The News
      </h1>
      {/* <MediaLoading /> */}

      {toShow}

      </div>
      

      <Footer />

      <div className="head-div-1">
      <button className="heda123" onClick={() => setAssistance(true)}> Get Assistance!</button>
      </div>
      <GetAssistanceForm open={assistance} handleClose={() => { setAssistance(false) }} handleSubmit={handleAssistance} form={form} setForm={setForm} />
    </React.Fragment>
  );
}

export default Media;
