import React, { useState } from "react";
import Appbar from "../components/Appbar/Appbar";
import Footer from "../components/Footer/Footer";
import Headline from "../components/Headline/Headline";
import Explore from "../components/Loans/Explore";
import FAQ from "../components/Loans/FAQ";
import Middle from "../components/Loans/Middle";
import Poster from "../components/Loans/Poster";
import Navbar from "../components/Navbar/Navbar";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";

function Loan() {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const openModal = () => setOpen(true);
  const [type, setType] = useState("1");
  const handleClick = (e) => {
    setOpen(true);
    console.log(open);
    console.log(e.target.value);
    setType(e.target.value);
  };
  return (
    <div style={{ background: "white" }}>
      <ScrollToTop />
      <Headline />
      <Navbar />

      <Poster open={open} handleClose={handleClose} handleOpen={openModal} />
      <Middle />
      <Explore openModal={openModal} handleClick={handleClick}/>
      <FAQ />
      <Footer />
    </div>
  );
}

export default Loan;
