import React from "react";
import { useEffect, useState, useRef } from 'react';
import { Button, Stack } from "@mui/material";
import "./financial.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Chart from "react-apexcharts";
import minusCircle from "./minus-circle.png";
import { ConstructionOutlined } from "@mui/icons-material";

function FinancialGoals ({page, setPage}) {
    
    // {task: "", priority: ""}
    const [goals, setGoals] = useState([]);
    const [editGoal, setEditGoal] = useState("");
    const [goalRemove, setGoalRemove] = useState(goals);
    const ref = useRef();

    async function handleSubmit(e){
        const token = localStorage.getItem("authToken")
        const url = process.env.REACT_APP_API_BASE_URL + "/finantialplanning/finnantial-Q6?authToken="+token
        await fetch(url, {
            method : "POST",
            headers : {
                // "authToken" : token,
                "Content-Type" : "application/json"
            },
            body  : JSON.stringify({goals : goals}) 
        })
        .then((res)=> res.json())
        .then(data => {
            console.log("response from adding data ", data)
            if(data.message){
                setPage(page+1);
            }
            else {
                alert("Something went wrong while submitting your data, please try again")
            }
        })
        .catch( err => {
            console.log(err)
            alert("Something went wrong, please check your internet connection and try again")
        })
        e.preventDefault();
    }

    const handleRemove = (ind) => {
        console.log("here")
        const goalsTemp = [...goals]
        goalsTemp.splice(ind, 1);
        setGoals(goalsTemp);
    } 

    const handleEdit = (index , goal) => {
        const goalsTemp = [...goals]
        goalsTemp[index] = goal
        setGoals(goalsTemp)
    }

    const addMoreGoals = () => {
        setGoals([...goals, ""])
    }

    useEffect(()=>{
        setGoalRemove(goals);
    },[goals])

    useEffect( async () => {
        const token = localStorage.getItem("authToken");
        console.log("token", token)
        const url = process.env.REACT_APP_API_BASE_URL + "/finantialplanning/finnantial-Q6?authToken=" + token
        await fetch(url, {
            method : "GET"
        })
        .then(res => res.json())
        .then(async (data) => {
            console.log(data)
            if(data.length){
                setGoals(data);
            }
        })
        .catch(err => {
            console.log(err)
        })
    }, [])

    return(
        <div className="profile-info-container">
            <div className="profile-head">WELCOME TO FINANCIAL PLANNING</div>
            <div className="profile-subheading">{"Family Financial Goals"}</div>
            <div style={{margin:"10px"}}>Enter your goals for next 5 years</div>
            {goals.map((goal, index)=>
                <Stack className="abcde123" key ={index} direction ="row" justifyContent="space-around" alignItems="space-around" style={{margin:"-10px"}}>
                    <img src={minusCircle} style={{height: "20px", alignSelf:"center", margin:"20px"}} onClick={()=>handleRemove(index)}></img>
                    <input
                        type="text"
                        placeholder="Enter goal"
                        value={goal}
                        style={{height: "30px", alignSelf:"center"}}
                        onChange={(e) => {
                            handleEdit(index, e.target.value);
                        }}></input>
                    
                </Stack>
            )}
            {/* <Stack direction = "row" justifyContent="space-around" alignItems="space-around" style={{marginLeft: "38px"}}>
                <input
                    type="text"
                    placeholder="Enter goal"
                    defaultValue={null}
                    value={editGoal.task}
                    style={{height: "30px", alignSelf:"center"}}
                    onChange={(e) => {
                        setEditGoal(e.target.value);
                    }}></input>
            </Stack> */}
            <button className="fdbvbv" onClick={addMoreGoals} >Add Goal</button>
            <div className="profile-button-row">
            <button className="hedfa" variant="contained" style={{margin:"20px",padding:"20 40"}} onClick={() => setPage(page-1)} startIcon={
                    <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>}>Previous   </button>
                <button className="jkrd" variant="outlined" style={{margin:"20px",padding:"20 40"}} onClick={() => setPage(page+1)}>SKIP</button>
                <button  className="hedfa" variant="contained" style={{margin:"20px",padding:"20 40"}} onClick={handleSubmit} endIcon={
                    <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>}>NEXT   </button>
            </div>
        </div>
    )
}

export default FinancialGoals;